import React, { useState } from 'react'
import { PageTitle } from '../../../../_metronic/layout/core'
import { KTSVG } from '../../../../_metronic/helpers'
import { Controller, useForm } from 'react-hook-form'
import Select from 'react-select';
import CallFor from '../../../routing/CallFor';
import { Modal } from 'react-bootstrap';
import { mediaUploader } from './ImageBucket';
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom';
const Creative = () => {
    const user = [
        { value: '0', label: 'SIDE_MENU' },
        { value: '1', label: 'RECIEVED' },
        { value: '2', label: 'SEND' },
        { value: '3', label: 'MY_NETWORK' },
        { value: '4', label: 'USER_PROFILE' },
        { value: '5', label: 'COMPANY_PRODUCT' },
        { value: '6', label: 'TEAM_MEMBER' },
    ]

    const d = new Date();
    const navigate = useNavigate();
    const h = d.getHours();
    const m = d.getMinutes();
    const datePicker = new Date().toISOString().split("T")[0];
    const currentDate = new Date().toISOString().split("T")[0];
    const time = new Date().toLocaleTimeString().split(" ")[0];
    const sceduleTime = time?.slice(0, 5);

    const options: any = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
    };

    const formatter = new Intl.DateTimeFormat('en-US', options);
    const timeString = formatter.format(new Date());

    const { control } = useForm();
    const [isValid, setIsValid] = useState(true);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState('');
    const [creativeData, setCreativeData] = useState<any[]>([]);
    const [formData, setFormData] = useState<any>({
        image: null,
        image_error: '',
        userType: "",
        userType_error: '',
        date: "",
        date_error: '',
        time: "",
        time_error: '',
        end_date: "",
        end_date_error: '',
        end_time: "",
        end_time_error: '',
    });

    const handleChange = (event: { target: { name: any; value: any; }; }) => {
        const validationField = [event.target.name] + '_error'
        setFormData({ ...formData, [event.target.name]: event.target.value, [validationField]: '' })
    }

    const userHandler = (event: any) => {
        if (event !== null && event.value !== undefined) {
            setFormData({ ...formData, userType: event.value, userType_error: '' })
        }
        else {
            setFormData({ ...formData, userType: '', userType_error: '' })
        }
    }

    const handleLogoChange = (event: { target: { files: any; }; }) => {
        const fileSizeInKB = event?.target?.files[0]?.size / 1024;
        if (event?.target?.files?.length > 0) {
            if (!event?.target?.files[0]?.name?.match(/\.(JPG|JPEG|PNG|jpg|jpeg|png)$/)) {
                setIsValid(false);
                setFormData((prev: { target: { files: any; }; }) => ({ ...prev, image_error: 'ImageJPG/JPEG/JFIF/PNG file only' }))
            } else if (fileSizeInKB > 250) {
                setIsValid(false);
                setFormData((prev: { target: { files: any; }; }) => ({ ...prev, image_error: 'Image upto 250 KB allowed' }))
            }
            else {
                setFormData({ ...formData, image: event.target.files[0], image_error: '' })
                setIsValid(true);
                setError('')
            }
        }
    }

    const validationSchema = () => {
        let isValid = true;
        if (formData?.image === null) {
            setFormData((prev: { target: { files: any; }; }) => ({ ...prev, image_error: "Please select image" }))
            isValid = false
        }
        if (formData?.userType?.trim() === '') {
            setFormData((prev: any) => ({ ...prev, userType_error: "Please select a Device Type" }))
            isValid = false
        }
        if (formData?.date === '') {
            setFormData((prev: any) => ({ ...prev, date_error: "select schedule date" }))
            isValid = false
        }
        if (formData?.time === '') {
            setFormData((prev: any) => ({ ...prev, time_error: "Select schedule time" }))
            isValid = false
        }
        if (formData?.end_date === '') {
            setFormData((prev: any) => ({ ...prev, end_date_error: "select end schedule date" }))
            isValid = false
        }
        if (formData?.end_time === '') {
            setFormData((prev: any) => ({ ...prev, end_time_error: "Select end schedule time" }))
            isValid = false
        }
        if (formData?.end_date === formData.date && formData?.end_time <= formData?.time) {
            setFormData((prev: any) => ({ ...prev, end_time_error: "Select valid end time" }))
            isValid = false
        }
        if (formData?.date === currentDate && formData?.time <= timeString) {
            setFormData((prev: any) => ({ ...prev, time_error: "Select valid start time" }))
            isValid = false
        }

        if (formData.time?.slice(0, 2) < h) {
            if (formData.time?.slice(3, 6) < m) {
                if (formData.date === currentDate) {
                    setFormData((prev: any) => ({ ...prev, time_error: "The schedule time is invalid" }))
                    isValid = false
                }
            }
        }
        return isValid
    }

    const publishHandler = async () => {
        const url = await mediaUploader([formData.image])
        if (validationSchema()) {
            let jsonData: any = {
                bannerType: Number(formData.userType),
                mediaUrl: url?.data[0],
                startDate: `${formData.date.split("-").reverse().join("-")} ${formData.time}`,
                endDate: `${formData.end_date.split("-").reverse().join("-")} ${formData.end_time}`,
            }
            try {
                const response = await CallFor('api/admin/v1/banner/creative/create', 'POST', JSON.stringify(jsonData), 'Auth');
                const resData = await response.json();
                if (response.status === 201) {
                    setOpen(false)
                    setCreativeData(prev => [resData.data, ...prev]);
                    navigate('/CreativeNotification');
                } else if (response.status === 400) {
                    toast.error(resData?.error?.message ? resData?.error?.message : 'Please Select deferent Data ')
                    setOpen(false)
                }
            } catch (error) {
                toast.error('Please Select deferent Data ')
                setOpen(false)
            }
        }
    }

    const createUrl = (file: any) => {
        if (file) {
            return URL.createObjectURL(file)
        } else {
            return ""
        }
    }

    const reviewModal = () => {
        if (validationSchema() && isValid) {
            setOpen(true)
        }
    }
    const ApiCall = () => {
        publishHandler();
    }

    return (
        <div>
            <PageTitle >Creative</PageTitle>
            <div className='card mb-5 p-5'>
                <div className='row'>

                    <div className='col-lg-6 offset-lg-3' >

                        <div className="mb-3 col-lg-12">
                            <label className="form-label">Image / Logo </label>
                            <input
                                name='logofile'
                                type="file"
                                className={`form-control ${formData?.image_error === "" ? '' : 'is-invalid'}`}
                                placeholder=". Image / Logo - Ur"
                                accept="image/png, image/jpg, image/jpeg"
                                onChange={handleLogoChange}
                                id='logoimg'
                            />
                            <p id='error_1' className='errormsg' >{formData?.image_error}</p>
                        </div>

                        <div className="mb-3 col-lg-12">
                            <label className='form-label'>User Type*</label>
                            <Controller
                                name='userType'
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        isClearable
                                        isSearchable={true}
                                        placeholder='Select Status'
                                        className={formData.userType_error ? 'error-border' : ''}
                                        value={user.filter(
                                            (obj) => obj.value === formData.userType)}
                                        onChange={userHandler}
                                        id='status'
                                        options={user}
                                    />
                                )}
                            />
                            <p id='error_1' className='errormsg' >{formData.userType_error}</p>
                        </div>

                        <div className="mb-3 col-lg-12">
                            <label className="form-label">Scheduling*</label>
                            <div className='row'>
                                <div className="mb-6 col-lg-6">
                                    <input
                                        name='date'
                                        type="date"
                                        id="datePickerId"
                                        min={datePicker}
                                        value={formData.date}
                                        className={`form-control ${formData?.date_error === "" ? '' : 'is-invalid'}`}
                                        onChange={handleChange}
                                    />
                                    <p id='error_1' className='errormsg' >{formData?.date_error}</p>
                                </div>

                                <div className="mb-6 col-lg-6">
                                    <input
                                        name='time'
                                        type="time"
                                        min={sceduleTime}
                                        value={formData.time}
                                        className={`form-control ${formData?.time_error === "" ? '' : 'is-invalid'}`}
                                        onChange={handleChange}
                                    />
                                    <p id='error_1' className='errormsg' >{formData?.time_error}</p>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3 col-lg-12">
                            <label className="form-label">End Time*</label>
                            <div className='row'>
                                <div className="mb-6 col-lg-6">
                                    <input
                                        name='end_date'
                                        type="date"
                                        id="datePickerId"
                                        min={formData.date}
                                        value={formData.end_date}
                                        className={`form-control ${formData?.end_date_error === "" ? '' : 'is-invalid'}`}
                                        onChange={handleChange}
                                    />
                                    <p id='error_1' className='errormsg' >{formData?.end_date_error}</p>
                                </div>
                                {formData.end_date === currentDate && formData.date === currentDate ? (
                                    <div className="mb-6 col-lg-6">
                                        <input
                                            name='end_time'
                                            type="time"
                                            min={formData.time}
                                            value={formData.end_time}
                                            className={`form-control ${formData?.end_time_error === "" ? '' : 'is-invalid'}`}
                                            onChange={handleChange}
                                        />
                                        <p id='error_1' className='errormsg' >{formData?.end_time_error}</p>
                                    </div>
                                )
                                    : (
                                        <div className="mb-6 col-lg-6">
                                            <input
                                                name='end_time'
                                                type="time"
                                                min={sceduleTime}
                                                value={formData.end_time}
                                                className={`form-control ${formData?.end_time_error === "" ? '' : 'is-invalid'}`}
                                                onChange={handleChange}
                                            />
                                            <p id='error_1' className='errormsg' >{formData?.end_time_error}</p>
                                        </div>
                                    )}
                            </div>
                        </div>
                        <div className="col-lg-12  text-center">
                            <button type='submit' className='btn btn-primary' onClick={reviewModal}>Review</button>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={open} onHide={() => setOpen(true)} >
                <Modal.Header>

                    <Modal.Title>Review Message</Modal.Title>
                    <div
                        className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => setOpen(false)}
                    >
                        <KTSVG
                            path="/media/icons/duotune/arrows/arr061.svg"
                            className="svg-icon svg-icon-2x"
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>

                    <div className="mb-3 col-lg-12">
                        <label className="form-label">Create Banner</label>
                        <div className="border-bottom border-gray-300">
                            <img src={createUrl(formData?.image)} style={{ width: "300px" }} alt='' />
                        </div>
                    </div>

                    <div className="mb-3 col-lg-12 mt-4">
                        <label className="form-label">User Type</label>
                        <div className="border-bottom border-gray-300">
                            {formData.userType === '0' ?
                                'SIDE_MENU' : formData.userType === '1' ?
                                    'RECIEVED' : formData.userType === '2' ?
                                        'SEND' : formData.userType === '3' ?
                                            'MY_NETWORK' : formData.userType === '4' ?
                                                'USER_PROFILE' : formData.userType === '5' ?
                                                    'COMPANY_PRODUCT' : 'TEAM_MEMBER'
                            }
                        </div>
                    </div>
                    <div className="mb-3 col-lg-12 mt-4">
                        <label className="form-label">Start Time Scheduling</label>
                        <div className="">{formData?.date} {formData?.time}</div>
                    </div>

                    <div className="mb-3 col-lg-12 mt-4">
                        <label className="form-label">End Time Scheduling</label>
                        <div className="">{formData?.end_date} {formData?.end_time}</div>
                    </div>

                </Modal.Body>
                <Modal.Footer>

                    <button type="button" className="btn btn-secondary" onClick={() => setOpen(false)}>
                        Cancel
                    </button>

                    <button type="button" className="btn btn-primary" onClick={ApiCall}>
                        Publish
                    </button>

                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Creative