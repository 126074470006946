/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, useRef } from 'react'
import { PageTitle } from '../../../../_metronic/layout/core'
import Pagination from '../../../components/Pagination'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import CallFor from '../../../routing/CallFor'
import { toast } from 'react-toastify'


const Notification = () => {

  const [campaignData, setCampaignData] = useState<any[]>([]);
  const [page, setPage] = useState<number>(0);
  const [totalPageNo, setTotalPageNo] = useState<number>(0);
  const [deleteId, setDeleteId] = useState("")
  const deleteModalRef = useRef<HTMLDivElement>(null);
  // const [currentDeletId,SetCurrentId] = useState()
  // const history = useHistory();

  useEffect(() => {
    getCampaignList();
  }, [page])


  const deletePlanModal = (deleteId: any) => {

    setDeleteId(deleteId)
    const divElement = document.createElement("div")
    divElement.classList.add("modal-backdrop")
    divElement.classList.add("fade")
    divElement.classList.add("show")
    const body = document.getElementById("kt_app_body")
    body?.appendChild(divElement)
    deleteModalRef.current!.style.display = "block"
    deleteModalRef.current!.style.paddingLeft = "0px"
    deleteModalRef.current!.classList.add("show")
    deleteModalRef.current!.setAttribute("aria-modal", "true")
    deleteModalRef.current!.setAttribute("role", "dialog")
    deleteModalRef.current!.removeAttribute("aria-hidden")
  }

  const closeKpPlanDeleteModal = () => {
    deleteModalRef.current!.style!.display = "none"
    deleteModalRef.current!.classList.remove("show")
    deleteModalRef.current!.removeAttribute("aria-modal")
    deleteModalRef.current!.removeAttribute("role")
    deleteModalRef.current!.setAttribute("aria-hidden", "true");
    const divElement = document.getElementsByClassName('modal-backdrop')
    divElement[0].remove()
    setDeleteId("")
  }

  const deleteEmployeeDetail = async () => {
    // setAllemployeData(allemployeData.filter((item) => item.usersLoginId !== deleteId));
    const deleteCampain = await CallFor
      (`api/admin/v1/notifications/customs/${deleteId}`,
        'PUT',
        null,
        'Auth')
    const fetchData = await deleteCampain.json()
    if (deleteCampain.status === 200) {
      setCampaignData(campaignData.filter((item) => item.id !== deleteId))
      toast.success(fetchData?.message)
    } else if (deleteCampain.status === 404) {
      toast.error(fetchData?.error?.message)
    } else if (deleteCampain.status === 400) {
      toast.error(fetchData?.error?.message)
    }
    closeKpPlanDeleteModal();
  }

  const getCampaignList = async () => {
    try {
      const response = await CallFor('api/admin/v1/notifications', 'POST', JSON.stringify({ page: page }), 'AuthwithContentType');
      if (response.status === 200) {
        const json1response = await response.json();
        if (json1response?.length === 0) {
          setPage(0);
          setTotalPageNo(0);
        }
        setTotalPageNo(Number(json1response.data.page))
        setCampaignData(json1response.data.content)
      }
    } catch (error) {
      console.log(error)
    }

  }
  return (
    <div>

      <PageTitle>Notification Dashboard</PageTitle>
      <div className='card mb-5'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Campaign Details</span>
          </h3>
        </div>

        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                  <th>Campaign</th>
                  <th>Start</th>
                  <th>Status</th>
                  <th>Traget</th>
                </tr>
              </thead>
              <tbody>
                {
                  campaignData.map((detail, i) => {
                    return (
                      <tr key={i} >
                        <td>
                          <div className='d-flex justify-content-start flex-column'>
                            {detail.title}
                            <span className='text-muted fs-7' >{detail.content}</span>
                          </div>
                        </td>
                        <td>{detail.scheduleTime}</td>
                        <td>{detail.status}</td>
                        <td>{detail.deviceType}</td>
                        <td>
                          <a
                            href='#'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            onClick={() => deletePlanModal(detail.id)}
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen027.svg'
                              className='svg-icon-3'
                            />
                          </a>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* ------Modal------ */}

      <div className="modal fade" ref={deleteModalRef} tabIndex={-1} id="kt_modal_1">
        <div className="modal-dialog modelLower">
          <div className="modal-content">
            <div className="modal-header p-4">
              <h5 className="modal-title">Campain detele</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                // data-bs-dismiss="modal"
                aria-label="Close"
              >
                <a
                  href='#'
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                  onClick={closeKpPlanDeleteModal}
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon svg-icon-2x"
                  />
                </a>
              </div>
            </div>
            <div className="modal-body">
              <p className='inactiveName'>Are you sure you want to delete campaign ?</p>
            </div>
            <div className='modal-footer py-1 px-3 ms-auto w-100 deleteButton'>
              <button className="btn btn-secondary btn-sm" onClick={deleteEmployeeDetail}>Yes</button>
              <button className="btn btn-primary btn-sm" onClick={closeKpPlanDeleteModal}>No</button>
            </div>
          </div>
        </div>
      </div>

      {
        totalPageNo > 0 &&
        <Pagination
          totalCount={totalPageNo}  //total number of data
          siblingCount={1} // number of extra elements around avtive page
          currentPage={page} //current active page
          onPageChange={setPage} //page change handler state
          pageSize={10} // data per page
        />}
    </div>
  )
}
export default Notification
