import React from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CallFor from '../../../routing/CallFor';

type UserSubmitForm={
    designationName:string,
    status:string
  }

const EditDesignation = (props:any) => {

    // const validationschema = Yup.object().shape({
    //     designationName: Yup.string().required('Enter Designation').matches(/^[A-Za-z-&-\s]+$/, 'Only alphabets allowed ').test(
    //       'len',
    //       'Minimum 2 character require',
    //       (val) => val !== undefined && val?.toString()?.length > 2).test(
    //         'len',
    //         'Maximum 50 character allowed',
    //         (val) => val !== undefined && val?.toString()?.length < 50),
    //      status:Yup.string().required('Please Select status'),
    //   })

    const { handleSubmit} = useForm<UserSubmitForm>({mode: 'onChange'});
  

      const clearError =()=>{
        props.setEditData((prev:any)=>({...prev, designationName_error: "",status_error: ''}))
      }

      const validationSchema =()=>{
          if (props.editData.designationName.trim() === "") {
            props.setEditData((prev:any)=>({...prev, designationName_error: "Enter Designation"}))
            return false;
          }
          // else if (props.editData.designationName.match(/[0-9]/ ) || props.editData.designationName.match(/[.*+?^${}@#=%()|[\]\\]/)) {
          //   props.setEditData((prev:any)=>({...prev, designationName_error: "Only Alphabets Allowed"}))
          //   return false;
          // }
          // else if(props.editData.status === ""){
          //   props.setEditData((prev:any)=>({...prev, status_error: "Select Status"}))
          //   return false;
          // }
          return true
      }
      
    const userFormDataChangeHandler = (event: { target: { name: any; value: any; }; }) => {
        const validationField = [event.target.name]+'_error'
        props.setEditData((prev: any) => ({ ...prev, [event.target.name]: event.target.value,[validationField]: ''}));
        clearError();
      }
    const submitEditHandler = async () => {
      if (validationSchema()) {
        const jsondata = {
          designationName: props.editData.designationName,
          status: 'ACTIVE'
        }
        const data = JSON.stringify(jsondata)
        const response = await CallFor('api/admin/v1/designations/'+ props.editData.id , 'PUT',data,'Auth')
        if (response.status === 200) {
          closeModal1();
          props.getDesignationDetails();
          props.setEditData({});
        }
      }
      }  

      const closeModal1 = () => {
        clearError();
        props.editref.current!.style!.display = "none"
        props.editref.current!.classList.remove("show")
        props.editref.current!.removeAttribute("aria-modal")
        props.editref.current!.removeAttribute("role")
        props.editref.current!.setAttribute("aria-hidden", "true");
        const divElement = document.getElementsByClassName('modal-backdrop')
        divElement[0].remove()

      }

  return (
    <div className="modal fade" ref={props.editref} tabIndex={-1} id="kt_modal_1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header p-4">
              <h5 className="modal-title">Add Designation</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                // data-bs-dismiss="modal"
                aria-label="Close"
              >
                <a
                  href='#'
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                  onClick={closeModal1}
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon svg-icon-2x"
                  />
                </a>
              </div>
            </div>
            <div className="modal-body">
              <div className='row'>

                <form onSubmit={handleSubmit(submitEditHandler)}>
                  <div className="mb-3 col-lg-12">
                    <label className="form-label">Designation Name</label>
                    <input
                      type="text"
                      className={`form-control ${props?.editData?.designationName_error === "" ? '' : 'is-invalid'}`}
                      placeholder="Enter Designation Name"
                      name='designationName'
                      value={props.editData.designationName}
                      onChange={userFormDataChangeHandler}
                      />
                  <p id='error_1'  className='errormsg' >{props.editData.designationName_error}</p>
                  </div>
                  
                  {/* <div className="col-lg-12">
                    <label className='form-label'>Status</label>
                    <select 
                      name='status'
                      className={`form-select text-muted ${props?.editData?.status_error === "" ? '' : 'is-invalid'}`} 
                      value={props.editData.status}
                      onChange={userFormDataChangeHandler}
                    > */}
                      {/* <option value=''>Select a Status...</option> */}
                      {/* <option value='ACTIVE'>ACTIVE</option>
                      <option value='INACTIVE'>INACTIVE</option>
                    </select>
                  </div> */}
                  <p className='errormsg' >{props.editData.status_error}</p>
                  <div className="modal-footer p-0 ms-auto w-100 border-0 mt-2">
                    <button type="submit" className="btn btn-primary"
                      id='saveBtn'
                    >
                      Save Designation
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default EditDesignation