import React, { useEffect, useRef, useState } from 'react'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import Pagination from '../../../components/Pagination';
import CallFor from '../../../routing/CallFor';

const InquiryNow: React.FC = () => {
    const [details, setDetails] = useState<Object[]>([]);
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState<Boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [totalPageNo, setTotalPageNo] = useState<number>(1);
    const ncurentDate = new Date().toISOString().split("T")[0];
    const [search, setSearch] = useState<any>({
        compName: '',
        compName_error: '',
        personName: '',
        personName_error: '',
        fromDate: '',
        fromDate_error: '',
        toDate: '',
        toDate_error: ''
    })
    const [isClear, setIsClear] = useState<Boolean>(true);
    // useEffect(() => {
    //     let subscription = true;
    //     if (subscription) {
    //         searchHandler()
    //     }
    //     return () => {
    //         subscription = false;
    //     }
    // }, [])
    useEffect(() => {
        let subscription = true;
        if (subscription) {
            searchHandler()
        }
        return () => {
            subscription = false;
        }
    }, [isClear, page])

    const searchHandler = async () => {
        try {
            setLoading(true)
            setIsClear(false)
            //doubt what to do ? about &status=ACTIVE
            const response = await CallFor('api/admin/v1/knowledge-center-inquiries/getAll', 'POST',
                JSON.stringify({
                    companyName: search.compName,
                    personName: search.personName,
                    fromDate: search.fromDate,
                    toDate: search.toDate,
                    page: page
                }), 'Auth')
            if ((await response).status === 200) {
                const jsonResponse = await response.json();
                if (jsonResponse.length === 0) {
                    setPage(0);
                    setTotalPageNo(0);
                }
                setTotalPageNo(Number(jsonResponse.data.pages))
                setDetails(jsonResponse.data.content);

            }
        } catch (error) {
            setError(error);
        }
        setLoading(false);
    }
    const clearHandler = () => {
        setPage(0);
        setIsClear(true);
        setSearch((prev: any) => ({
            compName: '',
            compName_error: '',
            personName: '',
            personName_error: '',
            fromDate: '',
            fromDate_error: '',
            toDate: '',
            toDate_error: ''
        }))
    }

    const validationSchema = () => {
        if (search.compName === '' && search.personName === '' && search.fromDate === '' && search.toDate === '') {
            setSearch((prev: any) => ({ ...prev, compName_error: "Enter Company Name", personName_error: "Enter Person Name", fromDate_error: "Enter From Date", toDate_error: "Enter To Date" }))
            return false
        }
        return true
    }

    const searchSubmit = () => {
        if (validationSchema()) {
            setPage(0);
            searchHandler()
        }
    }

    const handleSearch = (event: any) => {
        const validationField = [event.target.name] + '_error'
        setSearch({ ...search, [event.target.name]: event.target.value, [validationField]: '' })
        setSearch((prev: any) => ({ ...prev, compName_error: "", personName_error: "", fromDate_error: "", toDate_error: "" }))
    }
    return (
        <div>
            <PageTitle >Inquiry</PageTitle>
            <div className='card mb-5 p-5'>
                <div className='row'>
                    <div className="mb-3 col-lg-3">
                        <label className="form-label">Company Name</label>
                        <input
                            // ref={compNameRef}
                            name='compName'
                            type="text"
                            className={`form-control ${search?.compName_error === "" ? '' : 'is-invalid'}`}
                            value={search.compName}
                            placeholder="Company Name"
                            onChange={(e) => handleSearch(e)}
                        />
                        <p id='error_1' className='errormsg' >{search?.compName_error}</p>
                    </div>
                    <div className="mb-3 col-lg-3">
                        <label className="form-label">Person Name</label>
                        <input
                            // ref={personNameRef}
                            name='personName'
                            type="text"
                            className={`form-control ${search?.personName_error === "" ? '' : 'is-invalid'}`}
                            value={search.personName}
                            placeholder="Person Name"
                            onChange={(e) => handleSearch(e)}
                        />
                        <p id='error_1' className='errormsg' >{search?.personName_error}</p>
                    </div>
                    <div className="mb-3 col-lg-3">
                        <label className="form-label">From Date</label>
                        <input
                            // ref={fromDateRef}
                            name='fromDate'
                            type="date"
                            className={`form-control ${search?.fromDate_error === "" ? '' : 'is-invalid'}`}
                            value={search.fromDate}
                            onChange={(e) => handleSearch(e)}
                            max={ncurentDate}
                        />
                        <p id='error_1' className='errormsg' >{search?.fromDate_error}</p>
                    </div>
                    <div className="mb-3 col-lg-3">
                        <label className="form-label">To Date</label>
                        <input
                            // ref={toDateRef}
                            name='toDate'
                            type="date"
                            className={`form-control ${search?.toDate_error === "" ? '' : 'is-invalid'}`}
                            value={search.toDate}
                            onChange={(e) => handleSearch(e)}
                            max={ncurentDate}
                        />
                        <p id='error_1' className='errormsg' >{search?.toDate_error}</p>
                    </div>
                    <div className="col-lg-12  text-center">
                        <button type='submit' className='btn btn-primary' onClick={searchSubmit}>Search</button>
                        <button type='submit' className='btn btn-danger clear-btn' onClick={clearHandler}>Clear</button>
                    </div>
                </div>
            </div>
            <div className='card mb-5'>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>Inquiry Details</span>
                    </h3>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                {<div className='card-body py-3'>
                    {/* begin::Table container */}
                    {details.length > 0 ?
                        <div className='table-responsive'>
                            {/* begin::Table */}
                            <table className='table table-row-gray-300 align-middle gs-0 gy-4'>
                                {/* begin::Table head */}
                                <thead>
                                    <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                                        <th className='min-w-150px'>Company Name</th>
                                        <th className='min-w-140px'>Person Name</th>
                                        <th className='min-w-120px'>Phone</th>
                                        <th className='min-w-120px'>Email</th>
                                        <th className='min-w-100px'>Date</th>
                                    </tr>
                                </thead>
                                {/* end::Table head */}
                                {/* begin::Table body */}
                                <tbody>
                                    {
                                        details?.map((detail: any) => <tr key={detail.id}>
                                            <td>
                                                <div className='d-flex   align-items-center'>
                                                    {detail?.companyName}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='d-flex   align-items-center'>
                                                    {detail?.personName}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='d-flex   align-items-center'>
                                                    {detail?.contactNo}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='d-flex   align-items-center'>
                                                    {detail?.email}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='d-flex   align-items-center'>
                                                    {detail.updatedDate ? (detail?.updatedDate?.split('T')[0])?.split("-")?.reverse()?.join("-") : (detail?.createdDate?.split('T')[0])?.split("-")?.reverse()?.join("-")}
                                                </div>
                                            </td>
                                        </tr>)
                                    }
                                </tbody>
                                {/* end::Table body */}
                            </table>
                            {/* end::Table */}
                        </div> : <tbody>
                            <tr>
                                <td colSpan={9}>
                                    <h6 className='text-center bg-gray-200i p-4 text-gray-800 mb-0 mt-6'>Data Not Found</h6>
                                </td>
                            </tr>
                        </tbody>
                    }
                </div>
                }
                {/* end::Table container */}
            </div>
            {/* begin::Body */}
            {
                totalPageNo > 0 &&
                <Pagination
                    totalCount={totalPageNo}  //total number of data
                    siblingCount={1} // number of extra elements around avtive page
                    currentPage={page} //current active page
                    onPageChange={setPage} //page change handler state
                    pageSize={10} // data per page
                />
            }
        </div >
    )
}
export default InquiryNow