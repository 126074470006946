import * as Yup from 'yup';

export function FormUpdate() {
    return Yup.object().shape({
        logo: Yup.mixed()
            .test(
                'type',
                "Please Valid Image Formate",
                (val: any) => {
                    if (!val) return true
                    return val?.name?.match(/\.(jpg|jpeg|png|PNG|JPEG|JPG)$/)
                }
            )
            .test(
                'size',
                "Image upto 250 KB allowed",
                (val: any) => {
                    if (!val) return true
                    return val?.size <= 250 * 1024
                }
            ),
        // userType: Yup.object().shape({
        //     label: Yup.string().required("please select User Type"),
        //     value: Yup.string().required("please select User Type")
        // }),

        startDate: Yup.date()
            .required("start Date required")
            .typeError('Select Start Date'),

        startTime: Yup.string()
            .required('Start Time is required')
            .test('startTime', "Please Select Valid Start Time ", function (value: any) {
                const currentDate = new Date();
                const startDate = new Date(this.parent.startDate);
                const time = new Date().toLocaleTimeString().split(" ")[0];
                if (startDate.toLocaleDateString() === currentDate.toLocaleDateString()) {
                    if (value <= time) {
                        return false
                    } else {
                        return true
                    }
                }
                else {
                    return true
                }
            }),

        endDate: Yup.date()
            .required("End Date required")
            .typeError('Select End Date'),

        endTime: Yup.string()
            .required('End Time is required')

            .test('endTime', "Please Select Valid End Time ", function (value: any) {
                const currentDate = new Date();
                const startDate = new Date(this.parent.startDate);
                const endDate = new Date(this.parent.endDate);
                const time = new Date().toLocaleTimeString().split(" ")[0];
                if (startDate.toLocaleDateString() === currentDate.toLocaleDateString() && endDate.toLocaleDateString() === currentDate.toLocaleDateString()) {
                    if (value <= time) {
                        return false
                    } else {
                        return true
                    }
                }
                else {
                    return true
                }
            })
            .test('endTime', "Please Select Valid End Time ", function (value: any) {
                const startDate = new Date(this.parent.startDate);
                const endDate = new Date(this.parent.endDate);
                const time = this.parent.startTime;
                if (startDate.toLocaleDateString() === endDate.toLocaleDateString()) {
                    if (value <= time) {
                        return false
                    } else {
                        return true
                    }
                }
                else {
                    return true
                }

            }),
    })

}