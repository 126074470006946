/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, useRef } from 'react'
import { PageTitle } from '../../../../_metronic/layout/core'
import { KTSVG } from '../../../../_metronic/helpers'
import CallFor from '../../../routing/CallFor'
import { toast } from 'react-toastify'
import Pagination from '../../../components/Pagination'
import { Modal } from 'react-bootstrap'
import { convertBigIntToString } from '../../../../_metronic/layout/core/ConvertToBigInt'

const PromoCodeListing = () => {

    const [promoCode, setPromoCode] = useState<any[]>([]);
    const [page, setPage] = useState<number>(0);
    const [totalPageNo, setTotalPageNo] = useState<number>(0);
    const [deleteId, setDeleteId] = useState("")
    const deleteModalRef = useRef<HTMLDivElement>(null);
    const [openList, setOpenList] = useState(false);
    const [perticularPromoCode, setPerticularPromoCode] = useState<any>();
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        getPromoCodeList();
    }, [page]);

    const getPromoCodeList = async () => {
        try {
            const response = await CallFor('api/admin/v1/promocodes/promocodelist', 'POST', JSON.stringify({ page: page }), 'Auth');
            if (response.status === 200) {
                const json1response = await response.json();
                if (json1response?.length === 0) {
                    setPage(0);
                    setTotalPageNo(0);
                }
                setTotalPageNo(Number(json1response?.data?.page))
                setPromoCode(json1response?.data?.content)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const deletePlanModal = (deleteId: any) => {
        setDeleteId(deleteId)
        const divElement = document.createElement("div")
        divElement.classList.add("modal-backdrop")
        divElement.classList.add("fade")
        divElement.classList.add("show")
        const body = document.getElementById("kt_app_body")
        body?.appendChild(divElement)
        deleteModalRef.current!.style.display = "block"
        deleteModalRef.current!.style.paddingLeft = "0px"
        deleteModalRef.current!.classList.add("show")
        deleteModalRef.current!.setAttribute("aria-modal", "true")
        deleteModalRef.current!.setAttribute("role", "dialog")
        deleteModalRef.current!.removeAttribute("aria-hidden")
    }

    const closeKpPlanDeleteModal = () => {
        deleteModalRef.current!.style!.display = "none"
        deleteModalRef.current!.classList.remove("show")
        deleteModalRef.current!.removeAttribute("aria-modal")
        deleteModalRef.current!.removeAttribute("role")
        deleteModalRef.current!.setAttribute("aria-hidden", "true");
        const divElement = document.getElementsByClassName('modal-backdrop')
        divElement[0].remove()
        setDeleteId("")
    }

    const deleteEmployeeDetail = async () => {
        const deletePromoCode = await CallFor
            (`api/admin/v1/promocodes/${deleteId}`,
                'DELETE',
                null,
                'Auth')
        const fetchData = await deletePromoCode.json()
        if (deletePromoCode.status === 200) {
            setPromoCode(promoCode.filter((item) => (convertBigIntToString(item.id)) !== deleteId));
            toast.success("Promocode Deleted")
            getPromoCodeList();
        } else if (deletePromoCode.status === 404) {
            toast.error(fetchData?.error?.message)
        } else if (deletePromoCode.status === 400) {
            toast.error(fetchData?.error?.message)
        }
        closeKpPlanDeleteModal();
    }

    return (
        <div>
            <PageTitle>PromoCode Listing</PageTitle>
            <div className='card mb-5'>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>PromoCode Details</span>
                    </h3>
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                                    <th>PromoCode Name</th>
                                    <th>Discount Percentage</th>
                                    <th>Discount Amount</th>
                                    <th className='w-14'>Header</th>
                                    <th className='w-14'>Description</th>
                                    <th>Type</th>
                                    <th>Start</th>
                                    <th>End</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    promoCode.length > 0 &&
                                    promoCode.map((detail, i) => {
                                        return (
                                            <tr key={i} onClick={() => { setPerticularPromoCode(detail); setOpenModal(true) }}>
                                                <td>
                                                    <div className='d-flex justify-content-start flex-column'>
                                                        {detail?.name}
                                                    </div>
                                                </td>
                                                <td>{detail?.discountPercentage}%</td>
                                                <td>{detail?.discountAmount}</td>
                                                <td className='w-14'>{detail?.header}</td>
                                                <td className='w-14'>{detail?.description}</td>
                                                <td>{detail?.isPrivate === true ? "Private" : "Public"}</td>
                                                <td>{detail?.startDate}</td>
                                                <td>{detail?.endDate}</td>
                                                <td>
                                                    <a
                                                        href='#'
                                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                                        onClick={(e) => { e.stopPropagation(); deletePlanModal(convertBigIntToString(detail?.id)) }}
                                                    >
                                                        <KTSVG
                                                            path='/media/icons/duotune/general/gen027.svg'
                                                            className='svg-icon-3'
                                                        />
                                                    </a>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {/* ------Modal------ */}

            <div className="modal fade" ref={deleteModalRef} tabIndex={-1} id="kt_modal_1">
                <div className="modal-dialog modelLower">
                    <div className="modal-content">
                        <div className="modal-header p-4">
                            <h5 className="modal-title">PromoCode Delete</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                // data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <a
                                    href='#'
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                    onClick={closeKpPlanDeleteModal}
                                >
                                    <KTSVG
                                        path="/media/icons/duotune/arrows/arr061.svg"
                                        className="svg-icon svg-icon-2x"
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="modal-body">
                            <p className='inactiveName'>Are you sure you want to delete PromoCode ?</p>
                        </div>
                        <div className='modal-footer py-1 px-3 ms-auto w-100 deleteButton'>
                            <button className="btn btn-secondary btn-sm" onClick={deleteEmployeeDetail}>Yes</button>
                            <button className="btn btn-primary btn-sm" onClick={closeKpPlanDeleteModal}>No</button>
                        </div>
                    </div>
                </div>
            </div>
            {
                totalPageNo > 0 &&
                <Pagination
                    totalCount={totalPageNo * 10}  //total number of data
                    siblingCount={1} // number of extra elements around avtive page
                    currentPage={page} //current active page
                    onPageChange={setPage} //page change handler state
                    pageSize={10} // data per page
                />
            }

            {/* perticular Promocode details */}
            <Modal show={openModal} onHide={() => setOpenList(true)} className='modal-sm'>
                <Modal.Header>

                    <Modal.Title>PromoCode</Modal.Title>
                    <div
                        className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => setOpenModal(false)}
                    >
                        <KTSVG
                            path="/media/icons/duotune/arrows/arr061.svg"
                            className="svg-icon svg-icon-2x"
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='card-body py-3'>
                        <div className='table-responsive'>
                            <span>PromoCode Name : <p>{perticularPromoCode?.name}</p></span>
                            {perticularPromoCode?.discountAmount && <span>Discount Amount :<p>{perticularPromoCode?.discountAmount}</p></span>}
                            <span>Discount Percentage :<p>{perticularPromoCode?.discountPercentage}</p></span>
                            {perticularPromoCode?.header && <span>Header :<p>{perticularPromoCode?.header}</p></span>}
                            {perticularPromoCode?.description && <span> Description:<p>{perticularPromoCode?.description}</p></span>}
                            <span> Type:<p>{perticularPromoCode?.isPrivate === true ? "Private" : "Public"}</p></span>
                            <span>startDate :<p>{perticularPromoCode?.startDate}</p></span>
                            <span>endDate :<p>{perticularPromoCode?.endDate}</p></span>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </div >
    )
}
export default PromoCodeListing