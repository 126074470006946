import React from 'react'
import companyLogo from '../../../../_metronic/assets/images/zyapaar-icon.svg';
const Loading = () => {
  return (
    <div>
        <div id="splash-screen" className="splash-screen loadingModal">
            <img src={companyLogo} alt="Zyapaar logo" />
            <span className='text-gray-100'>Loading ...</span>
        </div>
    </div>
  )
}
export default Loading