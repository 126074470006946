import React from 'react';
import { usePagination, DOTS } from '../hooks/usePagination';


const Pagination = (props: any) => {

    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
    } = { ...props, currentPage: props.currentPage, pageSize: props.pageSize, totalCount: props.totalCount, onPageChange: props.onPageChange };
    const paginationRange: any = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    if (currentPage < 0 || paginationRange?.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };
    return (
        <ul
            className="pagination py-5"
        >
            <li className={`page-item previous ${(currentPage + 1) === 1 ? 'disabled' : ''}`}>
                <a href="#" className="page-link" onClick={onPrevious}>
                    <i className="previous"></i>
                </a>
            </li>

            {paginationRange?.map((pageNumber: number | String | any, i: number) => {
                if (pageNumber === DOTS) {
                    return <li
                        key={i}
                        className='page-item py-3'
                    >&#8230;</li>;
                }

                return (
                    <li
                        key={i}
                        className={`page-item ${(pageNumber - 1) === props.currentPage ? 'active' : ""}`}
                        onClick={() => onPageChange(pageNumber - 1)}
                    >
                        <a href="#" className="page-link" >
                            {pageNumber}
                        </a>
                    </li>
                );
            })}
            <li className={`page-item next ${(currentPage + 1) === paginationRange[paginationRange?.length - 1] ? 'disabled' : ''}`}>
                <a href="#" className="page-link" onClick={onNext}>
                    <i className="next"></i>
                </a>
            </li>
        </ul>
    )
}
export default Pagination;