import React, { useEffect, useState } from 'react'
import { Controller, ControllerFieldState, ControllerRenderProps, FieldValues, useForm, UseFormStateReturn } from 'react-hook-form';
import { KTSVG } from '../../../../_metronic/helpers'
import Select from 'react-select';
import CallFor from '../../../routing/CallFor';

const EditEmployee = (props:any) => {

    const {handleSubmit,control} = useForm({ });
    const [designation, setDesignation] = useState<any[]>([]);

    useEffect(()=>{designationList()},[])

    const designationList = async () => {
      const response = await CallFor('api/admin/v1/designations/findAll','GET',null,'Auth')
      if (response.status===200) {
        const res = await response.json();
        const states = await res.data.map(
          (d:{id:any;designationName:any})=>({
            value:d.id,
            label:d.designationName
          })
        );
        setDesignation(states);
      }
    }

    const closeModal1 = () => {
        props.editRef.current!.style!.display = "none"
        props.editRef.current!.classList.remove("show")
        props.editRef.current!.removeAttribute("aria-modal")
        props.editRef.current!.removeAttribute("role")
        props.editRef.current!.setAttribute("aria-hidden", "true");
        const divElement = document.getElementsByClassName('modal-backdrop')
        divElement[0].remove()
        clearError();
      }

    const handleEditChange =(event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>)=>{
        const validationField = [event.target.name]+'_error'
        props.setEditEmployeeData((prev: any) => ({ ...prev, [event.target.name]: event.target.value,[validationField]: ''}));
        clearError();
    }

    
    const validationSchema =()=>{
        let isValid = true;
        if (props.editEmployeeData.name === "") {
            props.setEditEmployeeData((prev:any)=>({...prev, name_error: "Enter employee name"}))
            isValid= false
        }else
        if (props.editEmployeeData.name?.length<=2) {
            props.setEditEmployeeData((prev:any)=>({...prev, name_error: "Minimum 2 character"}))
            isValid= false
        }else
        if (props.editEmployeeData.name?.length>=50) {
            props.setEditEmployeeData((prev:any)=>({...prev, name_error: "Maximum 50 character"}))
            isValid= false
        }else
        if (props.editEmployeeData.name.match(/[0-9]/)) {
            props.setEditEmployeeData((prev:any)=>({...prev, name_error: "Only alphabets allowed"}))
            isValid= false
        }
        if (props.editEmployeeData.code === '') {
            props.setEditEmployeeData((prev:any)=>({...prev, code_error: "Enter employee code"}))
            isValid= false
        }
        if (props.editEmployeeData.designationId === '') {
            props.setEditEmployeeData((prev:any)=>({...prev, designationId_error: "Select designation"}))
            isValid= false
        }
        if (props.editEmployeeData.role === '') {
            props.setEditEmployeeData((prev:any)=>({...prev, role_error: "Enter employee role"}))
            isValid= false
        }
        if (props.editEmployeeData.mobile === '') {
            props.setEditEmployeeData((prev:any)=>({...prev, mobile_error: "Enter mobile number"}))
            isValid= false
        }else
        if (props.editEmployeeData.mobile.match(/[a-zA-Z]/) || props.editEmployeeData.mobile.match(/[.*+?^${}@#=%()|[\]\\]/)) {
            props.setEditEmployeeData((prev:any)=>({...prev, mobile_error: "Mobile number must be digit only"}))
            isValid= false
        }else
        if (props.editEmployeeData.mobile.length <10) {
            props.setEditEmployeeData((prev:any)=>({...prev, mobile_error: "Enter 10 digit mobile number"}))
            isValid= false
        }else
        if (props.editEmployeeData.mobile.length >10) {
            props.setEditEmployeeData((prev:any)=>({...prev, mobile_error: "Mobile number is invalid"}))
            isValid= false
        }
        if (props.editEmployeeData.username === '') {
            props.setEditEmployeeData((prev:any)=>({...prev, username_error: "Enter user name"}))
            isValid= false
        }
        // if (props.editEmployeeData.password === '') {
        //     props.setEditEmployeeData((prev:any)=>({...prev, password_error: "Enter password"}))
        //     isValid= false
        // }
        // if (props.editEmployeeData.status === '') {
        //     props.setEditEmployeeData((prev:any)=>({...prev, status_error: "Select Status"}))
        //     return false
        // }
        return isValid
    }

    const clearError =()=>{
      props.setEditEmployeeData((prev:any)=>({...prev,name_error:'',code_error:'',designationId_error:'',role_error:'',mobile_error:'',username_error:'',password_error:'',
      // status_error:''
    }))
      }

    const submitHandler =async()=>{
        if (validationSchema()) {
            const jasonData = {
                name:props.editEmployeeData.name,
                designationId:props.editEmployeeData.designationId,
                mobile:props.editEmployeeData.mobile,
                code:props.editEmployeeData.code,
                role:props.editEmployeeData.role,
                username:props.editEmployeeData.username,
                // password:props.editEmployeeData.password,
                status:'ACTIVE'
            }
            const data = JSON.stringify(jasonData)
          const response = await CallFor('api/admin/v1/users/'+ props.editEmployeeData.id,'PUT',data,'Auth')
           if (response.status===200) {
            closeModal1();
            props.getEmployeeDetail();
           }       
        }
    }

    const designationSelect = (event: any) => {
      if (event !== null && event.value !== undefined) {
          props.setEditEmployeeData({ ...props.editEmployeeData, designationId: event.value, designationId_error: '' });
      }
      else {
          props.setEditEmployeeData({ ...props.editEmployeeData, designationId: '' });
      }
  };
  


  return (

    <div className="modal fade" ref={props.editRef} tabIndex={-1} id="kt_modal_1">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header p-4">
          <h5 className="modal-title">Edit Employee</h5>
          <div
            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <a
              href='#'
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
              onClick={closeModal1}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon svg-icon-2x"
              />
            </a>
          </div>
        </div>
        <div className="modal-body">
          <div className='row'>

            <form onSubmit={handleSubmit(submitHandler)}>
              <div className="mb-3 col-lg-12">
                <label className="form-label">Employee Name*</label>
                <input
                  type="text"
                  placeholder="Enter Employee Name"
                  className={`form-control ${props?.editEmployeeData?.name_error === "" ? '' : 'is-invalid'}`}
                  name='name'
                  id='designation'
                  value={props.editEmployeeData.name}
                   onChange={(e) => handleEditChange(e)}
                />
              </div>
              <p id='error_1'  className='errormsg' >{props.editEmployeeData.name_error}</p>
              <div className="col-lg-12">
                <label className="form-label">Employee Code*</label>
                <input
                  type="text"
                  name='code'
                  className={`form-control ${props?.editEmployeeData?.code_error === "" ? '' : 'is-invalid'}`}
                  placeholder="Enter Employee Code"
                  value={props.editEmployeeData.code}
                   onChange={(e) => handleEditChange(e)}
                />
              </div>
              <p id='error_1'  className='errormsg' >{props.editEmployeeData.code_error}</p>
              <div className="col-lg-12">
               <label className='form-label'>Designation*</label>
              
                <Controller 
                    name='designationId'
                    control={control}
                    render={({field})=>(
                      <Select 
                        {...field}
                        isClearable
                        isSearchable={true}
                        placeholder='Select Designation'
                        value={designation.filter(
                          (obj)=>obj.value === props.editEmployeeData.designationId)}
                          onChange={designationSelect}
                          id='designation'
                          options={designation}
                            className={props?.editEmployeeData?.designationId_error? 'error-border':''}
                          />
                    )}
                    />
              </div>
              <p id='error_1'  className='errormsg' >{props.editEmployeeData.designationId_error}</p>
              <div className="col-lg-12">
                <label className="form-label">Role*</label>
                <input
                  type="text"
                  name='role'
                  className={`form-control ${props?.editEmployeeData?.role_error === "" ? '' : 'is-invalid'}`}
                  placeholder="Enter role"
                  value={props.editEmployeeData.role}
                   onChange={(e) => handleEditChange(e)}
                />
              </div>
              <p id='error_1'  className='errormsg' >{props.editEmployeeData.role_error}</p>
              <div className="col-lg-12">
                <label className="form-label">Mobile Number*</label>
                <input
                  type="text"
                  placeholder="Enter Mobile Number"
                  name='mobile'
                  className={`form-control ${props?.editEmployeeData?.mobile_error === "" ? '' : 'is-invalid'}`}
                  value={props.editEmployeeData.mobile}
                  onChange={(e) => handleEditChange(e)}
                />
              </div>
              <p id='error_1'  className='errormsg' >{props.editEmployeeData.mobile_error}</p>
              <div className="col-lg-12">
                <label className="form-label">User Name*</label>
                <input
                  type="text"
                  name="username"
                  className={`form-control ${props?.editEmployeeData?.username_error === "" ? '' : 'is-invalid'}`}
                  placeholder="Enter UserName"
                  value={props.editEmployeeData.username}
                   onChange={(e) => handleEditChange(e)}
                />
              </div>
              <p id='error_1'  className='errormsg' >{props.editEmployeeData.username_error}</p>
              {/* <div className="col-lg-12">
                <label className="form-label">Password*</label>
                <input
                  type="password"
                  placeholder="Enter password"
                  className={`form-control ${props?.editEmployeeData?.password_error === "" ? '' : 'is-invalid'}`}
                  name='password'    
                  value={props.editEmployeeData.password}
                   onChange={(e) => handleEditChange(e)}
                  />
              </div>
              <p id='error_1'  className='errormsg' >{props.editEmployeeData.password_error}</p> */}
              {/* <div className="col-lg-12">
                <label className='form-label'>Status</label>
                <select
                  name='status'
                  id='selectstatus'
                  className={`form-control ${props?.editEmployeeData?.status_error === "" ? '' : 'is-invalid'}`}
                  value={props.editEmployeeData.status}
               onChange={(e) => handleEditChange(e)}
               >
                  <option value=''>Select a Status...</option>
                  <option value='ACTIVE'>ACTIVE</option>
                  <option value='INACTIVE'>INACTIVE</option>
                </select>
              </div>
              <p id='error_1'  className='errormsg' >{props.editEmployeeData.status_error}</p> */}
              <div className="modal-footer p-0 ms-auto w-100 border-0 mt-2">
                <button type="submit" className="btn btn-primary"
                  // data-bs-dismiss="modal"
                  id='saveBtn'
                >
                  Save Employee
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default EditEmployee