import { UrlType } from "./UrlType";

const GlobalProperties = {
    urlParam: 'https://admin.zyapaar.com/',
    localUrlParam: 'http://192.168.7.119:7050/',
    testParam: 'http://192.168.7.41:7017/',
    environment: UrlType.LIVE,
    // androidAppBuild: 22,
    // androidAppVersion: '2.0.5',
    // iosAppBuild: 14,
    // iosAppVersion: '2.0.4',
    // forceUpdate: true,
    // updateMessage: 'A new version of Zyapaar is available. Please update to version'
}
export default GlobalProperties;