import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { PageTitle } from '../../../../_metronic/layout/core'
import Pagination from '../../../components/Pagination'
import Select from 'react-select';
import CallFor from '../../../routing/CallFor'
import { useAuth } from '../../auth'
import { convertResponsetojsonBig } from '../../../../_metronic/layout/core/convertToBigJson'


const AddCustomer = () => {

  const [page, setPage] = useState<number>(0);
  const [totalPageNo, setTotalPageNo] = useState<number>(0);
  const [knowledgePartner, setKnowledgePartner] = useState<any[]>([]);
  const [industry, setIndustry] = useState<any[]>([]);
  const [search, setSearch] = useState<any>({
    Name: "",
    Name_error: '',
    industry: "",
    industry_error: ''
  });
  const [isclear, setIsClear] = useState(false);
  const navigate = useNavigate()

  useEffect(() => {
    getknowledgePartner();
  }, [isclear, page])

  useEffect(() => {
    getIndstury();
  }, []);

  const {
    control
  } = useForm({})


  const getIndstury = async () => {
    const response = await fetch(`${process.env.NODE_ENV === "development" ? "http://192.168.7.41:7777" : "https://admin.zyapaar.com"}/api/public/v2/industries`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxMDQ3NDA2NDQyNTA0Nzk4MjA4Iiwicm9sZXMiOiJST0xFX1VTRVIiLCJpYXQiOjE2Njk3OTE1NTUsImV4cCI6Mzc2Njk3OTE1NTV9.swGYxmTnnfsUgb1Ccf1h-BGQisW1WSscEemEAzLfUPc'
      }
    });
    if (response.status === 200) {
      const res = await convertResponsetojsonBig(response);
      const states = await res.data.map(
        (d: { subIndustryID: any; subIndustry: any }) => ({
          value: d.subIndustryID,
          label: d.subIndustry
        })
      );
      setIndustry(states);
    }
  }

  const industrySearchHandler = (event: any) => {
    if (event !== null && event.value !== undefined) {
      setSearch({ ...search, industry: event.value, industry_error: '' });
    }
    else {
      setSearch({ ...search, industry: '', industry_error: '' });
    }
  };

  const getknowledgePartner = async () => {
    setSearch((prev: any) => ({ ...prev, Name_error: '', industry_error: '' }))
    try {
      const response = await CallFor('api/admin/v1/knowledge-customers?companyName=' + search.Name + "&industryName=" + search.industry + "&status=&page=" + page + "&size=10", 'GET', null, 'Auth')
      const res = await response.json();
      if (res?.length === 0) {
        setPage(0);
        setTotalPageNo(0);
      }
      setTotalPageNo(Number(res.data.pages))
      setKnowledgePartner(res.data.content);

    } catch (error) {
      console.log(error)
    }
    setIsClear(false)
  }

  const searchValidate = () => {
    if (search.Name === '' && search.industry === '') {
      setSearch((prev: any) => ({ ...prev, Name_error: 'Enter Company Name', industry_error: 'Select industry' }))
      return false
    }
    return true
  }

  const searchCustomer = () => {
    if (searchValidate()) {
      setPage(0)
      getknowledgePartner()
    }
  }

  const addkpcustomer = () => {
    navigate('/addknowledgepartner')
  }

  // const editKnowledgePartner =(id:string)=>{
  //   navigate(`/editKnowledgePartner/${id}`)
  // }

  // const deleteKnowledgePartner =async(id:string)=>{
  //   setKnowledgePartner(knowledgePartner.filter((item) => item.id !== id));
  //   const response = await CallFor('admin/api/v1/knowledge-customers/'+ id,'DELETE',null,'test')
  //   if (response.status===204) {
  //     getknowledgePartner();
  //   }
  // }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const validationField = [event.target.name] + '_error'
    setSearch({ ...search, [event.target.name]: event.target.value, [validationField]: '' })
  }

  const clearSearch = () => {
    setPage(0);
    setSearch((prev: any) => ({ ...prev, Name: "", Name_error: '', industry: "", industry_error: '' }))
    getknowledgePartner();
    setIsClear(true)
  }

  return (
    <div>

      <PageTitle >Knowledge Partner - Customer Details</PageTitle>
      <div className='card mb-5 p-5'>
        <div className='row'>
          <div className="mb-3 col-lg-3">
            <label className="form-label"> Company Name</label>
            <input
              type="text"
              className={`form-control ${search?.Name_error === "" ? '' : 'is-invalid'}`}
              placeholder="Name"
              name='Name'
              value={search.Name}
              onChange={(e) => handleSearch(e)}
            />
            <p id='error_1' className='errormsg' >{search?.Name_error}</p>
          </div>


          <div className="col-lg-3">
            <label className='form-label'>Industry</label>
            <Controller
              name="industry"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  isClearable
                  isSearchable={true}
                  placeholder='Select Industry'
                  value={industry.filter(
                    (obj) => obj.value === search.industry)}
                  className={search.industry_error ? 'error-border' : ''}
                  onChange={industrySearchHandler}
                  id="Industry"
                  options={industry}
                // styles={customStyles}
                />
              )} />
            <p id='error_1' className='errormsg' >{search?.industry_error}</p>
          </div>
          <div className="mb-3 col-lg-3 mt-8">
            <button type='submit' className='btn btn-primary' onClick={searchCustomer} >Search</button>
            <button type='submit' className='btn btn-danger clear-btn' onClick={clearSearch}>Clear</button>
          </div>
        </div>

      </div>

      <div className='card mb-5'>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Customer Details</span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Add Knowledge Partner Customer'
          >
            <button type="button" className="btn btn-primary btn-sm" onClick={addkpcustomer} >Add Knowledge Partner Customer</button>
            {/* <Link to='/addknowledgepartner'>Addd</Link> */}
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                  <th className='min-w-150px'>Company Name</th>
                  <th className='min-w-140px'>Plan Name</th>
                  <th className='min-w-120px'>Industry</th>
                  <th className='min-w-120px'>Amount</th>
                  <th className='min-w-100px'>PaymentDate</th>
                  <th className='min-w-100px'>webinarCredit</th>
                  <th className='min-w-100px'>email</th>
                  <th className='min-w-100px'>contactNo</th>
                  {/* <th className='min-w-100px text-end'>Actions</th> */}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}

              {
                knowledgePartner?.length > 0 ?
                  knowledgePartner.map((data, i) => {
                    return (
                      data.status === 'ACTIVE'
                        ?
                        <tbody key={i}>
                          <tr>
                            <td>
                              <div className='d-flex align-items-center'>
                                {data.companyName}
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                {data.planName}
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                {data.industryName}
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                {data.amount}
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                {data.startDate?.split('T')[0]?.split("-")?.reverse().join("-")}
                                {/* {data.paymentDate} */}
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                {data.webinarCredit}
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                {data.email}
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                {data.contactNo}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                        :
                        ''
                    )
                  })
                  :
                  <tbody>
                    <tr>
                      <td colSpan={9}>
                        <h6 className='text-center bg-gray-200i p-4 text-gray-800 mb-0 mt-6'>Data Not Found</h6>
                      </td>
                    </tr>
                  </tbody>
              }
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
      </div>
      {/* begin::Body */}

      {
        totalPageNo > 0 &&
        <Pagination
          totalCount={totalPageNo}  //total number of data
          siblingCount={1} // number of extra elements around avtive page
          currentPage={page} //current active page
          onPageChange={setPage} //page change handler state
          pageSize={10} // data per page
        />}

    </div>
  )
}
export default AddCustomer
