import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { FormUpdate } from './FormUpdate';
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from 'react-hook-form';
import CallFor from '../../../routing/CallFor';
import { toast } from 'react-toastify'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap';
import { mediaUploader } from './ImageBucket';
const convertBigIntToString = (bigIntNumber: any) => {
    return BigInt(bigIntNumber).toString()
}
const user = [
    { value: '0', label: 'SIDE_MENU' },
    { value: '1', label: 'RECIEVED' },
    { value: '2', label: 'SEND' },
    { value: '3', label: 'MY_NETWORK' },
    { value: '4', label: 'USER_PROFILE' },
    { value: '5', label: 'COMPANY_PRODUCT' },
    { value: '6', label: 'TEAM_MEMBER' },
];

const CreativeUpdates = ({ perticularCreative, setCreativeData }: any) => {
    const [updateModal, setUpdateModal] = useState(false);
    const datePicker = new Date().toISOString().split("T")[0];
    const startDateTime = perticularCreative?.startDate?.split(' ')
    const endDateTime = perticularCreative?.endDate?.split(' ')
    var startDate = startDateTime[0].split("-").reverse().join("-");
    var endDate = endDateTime[0].split("-").reverse().join("-");
    const time = new Date().toLocaleTimeString().split(" ")[0];
    const sceduleTime = time?.slice(0, 5);
    const currentDate = new Date().toISOString().split("T")[0];

    useEffect(() => {
        setValue('userType', { label: perticularCreative?.bannerType, value: user?.filter(item => item?.label === perticularCreative?.bannerType)[0]?.value })
        setValue('startDate', startDate)
        setValue('startTime', startDateTime[1] as any)
        setValue('endDate', endDate)
        setValue('endTime', endDateTime[1] as any)
    }, [updateModal])

    const { control, handleSubmit, clearErrors, reset, watch, formState: { errors }, setValue, getValues }: any = useForm({
        resolver: yupResolver(FormUpdate()),
        mode: 'onChange'
    });
    const compareDate = (date: string, time: string) => {
        const currentDate2 = new Date();
        const expireDate = new Date(`${date} ${time}`)
        const isEndDateLower = currentDate2.getTime() > expireDate.getTime();
        if (isEndDateLower) {
            return true
        } else {
            return false
        }
    }

    const updtateCreativehandler = async () => {
        try {
            let finalMediaUrl = perticularCreative?.mediaUrl;
            if (getValues('logo') !== undefined) {
                const url = await mediaUploader([getValues('logo')])
                if (url?.success) {
                    finalMediaUrl = url?.data[0]
                }
            }
            const filteredData = user?.filter(item => item?.label === perticularCreative?.bannerType);
            let jsonData: any = {
                // bannerType: Number(filteredData[0]?.value),
                mediaUrl: finalMediaUrl,
                startDate: `${getValues('startDate').split("-").reverse().join("-")} ${getValues('startTime')}`,
                endDate: `${getValues('endDate').split("-").reverse().join("-")} ${getValues('endTime')}`,
            }
            const response = await CallFor(`api/admin/v1/banner/creative/update/${convertBigIntToString(perticularCreative?.id)}`, 'PUT', JSON.stringify(jsonData), 'Auth');
            const json1response = await response.json();
            if (response.status === 200) {
                toast.success('Successfully Update')
                setUpdateModal(false);
                setCreativeData((prev: any) => prev?.map((item: any) => item.id === perticularCreative?.id ? { ...perticularCreative, ...jsonData, bannerType: filteredData[0].label } : item))
            } else {
                toast.error(json1response?.error?.message ? json1response?.error?.message : "please do some changes for updates");
                // setUpdateModal(false);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const closeModalHander = () => {
        setUpdateModal(false);
        reset();
        clearErrors();
    }
    return (
        <>
            <button
                disabled={compareDate(endDate, endDateTime[1])}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                onClick={() => { setUpdateModal(true); }}
            >
                <KTSVG
                    path='/media/icons/duotune/art/art005.svg'
                    className='svg-icon-3'
                />
            </button>

            {updateModal && <Modal show={updateModal} className='modal-lg'>
                <Modal.Header>

                    <Modal.Title>Creative Update</Modal.Title>
                    <div
                        className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={closeModalHander}
                    >
                        <KTSVG
                            path="/media/icons/duotune/arrows/arr061.svg"
                            className="svg-icon svg-icon-2x"
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='card-body py-3'>
                        <div className='table-responsive'>
                            <p className='inactiveName'>Are you sure you want to Update Creative Banner ?</p>
                            <div className='card mb-5 p-5'>
                                <div className='row'>
                                    <form
                                        onSubmit={handleSubmit(updtateCreativehandler)}
                                        id='UpdateCreative'
                                    >
                                        <div className='col-lg-6 offset-lg-3' >

                                            <div className="mb-3 col-lg-12">
                                                <label className="form-label">Image / Logo </label>
                                                <Controller
                                                    name="logo"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input
                                                            type="file"
                                                            className={`form-control ${errors?.logo ? 'is-invalid' : ""}`}
                                                            placeholder=". Image / Logo - Ur"
                                                            accept="image/png, image/jpg, image/jpeg"
                                                            onChange={(e: any) => field.onChange(e.target.files[0])}
                                                        />
                                                    )}
                                                />
                                                <p id='error_1' className='errormsg' >{errors?.logo?.message}</p>
                                            </div>

                                            {/* <div className="mb-3 col-lg-12">
                                                <label className='form-label'>User Type*</label>
                                                <Controller
                                                    name='userType'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            // isClearable
                                                            isSearchable={true}
                                                            placeholder='Select Status'
                                                            className={errors?.userType ? 'error-border' : ''}
                                                            options={user}
                                                        />

                                                    )}
                                                />
                                                {errors?.userType && <p className='errormsg' >{errors?.userType?.message}</p>}
                                            </div> */}

                                            <div className="mb-3 col-lg-12">
                                                <label className="form-label">Scheduling*</label>
                                                <div className='row'>
                                                    <div className="mb-6 col-lg-6">
                                                        <Controller
                                                            name='startDate'
                                                            control={control}
                                                            render={({ field }) => (
                                                                <input
                                                                    {...field}
                                                                    name='startDate'
                                                                    type="date"
                                                                    id="datePickerId"
                                                                    className={`form-control ${errors?.startDate ? 'is-invalid' : ''}`}
                                                                    min={datePicker}
                                                                />
                                                            )}
                                                        />
                                                        {errors?.startDate && <p className='errormsg' >{errors?.startDate?.message}</p>}
                                                    </div>

                                                    <div className="mb-6 col-lg-6">
                                                        <Controller
                                                            name='startTime'
                                                            control={control}
                                                            render={({ field }) => (
                                                                <input
                                                                    {...field}
                                                                    name='startTime'
                                                                    type="time"
                                                                    className={`form-control ${errors?.startTime ? 'is-invalid' : ''}`}
                                                                    min={watch('startDate') === currentDate ? sceduleTime : ""}
                                                                />
                                                            )}
                                                        />
                                                        {errors?.startTime && <p id='error_1' className='errormsg' >{errors?.startTime?.message}</p>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-3 col-lg-12">
                                                <label className="form-label">End Time*</label>
                                                <div className='row'>

                                                    <div className="mb-6 col-lg-6">
                                                        <Controller
                                                            name='endDate'
                                                            defaultValue={"16-05-2023"}
                                                            control={control}
                                                            render={({ field }) => (
                                                                <>
                                                                    <input
                                                                        {...field}
                                                                        type="date"
                                                                        className={`form-control ${errors?.endDate ? 'is-invalid' : ''} `}
                                                                        min={watch('startDate')}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors?.endDate && <p className='errormsg' >{errors?.endDate?.message}</p>}
                                                    </div>

                                                    <div className="mb-6 col-lg-6">
                                                        <Controller
                                                            name='endTime'
                                                            control={control}
                                                            render={({ field }) => (
                                                                <input
                                                                    {...field}
                                                                    name='endTime'
                                                                    type="time"
                                                                    className={`form-control ${errors?.endTime ? 'is-invalid' : ''}`}
                                                                    min={
                                                                        (watch('endDate') === currentDate && watch('startDate') === currentDate)
                                                                            ||
                                                                            (watch('endDate') === watch('startDate')) ? watch('startTime') : ""}
                                                                />
                                                            )}
                                                        />
                                                        {errors?.endTime && <p className='errormsg' >{errors?.endTime?.message}</p>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body >
                <Modal.Footer>
                    <button form='UpdateCreative' className="btn btn-secondary btn-sm" type="submit">Yes</button>
                    <button className="btn btn-primary btn-sm" onClick={closeModalHander}>No</button>
                </Modal.Footer>
            </Modal >
            }
        </>
    )
}
export default CreativeUpdates;