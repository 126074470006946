import { type } from 'os'
import React, { useEffect, useState } from 'react'
import { Controller, useForm, UseFormHandleSubmit } from 'react-hook-form'
import { PageTitle } from '../../../../_metronic/layout/core'
import Pagination from '../../../components/Pagination'
import CallFor from '../../../routing/CallFor'
import Select from 'react-select';
import Loading from '../components/Loading'

const userInitialValue = {
    identificationType: "",
    identificationNumber: "",
    type: "",
    identificationType_error: "",
    identificationNumber_error: "",
    type_error: "",
    startdate: "",
    enddate: "",
    enddate_error: "",
    startdate_error: "",
    companyName: "",
    mobileNo: "",
    mobileNo_error: "",
    userName: ""
}
type UserSubmitForm = {
    type: string;
    identificationType: string;
}
const UserSearch: React.FC = () => {

    const userType = [
        { value: 'REG', label: 'HALF' },
        { value: 'FULL', label: 'FULL' }
    ]

    const identificationtype = [
        { value: 'GSTIN', label: 'GSTIN' },
        { value: 'PAN NUMBER', label: 'PAN NUMBER' },
        // { value: 'MOBILE NUMBER', label: 'MOBILE NUMBER' }
        { value: 'UDYAM ADHAR', label: 'UDYAM ADHAR' },
        { value: 'UDYOG ADHAR', label: 'UDYOG ADHAR' },

    ]

    const [details, setDetails] = useState<Object[]>([]);
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState<Boolean>(false);
    const [userInputData, setUserInputData] = useState<typeof userInitialValue>(userInitialValue)
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [totalNoOfData, setTotalNoOfData] = useState<number>(0);

    const {
        control
    } = useForm<UserSubmitForm>({})

    useEffect(() => {
        if (totalNoOfData > 0) {
            getUserSearchData({
                type: userInputData.type, search: userInputData.identificationNumber, page: pageNumber, fromDate: userInputData.startdate, toDate: userInputData.enddate, companyName: userInputData.companyName,
                mobileNo: userInputData.mobileNo, userName: userInputData.userName
            })
        }

    }, [pageNumber])

    const getUserSearchData = async (data: any) => {
        setUserInputData((prev: any) => ({
            ...prev, identificationType_error: '', identificationNumber_error: '', type_error: '',
            enddate_error: '', startdate_error: '', mobileNo_error: ''
        }))
        try {
            const apiResponse = await CallFor('api/admin/v1/mis/users-status', 'POST', JSON.stringify(data), 'Auth')
            const jsonResponse = await apiResponse.json();
            if (jsonResponse.data.length === 0) {
                setPageNumber(0);
                setTotalNoOfData(0);
            }
            setDetails(jsonResponse.data.content.content);
            setTotalNoOfData(Number(jsonResponse.data.content.pages))
        } catch (error) {
            setError(error);
        }
    }

    const validationHandler = () => {
        if (userInputData.identificationNumber !== '' && userInputData.identificationType === "") {
            setUserInputData((prev: any) => ({ ...prev, identificationType_error: 'Please Select a identity type' }))
            return false
        }
        if (userInputData.identificationNumber.trim() === '' && userInputData.identificationType.trim() !== "") {
            setUserInputData((prev: any) => ({ ...prev, identificationNumber_error: 'Please Select a identity number' }))
            return false
        }
        if (userInputData.identificationNumber !== '' && userInputData.identificationType === 'GSTIN') {

            if (!userInputData.identificationNumber.trim().match(/[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1}/)) {
                setUserInputData((prev: any) => ({ ...prev, identificationNumber_error: 'Enter valid GST number' }))
                return false
            }
            else if (userInputData.identificationNumber.trim().length < 15) {
                setUserInputData((prev: any) => ({ ...prev, identificationNumber_error: 'GST number must be 15 digit' }))
                return false
            }
            else if (userInputData.identificationNumber.trim().length > 15) {
                setUserInputData((prev: any) => ({ ...prev, identificationNumber_error: 'GST number maximum length must be 15 digit' }))
                return false
            }
        }
        if (userInputData.identificationNumber !== '' && userInputData.identificationType === 'PAN NUMBER') {
            if (!userInputData.identificationNumber.trim().match(/[a-zA-Z]{3}[pcftghlabjPCFTGHLABJ]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}/)) {
                setUserInputData((prev: any) => ({ ...prev, identificationNumber_error: 'Enter valid PAN number' }))
                return false
            }
            else if (userInputData.identificationNumber.trim().length < 10) {
                setUserInputData((prev: any) => ({ ...prev, identificationNumber_error: 'Enter valid PAN number' }))
                return false
            }
            else if (userInputData.identificationNumber.trim().length > 10) {
                setUserInputData((prev: any) => ({ ...prev, identificationNumber_error: 'Enter valid PAN number' }))
                return false
            }
        }

        if (userInputData.identificationNumber !== '' && userInputData.identificationType === 'UDYAM ADHAR') {
            const udyamAdharPattern = /^UDYAM-[A-Z]{2}-\d{2}-\d{7}$/; // Example pattern
            if (!userInputData.identificationNumber.trim().match(udyamAdharPattern)) {
                setUserInputData((prev: any) => ({ ...prev, identificationNumber_error: 'Enter valid UDYAM ADHAR' }))
                return false
            }
        }

        if (userInputData.mobileNo !== '') {
            if (userInputData.mobileNo.trim() === '') {
                setUserInputData((prev: any) => ({ ...prev, identificationNumber_error: 'Enter mobile number number' }))
                return true
            }
            else if (userInputData.mobileNo.trim().length < 10) {
                setUserInputData((prev: any) => ({ ...prev, mobileNo_error: 'Enter valid mobile number' }))
                return false
            }
            else if (userInputData.identificationNumber.trim().length > 10) {
                setUserInputData((prev: any) => ({ ...prev, mobileNo_error: 'Enter valid mobile number' }))
                return false
            }
        }
        if (userInputData.startdate !== '' && userInputData.enddate === '') {
            setUserInputData((prev: any) => ({ ...prev, enddate_error: 'Enter end date' }))
            return false
        }
        if (userInputData.startdate === '' && userInputData.enddate !== '') {
            setUserInputData((prev: any) => ({ ...prev, startdate_error: 'Enter end date' }))
            return false
        }
        //userInputData.type === "" &&
        // if (userInputData.identificationType === "" && userInputData.identificationNumber === "" &&
        //     userInputData.enddate === "" && userInputData.startdate === "") {
        //     setUserInputData((prev: any) => ({ ...prev }))
        //     return false
        // }
        //&& userInputData.type === ""
        if (userInputData.identificationType !== "" && userInputData.identificationNumber === "" &&
            userInputData.enddate === "" && userInputData.startdate === "") {
            setUserInputData((prev: any) => ({ ...prev }))
            return false
        }
        return true
    }

    const searchHandler = () => {
        console.log("ffff", validationHandler())
        if (validationHandler()) {
            setPageNumber(0)
            getUserSearchData({
                type: userInputData.type, search: userInputData.identificationNumber, page: pageNumber, fromDate: userInputData.startdate, toDate: userInputData.enddate, companyName: userInputData.companyName,
                mobileNo: userInputData.mobileNo, userName: userInputData.userName
            })
        }
    }
    const clearHandler = () => {
        setUserInputData(userInitialValue)
        setTotalNoOfData(0);
        setDetails([]);
    }

    const dataChangeHandler = (e: any) => {
        setUserInputData((prev: any) => ({ ...prev, [e.target.name]: e.target.value, [`${e.target.name}_error`]: "" }))
        setUserInputData((prev: any) => ({ ...prev, identificationType_error: '' }))
    }

    const changeHandler = (e: any, isErrorHandler = true) => {

        if (isErrorHandler === true) {
            setUserInputData({ ...userInputData, [e.target.name]: e.target.value, [`${e.target.name}_error`]: "" })
        }
        else {
            setUserInputData({ ...userInputData, [e.target.name]: e.target.value })
        }

    }
    const UserSearchTypeHandler = (event: any) => {
        if (event !== null && event.value !== undefined) {
            setUserInputData({ ...userInputData, type: event.value, type_error: '' });
        }
        else {
            setUserInputData({ ...userInputData, type: '' });
        }
    }

    const identitypeHandler = (event: any) => {
        if (event !== null && event.value !== undefined) {
            setUserInputData({ ...userInputData, identificationType: event.value, identificationType_error: '' });
        }
        else {
            setUserInputData({ ...userInputData, identificationType: '' });
        }
    }

    const downloadExcelSheet = async () => {
        try {
            setLoading(true);
            const response = await CallFor('api/admin/v1/mis/users-status/excel', 'POST', JSON.stringify({
                type: userInputData.type, search: userInputData.identificationNumber, fromDate: userInputData.startdate, toDate: userInputData.enddate, companyName: userInputData.companyName,
                mobileNo: userInputData.mobileNo, userName: userInputData.userName
            }), 'Auth')
            const fileName: any = `User_Status_Details_${Date.now()}.xlsx`
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();
        }
        catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    return (
        <div>
            <PageTitle >User Search</PageTitle>
            <div className='card mb-5 p-5'>
                <div className='row'>
                    <div className="col-lg-3">
                        <label className='form-label'>Identification Type</label>
                        <Controller
                            name='identificationType'
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    isClearable
                                    isSearchable={true}
                                    placeholder='Select Staus'
                                    className={userInputData.identificationType_error ? 'error-border' : ''}
                                    value={identificationtype.filter(
                                        (obj) => obj.value === userInputData.identificationType)}
                                    onChange={identitypeHandler}
                                    id="type"
                                    options={identificationtype}
                                />
                            )} />
                        <p id='error_1' className='errormsg' >{userInputData?.identificationType_error}</p>
                    </div>

                    <div className="mb-3 col-lg-3">
                        <label className="form-label">Identification Number</label>
                        <input
                            value={userInputData!.identificationNumber.toUpperCase()}
                            onChange={dataChangeHandler}
                            name="identificationNumber"
                            type="text"
                            className={`form-control ${userInputData.identificationNumber_error === "" ? '' : 'is-invalid'}`}
                            placeholder="Search Identification Number"
                        />
                        <p className='errormsg' >{userInputData.identificationNumber_error}</p>
                    </div>
                    <div className="col-lg-3">
                        <label className='form-label'>Type</label>
                        <Controller
                            name='type'
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    isClearable
                                    isSearchable={true}
                                    placeholder='Select Staus'
                                    className={userInputData.type_error ? 'error-border' : ''}
                                    value={userType.filter(
                                        (obj) => obj.value === userInputData.type)}
                                    onChange={UserSearchTypeHandler}
                                    id="type"
                                    options={userType}
                                />
                            )} />
                        <p id='error_1' className='errormsg' >{userInputData?.type_error}</p>
                    </div>
                    <div className="col-lg-3">
                        <label className="form-label">From Date*</label>
                        <input
                            type="date"
                            name='startdate'
                            placeholder="Enter StartDate"
                            className={`form-control calendar-picker-indicator ${userInputData.startdate_error === "" ? '' : 'is-invalid'}`}
                            value={userInputData!.startdate}
                            onChange={dataChangeHandler}
                        />
                        <p className='errormsg' >{userInputData.startdate_error}</p>
                    </div>
                    <div className="col-lg-3">
                        <label className="form-label">To Date*</label>
                        <input
                            type="date"
                            name='enddate'
                            min={userInputData?.startdate}
                            placeholder="Enter EndDate"
                            value={userInputData!.enddate}
                            className={`form-control calendar-picker-indicator ${userInputData.enddate_error === "" ? '' : 'is-invalid'}`}
                            onChange={dataChangeHandler}
                        />
                        <p className='errormsg' >{userInputData.enddate_error}</p>
                    </div>
                    <div className="mb-3 col-lg-3">
                        <label className="form-label">Company Name</label>
                        <input
                            value={userInputData!.companyName}
                            onChange={(e) => changeHandler(e, false)}
                            name="companyName"
                            type="text"
                            className={`form-control`}
                            placeholder="Search Company Name"
                        />
                    </div>
                    {/* <div className="mb-3 col-lg-3">
                        <label className="form-label">Company Name</label>
                        <input
                            value={userInputData!.companyName.toUpperCase()}
                            onChange={dataChangeHandler}
                            name="companyName"
                            type="text"
                            className={`form-control ${userInputData.companyName_error === "" ? '' : 'is-invalid'}`}
                            placeholder="Search Company Name"
                        />
                    </div> */}
                    <div className="mb-3 col-lg-3">
                        <label className="form-label">Mobile No.</label>
                        <input
                            value={userInputData!.mobileNo}
                            onChange={(e) => changeHandler(e, true)}
                            name="mobileNo"
                            type="text"
                            className={`form-control ${userInputData.mobileNo_error === "" ? '' : 'is-invalid'}`}
                            placeholder="Search Mobile Number"
                        />
                        <p className='errormsg' >{userInputData.mobileNo_error}</p>
                    </div>
                    <div className="mb-3 col-lg-3">
                        <label className="form-label">User Name</label>
                        <input
                            value={userInputData!.userName}
                            onChange={(e) => changeHandler(e, false)}
                            name="userName"
                            type="text"
                            className={`form-control`}
                            placeholder="Search User Name"
                        />
                        {/* <p className='errormsg' >{userInputData.identificationNumber_error}</p> */}
                    </div>
                    <div className="mb-3 col-lg-4 mt-8">
                        <button
                            type='submit'
                            className='btn btn-primary'
                            onClick={searchHandler}
                        >Search</button>
                        <button type='submit' className='btn btn-danger clear-btn' onClick={clearHandler}>Clear</button>


                    </div>
                    {details?.length > 0 ?
                        <div className="mb-3 col-lg-6 mt-8 text-lg-right text-lg-center">
                            <button onClick={downloadExcelSheet} className="btn btn-primary clear-btn ms-0">Download Excel Sheet</button>
                        </div> : null}
                </div>

            </div>
            {loading ? <Loading /> : <div className='card mb-5'>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>Search Details</span>
                    </h3>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {details?.length > 0 ?
                        <div className='table-responsive'>
                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                <thead>
                                    <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                                        <th className='min-w-150px'>Name</th>
                                        <th className='min-w-150px'>Identification Number</th>
                                        <th className='min-w-120px'>Phone</th>
                                        <th className='min-w-140px'>Nature Of Business</th>
                                        <th className='min-w-120px'>City</th>
                                        <th className='min-w-120px'>Pin Code</th>
                                        <th className='min-w-100px'>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {details.map((item: any, key: number) => <tr key={key}>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                {item?.fullName ? <>{item?.fullName}</> : "N/A"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                {item?.identityNo ? <> {item?.identityNo}</> : "N/A"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                {item.mobileNo || item?.mobileNo ? <> {
                                                    item.mobileNo ? <>
                                                        {item?.mobileNo}
                                                    </> : <>
                                                        {item?.mobileNo}
                                                    </>
                                                }</> : "N/A"}

                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                {item?.natureOfBusiness ? <>{item?.natureOfBusiness}</> : "N/A"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                {item?.city ? <>{item?.city}</> : "N/A"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                {item?.pincode ? <>{item?.pincode}</> : "N/A"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                {item.createdOn || item?.createdOn ? <> {item.createdOn ? (item?.createdOn?.split('T')[0])?.split("-").reverse().join("-") : (item?.createdOn?.split('T')[0])?.split("-").reverse().join("-")}</> : "N/A"}
                                            </div>
                                        </td>

                                    </tr>)}
                                </tbody>
                            </table>
                        </div>
                        : <tbody>
                            <tr>
                                <td colSpan={9}>
                                    <h6 className='text-center bg-gray-200i p-4 text-gray-800 mb-0 mt-6'>Data Not Found</h6>
                                </td>
                            </tr>
                        </tbody>
                    }
                </div>

                {/* end::Table container */}

                {totalNoOfData > 0 &&
                    <Pagination
                        totalCount={totalNoOfData}  //total number of data
                        siblingCount={1} // number of extra elements around avtive page
                        currentPage={pageNumber} //current active page
                        onPageChange={setPageNumber} //page change handler state
                        pageSize={10} // data per page
                    />}
            </div>}
            {/* begin::Body */}
        </div >
    )
}
export default UserSearch
