import { PageTitle } from '../../../../_metronic/layout/core'
import { KTSVG } from '../../../../_metronic/helpers'
import { Controller, useForm } from 'react-hook-form'
import Select from 'react-select';
import { useEffect, useState } from 'react';
import CallFor from '../../../routing/CallFor';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const user = [
    { value: '0', label: 'ALL' },
    { value: '1', label: 'HALF' },
    { value: '2', label: 'FULL' },
    { value: '3', label: 'ADD_COMPANY' },
    { value: '4', label: 'ADD_PRODUCT' },
    { value: '5', label: 'REFER_A_FRIEND' },
    { value: '6', label: 'GROW_YOUR_NETWORK' },
    { value: '7', label: 'EXTERNAL_ALL' },
    { value: '8', label: 'EXTERNAL_HALF' },
    { value: '9', label: 'EXTERNAL_FULL' },
]

const CreateBanner = () => {
    const d = new Date();
    const h = d.getHours();
    const m = d.getMinutes();
    const datePicker = new Date().toISOString().split("T")[0];
    const currentDate = new Date().toISOString().split("T")[0];
    const time = new Date().toLocaleTimeString().split(" ")[0];

    const options: any = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
    };

    const formatter = new Intl.DateTimeFormat('en-US', options);
    const timeString = formatter.format(new Date());

    const sceduleTime = time?.slice(0, 5);
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [open, setOpen] = useState(false);
    const [openList, setOpenList] = useState(false);
    const [bannerData2, setBannerData2] = useState<any[]>([]);
    const [formData, setFormData] = useState<any>({
        image: null,
        image_error: '',
        userType: "",
        userType_error: '',
        date: "",
        date_error: '',
        time: "",
        time_error: '',
        end_date: "",
        end_date_error: '',
        end_time: "",
        end_time_error: '',
        weburl: "",
        weburl_error: "",

    });
    const { control } = useForm();
    // useEffect(() => {

    const handleChange = (event: { target: { name: any; value: any; }; }) => {
        const validationField = [event.target.name] + '_error'
        setFormData({ ...formData, [event.target.name]: event.target.value, [validationField]: '' })
    }

    const userHandler = (event: any) => {
        if (event !== null && event.value !== undefined) {
            setFormData({ ...formData, userType: event.value, userType_error: '' })
        }
        else {
            setFormData({ ...formData, userType: '', userType_error: '' })
        }
    }

    const handleLogoChange = (event: { target: { files: any; }; }) => {
        const fileSizeInKB = event?.target?.files[0]?.size / 1024;
        if (event?.target?.files?.length > 0) {
            if (!event?.target?.files[0]?.name?.match(/\.(JPG|JPEG|PNG|jpg|jpeg|png)$/)) {
                setIsValid(false);
                setFormData((prev: { target: { files: any; }; }) => ({ ...prev, image_error: 'ImageJPG/JPEG/JFIF/PNG file only' }))
            } else if (fileSizeInKB > 250) {
                setIsValid(false);
                setFormData((prev: { target: { files: any; }; }) => ({ ...prev, image_error: 'Image upto 250 KB allowed', image: event?.target?.files[0] }))
            }
            else {
                setFormData({ ...formData, image: event?.target?.files[0], image_error: '' })
                setIsValid(true);
                setError('')
            }
        }
    }
    const publishHandler = async () => {
        const data = new FormData();
        data.append('bannerImage', formData.image)
        if (validationSchema()) {
            let jsonData: any = {
                userType: Number(formData.userType),
                startDate: `${formData.date.split("-").reverse().join("-")} ${formData.time}`,
                endDate: `${formData.end_date.split("-").reverse().join("-")} ${formData.end_time}`,
            }
            if (formData.userType === "7" || formData.userType === "8" || formData.userType === "9") {
                jsonData = { ...jsonData, webUrl: String(formData.weburl) }
            }
            data.append('dto', JSON.stringify(jsonData));
            const response = await CallFor('api/admin/v1/banner/create', 'POST', data, 'authWithoutContentType');
            const resData = await response.json();
            if (response.status === 201) {
                setOpen(false)
                setOpenList(true);
                setBannerData2(prev => [resData.data, ...prev]);
            }
            else if (response.status === 401) {
            }
        }
    }

    useEffect(() => {
        const getCampaignList2 = async () => {
            try {
                const response = await CallFor('api/admin/v1/banner/list', 'GET', null, 'Auth');
                if (response.status === 200) {
                    const json1response = await response.json();
                    if (json1response.data?.length > 0) {
                        setBannerData2(json1response.data);
                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
        getCampaignList2();
    }, [])

    const ListSave = async () => {
        if (!open && openList) {
            try {
                const listOfBannerId = bannerData2?.map(item => item.id)
                const response = await CallFor('api/admin/v1/banner/ordering', 'POST', JSON.stringify(listOfBannerId), 'AuthwithContentType');
                if (response.status === 200) {
                    setOpenList(false);
                    navigate('/BannerNotification');
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    const validationSchema = () => {
        let isValid = true;
        if (formData?.image === null) {
            setFormData((prev: { target: { files: any; }; }) => ({ ...prev, image_error: "Please select image" }))
            isValid = false
        }
        if (formData?.userType?.trim() === '') {
            setFormData((prev: any) => ({ ...prev, userType_error: "Please select a Device Type" }))
            isValid = false
        }
        if (formData.userType === "7" || formData.userType === "8" || formData.userType === "9") {
            if (formData?.weburl?.trim() === '') {
                setFormData((prev: any) => ({ ...prev, weburl_error: "Please enter url" }))
                isValid = false
            }
            if (!formData?.weburl?.match(/^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/\S*)?$/i)
                // if (!formData?.weburl?.match(/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gi)
            ) {
                setFormData((prev: any) => ({ ...prev, weburl_error: "Please enter valid url" }))
                isValid = false
            }
        }
        if (formData?.date === '') {
            setFormData((prev: any) => ({ ...prev, date_error: "select schedule date" }))
            isValid = false
        }
        if (formData?.time === '') {
            setFormData((prev: any) => ({ ...prev, time_error: "Select schedule time" }))
            isValid = false
        }
        if (formData?.end_date === '') {
            setFormData((prev: any) => ({ ...prev, end_date_error: "select end schedule date" }))
            isValid = false
        }
        if (formData?.end_time === '') {
            setFormData((prev: any) => ({ ...prev, end_time_error: "Select end schedule time" }))
            isValid = false
        }
        if (formData.end_date === formData.date && formData.end_time <= formData.time) {
            setFormData((prev: any) => ({ ...prev, end_time_error: "Select valid end time" }))
            isValid = false
        }
        if (formData.date === currentDate && formData.time <= timeString) {
            setFormData((prev: any) => ({ ...prev, time_error: "Select valid start time" }))
            isValid = false
        }

        if (formData.time?.slice(0, 2) < h) {
            if (formData.time?.slice(3, 6) < m) {
                if (formData.date === currentDate) {
                    setFormData((prev: any) => ({ ...prev, time_error: "The schedule time is invalid" }))
                    isValid = false
                }
            }
        }
        return isValid
    }

    const reviewModal = () => {
        if (validationSchema() && isValid) {
            setOpen(true)
        }
    }

    const handleOnDragEnd = async (result: any) => {
        if (!result.destination) return;
        const items = bannerData2;
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        // const itemsUpdated = items.map(item => item.id)
    }

    const ApiCall = () => {
        publishHandler();
    }

    const createUrl = (file: any) => {
        if (file) {
            return URL.createObjectURL(file)
        } else {
            return ""
        }
    }

    return (
        <div>
            <PageTitle >Create Banner</PageTitle>
            <div className='card mb-5 p-5'>
                <div className='row'>

                    <div className='col-lg-6 offset-lg-3' >

                        <div className="mb-3 col-lg-12">
                            <label className="form-label">Image / Logo </label>
                            <input
                                name='logofile'
                                type="file"
                                className={`form-control ${formData?.image_error === "" ? '' : 'is-invalid'}`}
                                placeholder=". Image / Logo - Ur"
                                accept="image/png, image/jpg, image/jpeg"
                                onChange={handleLogoChange}
                                id='logoimg'
                            />
                            <p id='error_1' className='errormsg' >{formData?.image_error}</p>
                        </div>

                        <div className="mb-3 col-lg-12">
                            <label className='form-label'>User Type*</label>
                            <Controller
                                name='userType'
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        isClearable
                                        isSearchable={true}
                                        placeholder='Select Status'
                                        className={formData.userType_error ? 'error-border' : ''}
                                        value={user.filter(
                                            (obj) => obj.value === formData.userType)}
                                        onChange={userHandler}
                                        id='status'
                                        options={user}
                                    />
                                )}
                            />
                            <p id='error_1' className='errormsg' >{formData.userType_error}</p>
                        </div>
                        {
                            (formData.userType === "7" || formData.userType === "8" || formData.userType === "9") &&
                            <div className="mb-6 col-lg-12">
                                <label className="form-label">Web Url</label>
                                <input
                                    name='weburl'
                                    type="url"
                                    className={`form-control ${formData.weburl_error ? 'is-invalid' : ''}`}
                                    placeholder="Web Url"
                                    value={formData.weburl}
                                    onChange={handleChange}
                                />
                                <p id='error_1' className='errormsg' >{formData?.weburl_error}</p>
                            </div>
                        }

                        <div className="mb-3 col-lg-12">
                            <label className="form-label">Scheduling*</label>
                            <div className='row'>
                                <div className="mb-6 col-lg-6">
                                    <input
                                        name='date'
                                        type="date"
                                        id="datePickerId"
                                        min={datePicker}
                                        value={formData.date}
                                        className={`form-control ${formData?.date_error === "" ? '' : 'is-invalid'}`}
                                        onChange={handleChange}
                                    />
                                    <p id='error_1' className='errormsg' >{formData?.date_error}</p>
                                </div>

                                <div className="mb-6 col-lg-6">
                                    <input
                                        name='time'
                                        type="time"
                                        min={sceduleTime}
                                        value={formData.time}
                                        className={`form-control ${formData?.time_error === "" ? '' : 'is-invalid'}`}
                                        onChange={handleChange}
                                    />
                                    <p id='error_1' className='errormsg' >{formData?.time_error}</p>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3 col-lg-12">
                            <label className="form-label">End Time*</label>
                            <div className='row'>
                                <div className="mb-6 col-lg-6">
                                    <input
                                        name='end_date'
                                        type="date"
                                        id="datePickerId"
                                        min={formData.date}
                                        value={formData.end_date}
                                        className={`form-control ${formData?.end_date_error === "" ? '' : 'is-invalid'}`}
                                        onChange={handleChange}
                                    />
                                    <p id='error_1' className='errormsg' >{formData?.end_date_error}</p>
                                </div>
                                {formData.end_date === currentDate && formData.date === currentDate ? (
                                    <div className="mb-6 col-lg-6">
                                        <input
                                            name='end_time'
                                            type="time"
                                            min={formData.time}
                                            value={formData.end_time}
                                            className={`form-control ${formData?.end_time_error === "" ? '' : 'is-invalid'}`}
                                            onChange={handleChange}
                                        />
                                        <p id='error_1' className='errormsg' >{formData?.end_time_error}</p>
                                    </div>
                                )
                                    : (
                                        <div className="mb-6 col-lg-6">
                                            <input
                                                name='end_time'
                                                type="time"
                                                min={sceduleTime}
                                                value={formData.end_time}
                                                className={`form-control ${formData?.end_time_error === "" ? '' : 'is-invalid'}`}
                                                onChange={handleChange}
                                            />
                                            <p id='error_1' className='errormsg' >{formData?.end_time_error}</p>
                                        </div>
                                    )}
                            </div>
                        </div>
                        <div className="col-lg-12  text-center">
                            <button type='submit' className='btn btn-primary' onClick={reviewModal} >Review</button>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={open} onHide={() => setOpen(true)} >
                <Modal.Header>

                    <Modal.Title>Review Message</Modal.Title>
                    <div
                        className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => setOpen(false)}
                    >
                        <KTSVG
                            path="/media/icons/duotune/arrows/arr061.svg"
                            className="svg-icon svg-icon-2x"
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>

                    <div className="mb-3 col-lg-12">
                        <label className="form-label">Create Banner</label>
                        <div className="border-bottom border-gray-300">
                            <img src={createUrl(formData?.image)} style={{ width: "300px" }} alt='' />
                        </div>
                    </div>

                    <div className="mb-3 col-lg-12 mt-4">
                        <label className="form-label">User Type</label>
                        <div className="border-bottom border-gray-300">
                            {formData.userType === '0' ?
                                'ALL' : formData.userType === '1' ?
                                    'HALF' : formData.userType === '2' ?
                                        'FULL' : formData.userType === '3' ?
                                            'ADD_COMPANY' : formData.userType === '4' ?
                                                'ADD_PRODUCT' : formData.userType === '5' ?
                                                    'REFER_A_FRIEND' : formData.userType === '6' ?
                                                        'GROW_YOUR_NETWORK' : formData.userType === '7' ?
                                                            'EXTERNAL_ALL' : formData.userType === '8' ?
                                                                'EXTERNAL_HALF' : 'EXTERNAL_FULL'
                            }
                        </div>
                    </div>
                    <div className="mb-3 col-lg-12 mt-4">
                        <label className="form-label">Start Time Scheduling</label>
                        <div className="">{formData?.date} {formData?.time}</div>
                    </div>

                    <div className="mb-3 col-lg-12 mt-4">
                        <label className="form-label">End Time Scheduling</label>
                        <div className="">{formData?.end_date} {formData?.end_time}</div>
                    </div>

                    <div className="mb-3 col-lg-12 mt-4">
                        <label className="form-label">Web Url</label>
                        <div className="">{formData?.weburl}</div>
                    </div>

                </Modal.Body>
                <Modal.Footer>

                    <button type="button" className="btn btn-secondary" onClick={() => setOpen(false)} >
                        Cancel
                    </button>

                    <button type="button" className="btn btn-primary" onClick={ApiCall} >
                        Publish
                    </button>

                </Modal.Footer>
            </Modal>

            {/* listing rearrange modal */}
            <Modal show={openList} onHide={() => setOpenList(true)} className='modal-lg' >
                <Modal.Header>

                    <Modal.Title>Banner List</Modal.Title>
                    <div
                        className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => setOpenList(false)}
                    >
                        <KTSVG
                            path="/media/icons/duotune/arrows/arr061.svg"
                            className="svg-icon svg-icon-2x"
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='card-body py-3'>
                        <div className='table-responsive'>
                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                <thead>
                                    <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                                        <th>Banner</th>
                                        <th>User Type</th>
                                        <th>Start</th>
                                        <th>End</th>
                                        <th>Web Url</th>
                                    </tr>
                                </thead>
                                <DragDropContext onDragEnd={handleOnDragEnd}>
                                    <Droppable droppableId="characters">
                                        {(provided) => (
                                            <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                                {
                                                    bannerData2.map((detail, i) => {
                                                        return (
                                                            <Draggable key={i} draggableId={detail.id} index={i}>
                                                                {(provided) => (
                                                                    <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                        <td><img src={detail.url} style={{ width: "50px" }} alt='Banner' /></td>
                                                                        <td>
                                                                            <div className='d-flex justify-content-start flex-column'>
                                                                                {detail.userType}
                                                                            </div>
                                                                        </td>
                                                                        <td>{detail.startDate}</td>
                                                                        <td>{detail.endDate}</td>
                                                                        <td>{detail.webUrl}</td>
                                                                    </tr>
                                                                )}
                                                            </Draggable>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </table>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>

                    <button type="button" className="btn btn-primary" onClick={ListSave}>
                        Save
                    </button>

                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default CreateBanner
