import * as Yup from 'yup';

function showDupes(myarr: any) {

    const data: any = new Set(myarr.filter((elem: any, idx: number, arr: any) => {
        if (elem?.featureName && elem?.featureName?.label) {
            return arr.findIndex((it: any) => it?.featureName?.value === elem?.featureName?.value) !== idx
        }
        return false

    }))
    return [...data]
}

export function CreatePlanValidation(props: any) {
    const decimalRegex = /^\d{1,10}(\.\d{1,2})?$/;
    return Yup.object().shape({

        premiumPlanName: Yup.string()
            .matches(/^[a-zA-Z0-9]*[a-zA-Z0-9 ]*[a-zA-Z0-9]*$/, 'Only alphanumeric characters and spaces are allowed')
            .required()
            .test('required', ' Premium PlanName is Required', function (value: any) {
                return value;
            }),


        startDate: Yup.string()
            .required('Date is required')
            .test('sameDate', "STARTDate & ENDDate should not be same", function (value) {
                return value !== this.parent?.endDate
            }),


        startTime: Yup.string()
            .required(),

        endDate: Yup.string()
            .required('Date is required')
            .test('sameDate', "STARTDate & ENDDate should not be same", function (value) {
                return value !== this.parent?.startDate
            }),

        timeEnd: Yup.string()
            .required(),

        planAmount: props?.premiumUnitTypes ?
            Yup.string().required('Plan Amount is required')
                .max(10, "Maximum 10 Digit allowed")
                .matches(decimalRegex, 'Invalid decimal format')
                .test("valid-amount", "Plan Amount must be greater than 0 and less than or equal to 10 digits", (value) => {
                    if (!value) {
                        return true;
                    }
                    const numericValue = parseFloat(value);
                    return numericValue > 0 && value.length <= 10;
                })
            : Yup.string(),

        planDuration: Yup.number()
            .required('planDuration is required')
            .typeError('planDuration is required')
            .min(1, 'Number must be greater than 0'),

        planDurationType: Yup.object().required("Please select Plan Duration Type")
            .typeError('Please select Plan Duration Type'),

        plan: Yup.array()
            .of(Yup.object().shape({

                premiumUnitTypes: Yup.object().shape({
                    label: Yup.string().required(),
                    value: Yup.string().required()
                })

                    .test('required', ' Duration is Required', function (value: any) {
                        return value !== null && value !== undefined;
                    })
                    .nullable(),


                limit: Yup.number()

                    .typeError('Limit is required')
                    // .integer('Please enter a whole number')
                    .min(0, 'Number must be greater than or equal to 0')
                    .max(32767, 'Number must be less than or equal to 32,767')
                    .required('Limit is required'),

                featureName:
                    Yup.object().shape({
                        label: Yup.string().required(),
                        value: Yup.string().required()
                    })
                        .test('required', 'FeatureName is Required', function (value: any) {
                            // console.log(value, 'vvvvvvvvvvvvvvvvvvvvvvv')
                            return value !== null && value !== undefined;
                        })
                        .nullable(),

            }))
            .test('is-unique', 'Feature Name is Duplicate', function (value: any) {
                return showDupes(value)?.length === 0
            })
    })

}