import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CallFor from '../../../routing/CallFor';
import { convertResponsetojsonBig } from '../../../../_metronic/layout/core/convertToBigJson';
const addpartnerBreadCrumbs: Array<PageLink> = [
    {
        title: 'Knowledge Partner',
        path: '#',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]
const EditKnowledgePartner = () => {
    const { id } = useParams();
    const [values, setValues] = useState<any>({
        organizationName: "",
        ClientContactNumber: "",
        contactNumber: "",
        emailId: "",
        gstNumber: "",
        Plan: "",
        Amount: "",
        Discount: "",
        invoiceAmount: "",
        gstInclude: "",
        cgst: "",
        sgst: "",
        paymentDetail: "",
        date: "",
        industry: "",
        ClientProvide: "",
        url: "",
        logo: "",
        partnerLabel: "",
        webinarCredit: "",
        webinarCode: "",
        organizationName_error: "",
        ClientContactNumber_error: "",
        contactNumber_error: "",
        emailId_error: "",
        gstNumber_error: "",
        Plan_error: "",
        Amount_error: "",
        Discount_error: "",
        invoiceAmount_error: "",
        gstInclude_error: "",
        cgst_error: "",
        sgst_error: "",
        paymentDetail_error: "",
        date_error: "",
        industry_error: "",
        ClientProvide_error: "",
        url_error: "",
        logo_error: "",
        partnerLabel_error: "",
        webinarCredit_error: "",
        webinarCode_error: ""
    });
    const [industry, setIndustry] = useState<any[]>([]);
    const [plan, setPlan] = useState<any[]>([]);
    const { handleSubmit } = useForm({})
    const navigate = useNavigate();
    useEffect(() => {
        getKnowledgePartnerData();
    }, []);

    useEffect(() => {
        getIndstury();
    }, []);

    useEffect(() => {
        kpPlan();
    }, []);

    const kpPlan = async () => {
        const response = await CallFor('api/admin/v1/plans/getAll', 'GET', null, 'Auth')
        if (response.status === 200) {
            const res = await response.json();
            setPlan(res.data);
        }
    }

    const getIndstury = async () => {

        const response = await fetch(`${process.env.NODE_ENV === "development" ? "http://192.168.7.41:7777" : "https://admin.zyapaar.com"}/api/public/v2/industries`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'authorization': 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxMDQ3NDA2NDQyNTA0Nzk4MjA4Iiwicm9sZXMiOiJST0xFX1VTRVIiLCJpYXQiOjE2Njk3OTE1NTUsImV4cCI6Mzc2Njk3OTE1NTV9.swGYxmTnnfsUgb1Ccf1h-BGQisW1WSscEemEAzLfUPc'
            }
        });
        if (response.status === 200) {
            const res = await convertResponsetojsonBig(response);
            setIndustry(res.data);
        }
    }

    const getKnowledgePartnerData = async () => {
        const response = await CallFor('api/admin/v1/knowledge-customers/' + id, 'GET', null, 'Auth')
        if (response.status === 200) {
            const res = await response.json();
            const currentstartDate = new Date(res.paymentDate);
            const dateStart = currentstartDate.setDate(currentstartDate.getDate())
            const newStartDate = new Date(dateStart).toISOString().split("T")[0];
            setValues({
                organizationName: res.companyName,
                ClientContactNumber: res.contactDetailsContent,
                contactNumber: res.contactNo,
                emailId: res.email,
                gstNumber: res.gstNo,
                Plan: res.planMasterId,
                planName: res.planName,
                Amount: res.amount,
                Discount: res.discount,
                invoiceAmount: res.invoiceAmount,
                gstInclude: res.gstIncluded,
                cgst: res.cgst,
                sgst: res.sgst,
                paymentDetail: res.paymentDetails,
                date: newStartDate,
                industry: res.industry,
                industryName: res.industryName,
                ClientProvide: res.clientWillProvide,
                url: res.webpageUrl,
                logo: res.logoUrl,
                partnerLabel: res.partnerLabel,
                webinarCredit: res.webinarCredit,
                webinarCode: res.webinarCodes,
                knowledgeCenterInqId: res.knowledgeCenterInqId,
                organizationName_error: "",
                ClientContactNumber_error: "",
                contactNumber_error: "",
                emailId_error: "",
                gstNumber_error: "",
                Plan_error: "",
                Amount_error: "",
                Discount_error: "",
                invoiceAmount_error: "",
                gstInclude_error: "",
                cgst_error: "",
                sgst_error: "",
                paymentDetail_error: "",
                date_error: "",
                industry_error: "",
                ClientProvide_error: "",
                url_error: "",
                logo_error: "",
                partnerLabel_error: "",
                webinarCredit_error: "",
                webinarCode_error: ""

            })
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
        const validationField = [event.target.name] + '_error'
        setValues({ ...values, [event.target.name]: event.target.value, [validationField]: '' });
    }
    const validationSchema = () => {
        if (values.organizationName.trim() === '' && values.ClientContactNumber.trim() === '' && values.contactNumber.trim() === '' && values.emailId.trim() === '' && values.gstNumber.trim() === '' && values.Plan.trim() === '' && values.Amount.trim() === '' && values.Discount.trim() === '' && values.invoiceAmount.trim() === '' && values.gstInclude.trim() === '' && values.cgst.trim() === '' && values.sgst.trim() === '' && values.paymentDetail.trim() === '' && values.date.trim() === '' && values.industry.trim() === '' && values.ClientProvide.trim() === '' && values.url.trim() === '' && values.logo.trim() === '' && values.partnerLabel.trim() === '' && values.webinarCredit.trim() === '' && values.webinarCode.trim() === '') {
            setValues((prev: any) => ({ ...prev, organizationName_error: "Enter OrganizationName", ClientContactNumber_error: "Enter Client Contactnumber", contactNumber_error: "Enter ContactNumber", emailId_error: "Enter EmailId", gstNumber_error: "Enter GSTNO", Plan_error: "Select Plan", Amount_error: "Enter Amount", Discount_error: "Enter Discount", invoiceAmount_error: "Enter InvoiceAmount", gstInclude_error: "Select GST Included or not", cgst_error: "Enter CGST", sgst_error: "Enter SGST", paymentDetail_error: "Enter PaymentDetail", date_error: "Enter Date", industry_error: "Select Industry", ClientProvide_error: "Select What Clientprovide", url_error: "Enter WebPage Url", logo_error: "Enter WebPage Url", partnerLabel_error: "Enter partnerlabel", webinarCredit_error: "Enter webinarCredit", webinarCode_error: "Enter webinarCredit" }))
            return false
        }
        if (values.organizationName.trim() === '') {
            setValues((prev: any) => ({ ...prev, organizationName_error: "Enter OrganizationName" }))
            return false
        }
        if (values.ClientContactNumber.trim() === '') {
            setValues((prev: any) => ({ ...prev, ClientContactNumber_error: "Enter ContactName" }))
            return false
        }
        if (values.ClientContactNumber.match(/[0-9]/)) {
            setValues((prev: any) => ({ ...prev, ClientContactNumber_error: "Only Alphabets Allow" }))
            return false
        }
        if (values.ClientContactNumber.trim().length < 2) {
            setValues((prev: any) => ({ ...prev, ClientContactNumber_error: "Minimum 2 character required" }))
            return false
        }
        // if (values.ClientContactNumber.trim().length > 10) {
        //     setValues((prev: any) => ({ ...prev, ClientContactNumber_error: "ConatctNo is Invalid" }))
        //     return false
        // }
        if (values.contactNumber.trim() === '') {
            setValues((prev: any) => ({ ...prev, contactNumber_error: "Enter ContactNumber" }))
            return false
        }
        if (values.contactNumber.match(/[a-zA-Z]/)) {
            setValues((prev: any) => ({ ...prev, contactNumber_error: "Contact No Must Be in digit only " }))
            return false
        }
        if (values.contactNumber.trim().length < 10) {
            setValues((prev: any) => ({ ...prev, contactNumber_error: "Enter 10 digit ContactNo" }))
            return false
        }
        if (values.contactNumber.trim().length > 10) {
            setValues((prev: any) => ({ ...prev, contactNumber_error: "ConatctNo is Invalid" }))
            return false
        }
        if (values.emailId.trim() === '') {
            setValues((prev: any) => ({ ...prev, emailId_error: "Enter EmailId" }))
            return false
        }
        if (values.gstNumber.trim() === '') {
            setValues((prev: any) => ({ ...prev, gstNumber_error: "Enter GSTNO" }))
            return false
        }
        if (values.Plan.trim() === '') {
            setValues((prev: any) => ({ ...prev, Plan_error: "Select Plan" }))
            return false
        }
        if (values.Amount === '') {
            setValues((prev: any) => ({ ...prev, Amount_error: "Enter Amount" }))
            return false
        }
        // if (values.Amount.match(/[a-zA-Z]/)) {
        //     setValues((prev:any)=>({...prev, Amount_error: "Amount is in only digits"}))
        //     return false
        //  }
        if (values.Discount === '') {
            setValues((prev: any) => ({ ...prev, Discount_error: "Enter Discount" }))
            return false
        }
        if (values.invoiceAmount === '') {
            setValues((prev: any) => ({ ...prev, invoiceAmount_error: "Enter InvoiceAmount" }))
            return false
        }
        if (values.gstInclude === '') {
            setValues((prev: any) => ({ ...prev, gstInclude_error: "Select GST Included or not" }))
            return false
        }
        if (values.cgst === '') {
            setValues((prev: any) => ({ ...prev, cgst_error: "Enter CGST" }))
            return false
        }
        if (values.sgst === '') {
            setValues((prev: any) => ({ ...prev, sgst_error: "Enter SGST" }))
            return false
        }
        if (values.paymentDetail.trim() === '') {
            setValues((prev: any) => ({ ...prev, paymentDetail_error: "Enter PaymentDetail" }))
            return false
        }
        if (values.date.trim() === '') {
            setValues((prev: any) => ({ ...prev, date_error: "Enter Date" }))
            return false
        }
        if (values.industry.trim() === '') {
            setValues((prev: any) => ({ ...prev, industry_error: "Select Industry" }))
            return false
        }
        if (values.ClientProvide === '') {
            setValues((prev: any) => ({ ...prev, ClientProvide_error: "Select What Clientprovide" }))
            return false
        }
        if (values.url.trim() === '') {
            setValues((prev: any) => ({ ...prev, url_error: "Enter WebPage Url" }))
            return false
        }
        if (values.logo.trim() === '') {
            setValues((prev: any) => ({ ...prev, logo_error: "Enter WebPage Url" }))
            return false
        }
        if (values.partnerLabel.trim() === '') {
            setValues((prev: any) => ({ ...prev, partnerLabel_error: "Enter partnerlabel" }))
            return false
        }
        if (values.webinarCredit === '') {
            setValues((prev: any) => ({ ...prev, webinarCredit_error: "Enter webinarCredit" }))
            return false
        }
        if (values.webinarCode.trim() === '') {
            setValues((prev: any) => ({ ...prev, webinarCode_error: "Enter webinarCredit" }))
            return false
        }
        return true
    }

    const submitHandler = async () => {
        if (validationSchema()) {
            const jsonData = {
                companyName: values.organizationName,
                contactNo: values.contactNumber,
                contactDetailsContent: values.ClientContactNumber,
                email: values.emailId,
                gstNo: values.gstNumber,
                planMasterId: values.Plan,
                discount: values.Discount,
                invoiceAmount: values.invoiceAmount,
                gstIncluded: values.gstInclude,
                cgst: values.cgst,
                sgst: values.sgst,
                paymentDetails: values.paymentDetail,
                paymentDate: new Date(values.date).toISOString(),
                industry: values.industry,
                clientWillProvide: values.ClientProvide,
                knowledgeCenterInqId: values.knowledgeCenterInqId,
                webpageUrl: values.url,
                logoUrl: values.logo,
                partnerLabel: values.partnerLabel,
                webinarCredit: values.webinarCredit,
                webinarCodes: values.webinarCode,
                amount: values.Amount
            }
            const data = JSON.stringify(jsonData)
            const response = await CallFor('api/admin/v1/knowledge-customers/' + id, 'PUT', data, 'Auth')
            if (response.status === 200) {
                navigate('/addcustomer')
            }
        }

    }

    return (
        <div>
            <PageTitle >Add Knowledge Partner Customer</PageTitle>
            <form onSubmit={handleSubmit(submitHandler)} >
                <div className='card mb-5 p-5'>
                    <div className='row'><h3>Organization Details</h3></div>
                    <div className='row'>
                        <div className="mb-3 col-xxl-3 col-lg-4">
                            <label className="form-label">Organization Name</label>
                            <input
                                type="text"
                                name='organizationName'
                                // className="form-control"
                                className={`form-control ${values?.organizationName_error === "" ? '' : 'is-invalid'}`}
                                placeholder="organization Name"
                                value={values.organizationName}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                        <p id='error_1' className='errormsg' >{values?.organizationName_error}</p>
                        <div className="mb-3 col-xxl-3 col-lg-4">
                            <label className="form-label"> Contact Name</label>
                            <input
                                type="text"
                                name='ClientContactNumber'
                                className={`form-control ${values?.ClientContactNumber_error === "" ? '' : 'is-invalid'}`}
                                placeholder="Enter Contact Name"
                                value={values.ClientContactNumber}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                        <p id='error_1' className='errormsg' >{values?.ClientContactNumber_error}</p>
                        <div className="mb-3 col-xxl-3 col-lg-4">
                            <label className="form-label">Mobile Number</label>
                            <input
                                type="text"
                                name='contactNumber'
                                className={`form-control ${values?.contactNumber_error === "" ? '' : 'is-invalid'}`}
                                placeholder="Contact Namber"
                                value={values.contactNumber}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                        <p id='error_1' className='errormsg' >{values?.contactNumber_error}</p>
                        <div className="mb-3 col-xxl-3 col-lg-4">
                            <label className="form-label">Email ID</label>
                            <input
                                type="text"
                                name='emailId'
                                className={`form-control ${values?.emailId_error === "" ? '' : 'is-invalid'}`}
                                placeholder="Email ID"
                                value={values.emailId}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                        <p id='error_1' className='errormsg' >{values?.emailId_error}</p>
                        <div className="mb-3 col-xxl-3 col-lg-4">
                            <label className="form-label">GST Number</label>
                            <input
                                type="text"
                                name='gstNumber'
                                className={`form-control ${values?.gstNumber_error === "" ? '' : 'is-invalid'}`}
                                placeholder="GST Number"
                                value={values.gstNumber}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                    </div>
                    <p id='error_1' className='errormsg' >{values?.gstNumber_error}</p>
                    <div className='row mt-5'><h3>Plan Details</h3></div>

                    <div className='row'>
                        <div className="mb-3 col-xxl-3 col-lg-4">
                            <label className='form-label'>Plan</label>
                            <select
                                className={`form-select text-muted ${values?.Plan_error === "" ? '' : 'is-invalid'}`}
                                name='Plan'
                                value={values.Plan}
                                onChange={(e) => handleChange(e)}
                            >
                                <option value=''>Select a plan...</option>
                                {plan.map((data) => {
                                    return (

                                        <option key={data.id} value={data.id}>
                                            {data.planName}
                                        </option>

                                    );
                                })}
                            </select>
                        </div>
                        <p id='error_1' className='errormsg' >{values?.Plan_error}</p>
                        <div className="mb-3 col-xxl-3 col-lg-4">
                            <label className="form-label">Amount</label>
                            <input
                                type="number"
                                name='Amount'
                                className={`form-control ${values?.Amount_error === "" ? '' : 'is-invalid'}`}
                                placeholder="Amount"
                                value={values.Amount}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                        <p id='error_1' className='errormsg' >{values?.Amount_error}</p>
                        <div className="mb-3 col-xxl-3 col-lg-4">
                            <label className="form-label">Discount</label>
                            <input
                                type="number"
                                name='Discount'
                                className={`form-control ${values?.Discount_error === "" ? '' : 'is-invalid'}`}
                                placeholder="Discount"
                                value={values.Discount}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                        <p id='error_1' className='errormsg' >{values?.Discount_error}</p>
                        <div className="mb-3 col-xxl-3 col-lg-4">
                            <label className="form-label">Invoice Amount</label>
                            <input
                                type="number"
                                name='invoiceAmount'
                                className={`form-control ${values?.invoiceAmount_error === "" ? '' : 'is-invalid'}`}
                                placeholder="Invoice Amount"
                                value={values.invoiceAmount}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                        <p id='error_1' className='errormsg' >{values?.invoiceAmount_error}</p>
                        <div className="mb-3 col-xxl-3 col-lg-4">
                            <label className="form-label">GST included?</label>
                            <div className="form-check form-check-custom form-check-solid form-check-sm">
                                <input
                                    className="form-check-input"
                                    id="windows"
                                    value="True"
                                    checked={values.gstInclude === true}
                                    // value={values.gstInclude}
                                    name="gstInclude"
                                    type="radio"
                                    onChange={(e) => handleChange(e)}
                                />
                                <label className="form-check-label" >Yes</label>
                                <input
                                    className="form-check-input ms-10"
                                    id="windows"
                                    value="False"
                                    checked={values.gstInclude === false}
                                    // value={values.gstInclude}
                                    name="gstInclude"
                                    type="radio"
                                    onChange={(e) => handleChange(e)}
                                />
                                <label className="form-check-label" >No</label>
                            </div>
                        </div>
                        <p id='error_1' className='errormsg' >{values?.gstInclude_error}</p>
                        <div className="mb-3 col-xxl-3 col-lg-4">
                            <label className="form-label">CGST</label>
                            <input
                                type="text"
                                name='cgst'
                                className={`form-control ${values?.cgst_error === "" ? '' : 'is-invalid'}`}
                                placeholder="CGST"
                                value={values.cgst}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                        <p id='error_1' className='errormsg' >{values?.cgst_error}</p>
                        <div className="mb-3 col-xxl-3 col-lg-4">
                            <label className="form-label">SGST</label>
                            <input
                                type="text"
                                name='sgst'
                                className={`form-control ${values?.sgst_error === "" ? '' : 'is-invalid'}`}
                                placeholder="SGST"
                                value={values.sgst}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                        <p id='error_1' className='errormsg' >{values?.sgst_error}</p>
                        {/* <div className="mb-3 col-xxl-3 col-lg-4">
                        <label className="form-label">Start Date</label>
                        <input
                            type="date"
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3 col-xxl-3 col-lg-4">
                        <label className="form-label">End Date</label>
                        <input
                            type="date"
                            className="form-control"
                        />
                    </div> */}
                    </div>
                    <div className='row mt-5'><h3>Payment Details</h3></div>

                    <div className='row'>
                        <div className="mb-3 col-xxl-3 col-lg-4">
                            <label className="form-label">Payment Details</label>
                            <input
                                type="text"
                                name='paymentDetail'
                                className={`form-control ${values?.paymentDetail_error === "" ? '' : 'is-invalid'}`}
                                placeholder="Payment Details"
                                value={values.paymentDetail}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                        <p id='error_1' className='errormsg' >{values?.paymentDetail_error}</p>
                        <div className="mb-3 col-xxl-3 col-lg-4">
                            <label className="form-label">Date</label>
                            <input
                                type="date"
                                name='date'
                                className={`form-control ${values?.date_error === "" ? '' : 'is-invalid'}`}
                                value={values.date}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                        <p id='error_1' className='errormsg' >{values?.date_error}</p>
                        <div className="mb-3 col-xxl-3 col-lg-4">
                            <label className='form-label'>Industry</label>
                            <select
                                className={`form-select text-muted ${values?.industry_error === "" ? '' : 'is-invalid'}`}
                                name='industry'
                                value={values.industry}
                                onChange={(e) => handleChange(e)}
                            >
                                <option value=''>Select Industry Or All Industries...</option>
                                {industry.map((data) => {
                                    return (

                                        <option key={data.subIndustryID} value={data.subIndustryID}>
                                            {data.subIndustry}
                                        </option>

                                    );
                                })}
                            </select>
                        </div>
                        <p id='error_1' className='errormsg' >{values?.industry_error}</p>
                        {/* <div className="mb-3 col-xxl-3 col-lg-4">
                        <label className='form-label'>Select Plan</label>
                        <select className='form-select text-muted'>
                            <option value=''>Select a plan...</option>
                        </select>
                    </div> */}
                        {/* <div className="mb-3 col-xxl-3 col-lg-4">
                        <label className='form-label'>Select Sales Rep.</label>
                        <select className='form-select'>
                            <option value=''>Select Sales Rep...</option>
                        </select>
                    </div>
                    <div className="mb-3 col-xxl-3 col-lg-4">
                        <label className='form-label'>Status</label>
                        <select className='form-select'>
                            <option value=''>Select a Status...</option>
                        </select>
                    </div> */}

                    </div>
                </div>

                <div className='card mb-5 p-5'>
                    <div className='row'>
                        <div className="mb-3 col-xxl-3 col-lg-4">
                            <label className="form-label">Client will provid</label>
                            <div className="form-check form-check-custom form-check-solid form-check-sm" >
                                <input
                                    className="form-check-input"
                                    id="flexRadioSm"
                                    value="HTML"
                                    // value={values.ClientProvide}
                                    checked={values.ClientProvide === 'HTML'}
                                    name="ClientProvide"
                                    type="radio"
                                    onChange={(e) => handleChange(e)}
                                />
                                <label className="form-check-label" >HTML</label>
                                <input
                                    className="form-check-input ms-10"
                                    id="flexRadioSm"
                                    value="LINK"
                                    checked={values.ClientProvide === 'LINK'}
                                    // value={values.ClientProvide}
                                    name="ClientProvide"
                                    type="radio"
                                    onChange={(e) => handleChange(e)}
                                />
                                <label className="form-check-label" >Only 1 Link</label>
                            </div>

                        </div>
                        <p id='error_1' className='errormsg' >{values?.ClientProvide_error}</p>
                    </div>
                </div>

                <div className='card mb-5 p-5'>
                    <div className='row'>
                        <div className="mb-3 col-lg-12">
                            <label className="form-label">Webpage URL <span className='text-gray-500'>(Client will provide it - Open in new window)</span></label>
                            <input
                                type="text"
                                name='url'
                                className={`form-control ${values?.url_error === "" ? '' : 'is-invalid'}`}
                                placeholder="Webpage URL"
                                value={values.url}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                        <p id='error_1' className='errormsg' >{values?.url_error}</p>
                        <div className="mb-3 col-lg-12">
                            <label className="form-label">Logo Upload <span className='text-gray-500'>(Client will provide it)</span></label>
                            <input
                                type="TEXT"
                                name='logo'
                                className={`form-control ${values?.logo_error === "" ? '' : 'is-invalid'}`}
                                value={values.logo}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                        <p id='error_1' className='errormsg' >{values?.logo_error}</p>
                        <div className="mb-3 col-lg-12">
                            <label className="form-label">Partner Label  <span className='text-gray-500'>(Client will provide it)</span></label>
                            <input
                                type="TEXT"
                                name='partnerLabel'
                                className={`form-control ${values?.partnerLabel_error === "" ? '' : 'is-invalid'}`}
                                value={values.partnerLabel}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                        <p id='error_1' className='errormsg' >{values?.partnerLabel_error}</p>
                        <div className="mb-3 col-lg-12">
                            <label className="form-label">Free Webinar Credits</label>
                            <div className='row'>
                                <div className=' col-lg-6'>
                                    <input
                                        type="text"
                                        name='webinarCredit'
                                        className={`form-control ${values?.webinarCredit_error === "" ? '' : 'is-invalid'}`}
                                        value={values.webinarCredit}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </div>
                                <p id='error_1' className='errormsg' >{values?.webinarCredit_error}</p>
                                {/* <div className="mb-3 col-lg-6">
                            <button type='submit' className='btn btn-primary'>Generate Webinar Code</button>
                        </div> */}
                            </div>
                        </div>
                        <div className="mb-3 col-lg-12">
                            <label className="form-label">Webinar Code(s)</label>
                            <div className='row'>
                                <div className='col-lg-2'>
                                    <input
                                        type="text"
                                        name='webinarCode'
                                        value={values.webinarCode}
                                        className={`form-control ${values?.webinarCode_error === "" ? '' : 'is-invalid'}`}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </div>
                                {/* <div className='col-lg-2'>
                                    <input
                                    type="text"
                                    className="form-control" />
                                    </div>
                                    <div className='col-lg-2'>
                                    <input
                                    type="text"
                                    className="form-control" />
                                </div> */}

                            </div>
                        </div>
                        <p id='error_1' className='errormsg' >{values?.webinarCode_error}</p>
                        <div className="mb-3 col-lg-12 text-center">
                            <button type='submit' className='btn btn-primary'>Add Knowledge Partner Customer</button>
                        </div>
                    </div>
                </div>


            </form>
        </div>
    )
}
export default EditKnowledgePartner