import JSONbig from "json-bigint";

function replaceNumberIds(obj: any): any {
    if (Array.isArray(obj)) {
        // If the input is an array, process each element
        return obj.map((item: any) => replaceNumberIds(item));
    } else if (obj && typeof obj === "object") {
        // If the input is an object, process each key-value pair
        for (let key in obj) {
            if (
                obj[key] &&
                (key === "id" ||
                    key === "businessId" ||
                    key === "originId" ||
                    key === "userId" ||
                    key === "reactionId" ||
                    key === "categoryId" ||
                    key === "subcategoryId" ||
                    key === "productId" ||
                    key === "postOf" ||
                    key === "requestId" ||
                    key === "subIndustryID" ||
                    key === "keywordId" ||
                    key === "blockedList" ||
                    key === "messageIds" ||
                    key === "inquireRoomId" ||
                    key === "awardId" ||
                    key === "productPrice" ||
                    key === "quantity" ||
                    key === "price" ||
                    key === "reelId") &&
                (typeof obj[key] === "number" || typeof obj[key] === "object")
            ) {
                // If the key is 'id' and the value is a number, replace it with a string

                obj[key] = obj[key]?.toString();
            } else {
                // Recursively process other key-value pairs
                obj[key] = replaceNumberIds(obj[key]);
            }
        }
    }
    return obj;
}

export const convertResponsetojsonBig = async (reponse: any) => {
    const convertResponse = await reponse?.text();
    const finalData = await JSONbig.parse(convertResponse);
    const finalDataWithReplacedId = replaceNumberIds(finalData);
    return finalDataWithReplacedId;
};
