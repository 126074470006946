/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react'
import { PageTitle } from '../../../../_metronic/layout/core'
import { KTSVG } from '../../../../_metronic/helpers'
import CallFor from '../../../routing/CallFor'
import { toast } from 'react-toastify'
import Pagination from '../../../components/Pagination'
import { Modal } from 'react-bootstrap'
import CreativeUpdates from './CreativeUpdates'
const BannerNotification = () => {

    const [creativeData, setCreativeData] = useState<any[]>([]);
    const [page, setPage] = useState<number>(0);
    const [totalPageNo, setTotalPageNo] = useState<number>(0);
    const [deleteId, setDeleteId] = useState("");
    const deleteModalRef = useRef<HTMLDivElement>(null);
    const [perticularCreative, setPerticularCreative] = useState<any>();
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        getCreativeList();
    }, [page])

    const convertBigIntToString = (bigIntNumber: any) => {
        return BigInt(bigIntNumber).toString()
    }

    const deletePlanModal = (deleteId: any) => {
        setDeleteId(deleteId)
        const divElement = document.createElement("div")
        divElement.classList.add("modal-backdrop")
        divElement.classList.add("fade")
        divElement.classList.add("show")
        const body = document.getElementById("kt_app_body")
        body?.appendChild(divElement)
        deleteModalRef.current!.style.display = "block"
        deleteModalRef.current!.style.paddingLeft = "0px"
        deleteModalRef.current!.classList.add("show")
        deleteModalRef.current!.setAttribute("aria-modal", "true")
        deleteModalRef.current!.setAttribute("role", "dialog")
        deleteModalRef.current!.removeAttribute("aria-hidden")
    }

    const closeKpPlanDeleteModal = () => {
        deleteModalRef.current!.style!.display = "none"
        deleteModalRef.current!.classList.remove("show")
        deleteModalRef.current!.removeAttribute("aria-modal")
        deleteModalRef.current!.removeAttribute("role")
        deleteModalRef.current!.setAttribute("aria-hidden", "true");
        const divElement = document.getElementsByClassName('modal-backdrop')
        divElement[0].remove()
        setDeleteId("")
    }

    const deleteEmployeeDetail = async () => {
        const deleteCampain = await CallFor
            (`api/admin/v1/banner/creative/delete/${convertBigIntToString(deleteId)}`,
                'DELETE',
                null,
                'Auth')
        const fetchData = await deleteCampain.json()
        if (deleteCampain.status === 200) {
            setCreativeData(creativeData.filter((item) => item.id !== deleteId))
            toast.success('Successfully Delete')
            getCreativeList();
        } else if (deleteCampain.status === 404) {
            toast.error(fetchData?.error?.message)
        } else if (deleteCampain.status === 400) {
            toast.error(fetchData?.error?.message)
        }
        closeKpPlanDeleteModal();
    }

    const getCreativeList = async () => {
        try {
            const response = await CallFor('api/admin/v1/banner/creative/listings', 'POST', JSON.stringify({ page: page }), 'Auth');
            if (response.status === 200) {
                const json1response = await response.json();
                if (json1response?.length === 0) {
                    setPage(0);
                    setTotalPageNo(0);
                }
                setTotalPageNo(Number(json1response?.data?.page))
                setCreativeData(json1response?.data?.content)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const openDetails = (details: any) => {
        setPerticularCreative(details); setOpenModal(true)
    }
    return (
        <div>
            <PageTitle>Notification Creative</PageTitle>
            <div className='card mb-5'>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>Banner Details</span>
                    </h3>
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                                    <th>Banner Type</th>
                                    <th>Start</th>
                                    <th>End</th>
                                    <th>Media Url</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    creativeData.length > 0 &&
                                    creativeData.map((detail, i) => {
                                        return (
                                            <tr key={i} >

                                                <td onClick={() => openDetails(detail)}><img src={detail.mediaUrl} alt='detail.mediaUrl' style={{ width: "50px" }}></img></td>
                                                <td onClick={() => openDetails(detail)}>
                                                    <div className='d-flex justify-content-start flex-column'>
                                                        {detail.bannerType}
                                                    </div>
                                                </td>
                                                <td onClick={() => openDetails(detail)}>{detail.startDate}</td>
                                                <td onClick={() => openDetails(detail)}> {detail.endDate}</td>

                                                <td>
                                                    <a
                                                        href='#'
                                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                                        onClick={(e) => { e.stopPropagation(); deletePlanModal(detail?.id); }}
                                                    >
                                                        <KTSVG
                                                            path='/media/icons/duotune/general/gen027.svg'
                                                            className='svg-icon-3'
                                                        />
                                                    </a>
                                                </td>
                                                <td>
                                                    <CreativeUpdates perticularCreative={detail} setCreativeData={setCreativeData} />
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* ------ModalDelete------ */}

            <div className="modal fade" ref={deleteModalRef} tabIndex={-1} id="kt_modal_1">
                <div className="modal-dialog modelLower">
                    <div className="modal-content">
                        <div className="modal-header p-4">
                            <h5 className="modal-title">Creative detele</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                // data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <a
                                    href='#'
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                    onClick={closeKpPlanDeleteModal}
                                >
                                    <KTSVG
                                        path="/media/icons/duotune/arrows/arr061.svg"
                                        className="svg-icon svg-icon-2x"
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="modal-body">
                            <p className='inactiveName'>Are you sure you want to delete Creative Banner ?</p>
                        </div>
                        <div className='modal-footer py-1 px-3 ms-auto w-100 deleteButton'>
                            <button className="btn btn-secondary btn-sm" onClick={deleteEmployeeDetail}>Yes</button>
                            <button className="btn btn-primary btn-sm" onClick={closeKpPlanDeleteModal}>No</button>
                        </div>
                    </div>
                </div>
            </div>

            {
                totalPageNo > 0 &&
                <Pagination
                    totalCount={totalPageNo * 10}  //total number of data
                    siblingCount={1} // number of extra elements around avtive page
                    currentPage={page} //current active page
                    onPageChange={setPage} //page change handler state
                    pageSize={10} // data per page
                />
            }

            {/* perticular Banner details */}
            {openModal && <Modal show={openModal} className='modal-md'>
                <Modal.Header>

                    <Modal.Title>Creative Banner</Modal.Title>
                    <div
                        className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                        // data-bs-dismiss="modal"
                        // aria-label="Close"
                        onClick={() => setOpenModal(false)}
                    >
                        <KTSVG
                            path="/media/icons/duotune/arrows/arr061.svg"
                            className="svg-icon svg-icon-2x"
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='card-body py-3'>
                        <div className='table-responsive'>
                            <span>WebUrl :</span><br />
                            <img src={perticularCreative?.mediaUrl ? perticularCreative?.mediaUrl : "null"} alt='perticularCreative?.mediaUrl' width='200' ></img>
                            <br /><br />
                            <span>UserType : <p>{perticularCreative?.bannerType}</p></span>
                            <span>startDate :<p>{perticularCreative?.startDate}</p></span>
                            <span>endDate :<p>{perticularCreative?.endDate}</p></span>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>}
        </div >
    )
}
export default BannerNotification