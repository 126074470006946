import { PageTitle } from '../../../../_metronic/layout/core'
import { KTSVG } from '../../../../_metronic/helpers'
import { useState } from 'react';
import CallFor from '../../../routing/CallFor';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'

const CreatePromoCode = () => {
    const d = new Date();
    const h = d.getHours();
    const m = d.getMinutes();
    const datePicker = new Date().toISOString().split("T")[0];
    const currentDate = new Date().toISOString().split("T")[0];
    const time = new Date().toLocaleTimeString().split(" ")[0];

    const options: any = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
    };

    const formatter = new Intl.DateTimeFormat('en-US', options);
    const timeString = formatter.format(new Date());

    const sceduleTime = time?.slice(0, 5);
    const [isValid, setIsValid] = useState(true);
    const [open, setOpen] = useState(false);
    const [type, setType] = useState(true);
    const navigate = useNavigate();
    const [formData, setFormData] = useState<any>({
        date: "",
        date_error: '',
        time: "",
        time_error: '',
        end_date: "",
        end_date_error: '',
        end_time: "",
        end_time_error: '',
        discountpercentage: "",
        discountpercentage_error: "",
        discountamount: "",
        discountamount_error: "",
        promoName: "",
        promoName_error: "",
        description: "",
        description_error: "",
        header: "",
        header_error: "",

    });

    const handleChange = (event: { target: { name: any; value: any; }; }) => {
        const validationField = [event.target.name] + '_error'
        setFormData({ ...formData, [event.target.name]: event.target.value, [validationField]: '' })
    }

    const userHandler = (event: any) => {
        if (event !== null && event.value !== undefined) {
            setFormData({ ...formData, userType: event.value, userType_error: '' })
        }
        else {
            setFormData({ ...formData, userType: '', userType_error: '' })
        }
    }

    const publishHandler = async () => {
        if (validationSchema()) {
            let jsonData: any = {
                name: String(formData.promoName),
                discountPercentage: String(formData.discountpercentage),
                discountAmount: String(formData.discountamount),
                startDate: `${formData.date.split("-").reverse().join("-")} ${formData.time}`,
                endDate: `${formData.end_date.split("-").reverse().join("-")} ${formData.end_time}`,
                header: String(formData?.header),
                description: String(formData.description),
                isPrivate: Boolean(type)
            }
            try {
                const response: any = await CallFor('api/admin/v1/promocodes', 'POST', JSON.stringify(jsonData), 'Auth');
                const res = await response.json();
                if (response.status === 201) {
                    setOpen(false)
                    navigate('/promoCodelisting');
                }
                else if (response.status === 400) {
                    toast.error(res?.error?.subcode === "1242" && 'This PromoCode Name is already use')
                }
                else if (response.status === 401) {
                }
            } catch (error) {
                console.log(error)
            }
        }
    }
    const validationSchema = () => {
        let isValid = true;
        if (formData?.promoName?.trim() === '') {
            setFormData((prev: any) => ({ ...prev, promoName_error: "Please enter PromoCode Name" }))
            isValid = false
        }
        if (formData?.promoName?.trim() !== '') {
            const regex = /^[a-zA-Z0-9]*$/;
            if (formData?.promoName?.trim().includes(' ')) {
                setFormData((prev: any) => ({ ...prev, promoName_error: "Please enter PromoCode without space" }))
                isValid = false
            }
            if (!formData?.promoName?.match(regex)
            ) {
                setFormData((prev: any) => ({ ...prev, promoName_error: "Special character not allow and space not allow" }))
                isValid = false
            }
        }
        if (formData?.discountpercentage?.trim() === '') {
            setFormData((prev: any) => ({ ...prev, discountpercentage_error: "Please enter discount percentage" }))
            isValid = false
        }

        if (formData?.discountpercentage?.trim() !== '') {
            if (formData?.discountpercentage?.trim() < 1 || formData?.discountpercentage?.trim() > 100) {
                setFormData((prev: any) => ({ ...prev, discountpercentage_error: "Please enter percentage between 1 to 100" }))
                isValid = false
            }
        }

        // if (formData?.discountamount?.trim() === '') {
        //     setFormData((prev: any) => ({ ...prev, discountamount_error: "Please enter discount amount" }))
        //     isValid = false
        // }

        if (formData?.discountamount?.trim() !== '') {
            if (formData?.discountamount?.trim() < 1) {
                setFormData((prev: any) => ({ ...prev, discountamount_error: "Please enter discount amount positive" }))
                isValid = false
            }
            if (formData.discountamount.length > 16) {
                setFormData((prev: any) => ({ ...prev, discountamount_error: "Please enter valid discount amount " }))
                isValid = false
            }
        }

        if (formData?.header === '') {
            setFormData((prev: any) => ({ ...prev, header_error: "Please enter header" }))
            isValid = false
        }
        if (formData?.header?.trim() !== '') {
            const regex = /^[a-zA-Z0-9\-&@%\s]*$/;
            if (!formData?.header?.match(regex)
            ) {
                setFormData((prev: any) => ({ ...prev, header_error: "Only -, &, @, % Special character allow" }))
                isValid = false
            }
        }

        // if (formData?.description === '') {
        //     setFormData((prev: any) => ({ ...prev, description_error: "Please enter description" }))
        //     isValid = false
        // }

        if (formData?.date === '') {
            setFormData((prev: any) => ({ ...prev, date_error: "select schedule date" }))
            isValid = false
        }

        if (formData?.time === '') {
            setFormData((prev: any) => ({ ...prev, time_error: "Select schedule time" }))
            isValid = false
        }

        if (formData?.end_date === '') {
            setFormData((prev: any) => ({ ...prev, end_date_error: "select end schedule date" }))
            isValid = false
        }

        if (formData?.end_time === '') {
            setFormData((prev: any) => ({ ...prev, end_time_error: "Select end schedule time" }))
            isValid = false
        }

        if (formData.end_date === formData.date && formData.end_time <= formData.time) {
            setFormData((prev: any) => ({ ...prev, end_time_error: "Select valid end time" }))
            isValid = false
        }

        if (formData.date === currentDate && formData.time <= timeString) {
            setFormData((prev: any) => ({ ...prev, time_error: "Select valid start time" }))
            isValid = false
        }

        if (formData.time?.slice(0, 2) < h) {
            if (formData.time?.slice(3, 6) < m) {
                if (formData.date === currentDate) {
                    setFormData((prev: any) => ({ ...prev, time_error: "The schedule time is invalid" }))
                    isValid = false
                }
            }
        }
        return isValid
    }

    const reviewModal = () => {
        if (validationSchema() && isValid) {
            setOpen(true)
        }
    }

    const ApiCall = () => {
        publishHandler();
    }

    const inputDate = new Date(formData?.date);
    const day = inputDate.getDate().toString().padStart(2, '0');
    const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
    const year = inputDate.getFullYear().toString().slice(-2);
    const formattedDate = `${day}-${month}-${year}`;

    const inputEndDate = new Date(formData?.end_date);
    const dayEnd = inputEndDate.getDate().toString().padStart(2, '0');
    const monthEnd = (inputEndDate.getMonth() + 1).toString().padStart(2, '0');
    const yearEnd = inputEndDate.getFullYear().toString().slice(-2);
    const formattedDateEnd = `${dayEnd}-${monthEnd}-${yearEnd}`;

    return (
        <div>
            <PageTitle >Create PromoCode</PageTitle>
            <div className='card mb-5 p-5'>
                <div className='row'>

                    <div className='col-lg-6 offset-lg-3' >

                        <div className="mb-6 col-lg-12">
                            <label className="form-label">PromoCode Name</label>
                            <input
                                name='promoName'
                                className={`form-control ${formData.promoName_error ? 'is-invalid' : ''}`}
                                placeholder="PromoCode Name"
                                value={formData.promoName}
                                onChange={handleChange}
                            />
                            <p id='error_1' className='errormsg' >{formData?.promoName_error}</p>
                        </div>

                        <div className="mb-6 col-lg-12">
                            <label className="form-label">Discount Percentage</label>
                            <input
                                name='discountpercentage'
                                type="number"
                                className={`form-control ${formData.discountpercentage_error ? 'is-invalid' : ''}`}
                                placeholder="Discount Percentage"
                                value={formData.discountpercentage}
                                onChange={handleChange}
                            />
                            <p id='error_1' className='errormsg' >{formData?.discountpercentage_error}</p>
                        </div>
                        <div className="mb-6 col-lg-12">
                            <label className="form-label">Discount Amount</label>
                            <input
                                name='discountamount'
                                type="number"
                                className={`form-control ${formData.discountamount_error ? 'is-invalid' : ''}`}
                                placeholder="Discount Amount"
                                value={formData.discountamount}
                                onChange={handleChange}
                                maxLength={5}
                            />
                            <p id='error_1' className='errormsg' >{formData?.discountamount_error}</p>
                        </div>
                        <div className="mb-6 col-lg-12">
                            <label className="form-label">Header</label>
                            <input
                                name='header'
                                type="text"
                                className={`form-control ${formData?.header_error ? 'is-invalid' : ''}`}
                                placeholder="Header"
                                value={formData?.header}
                                onChange={handleChange}
                            />
                            <p id='error_1' className='errormsg' >{formData?.header_error}</p>
                        </div>
                        <div className="mb-6 col-lg-12">
                            <label className="form-label">Description</label>
                            <input
                                name='description'
                                type="text"
                                className={`form-control ${formData?.description_error ? 'is-invalid' : ''}`}
                                placeholder="description"
                                value={formData?.description}
                                onChange={handleChange}
                                maxLength={500}
                            />
                            <p id='error_1' className='errormsg' >{formData?.description_error}</p>
                        </div>

                        <div className="mb-3 col-lg-12">
                            <label className="form-label">Scheduling*</label>
                            <div className='row'>
                                <div className="mb-6 col-lg-6">
                                    <input
                                        name='date'
                                        type="date"
                                        id="datePickerId"
                                        min={datePicker}
                                        value={formData.date}
                                        className={`form-control ${formData?.date_error === "" ? '' : 'is-invalid'}`}
                                        onChange={handleChange}
                                    />
                                    <p id='error_1' className='errormsg' >{formData?.date_error}</p>
                                </div>

                                <div className="mb-6 col-lg-6">
                                    <input
                                        name='time'
                                        type="time"
                                        min={sceduleTime}
                                        value={formData.time}
                                        className={`form-control ${formData?.time_error === "" ? '' : 'is-invalid'}`}
                                        onChange={handleChange}
                                    />
                                    <p id='error_1' className='errormsg' >{formData?.time_error}</p>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3 col-lg-12">
                            <label className="form-label">End Time*</label>
                            <div className='row'>
                                <div className="mb-6 col-lg-6">
                                    <input
                                        name='end_date'
                                        type="date"
                                        id="datePickerId"
                                        min={formData.date}
                                        value={formData.end_date}
                                        className={`form-control ${formData?.end_date_error === "" ? '' : 'is-invalid'}`}
                                        onChange={handleChange}
                                    />
                                    <p id='error_1' className='errormsg' >{formData?.end_date_error}</p>
                                </div>
                                {formData.end_date === currentDate && formData.date === currentDate ? (
                                    <div className="mb-6 col-lg-6">
                                        <input
                                            name='end_time'
                                            type="time"
                                            min={formData.time}
                                            value={formData.end_time}
                                            className={`form-control ${formData?.end_time_error === "" ? '' : 'is-invalid'}`}
                                            onChange={handleChange}
                                        />
                                        <p id='error_1' className='errormsg' >{formData?.end_time_error}</p>
                                    </div>
                                )
                                    : (
                                        <div className="mb-6 col-lg-6">
                                            <input
                                                name='end_time'
                                                type="time"
                                                min={sceduleTime}
                                                value={formData.end_time}
                                                className={`form-control ${formData?.end_time_error === "" ? '' : 'is-invalid'}`}
                                                onChange={handleChange}
                                            />
                                            <p id='error_1' className='errormsg' >{formData?.end_time_error}</p>
                                        </div>
                                    )}
                            </div>
                        </div>
                        <div className='mb-10'>
                            <label className='form-label fw-bold'>Promo Type:</label>
                            <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    name='promoType'
                                    defaultChecked={type}
                                    onChange={() => { type ? setType(false) : setType(true) }}
                                />
                                <label className='form-check-label'>{type ? 'Private' : 'Public'}</label>
                            </div>
                        </div>

                        <div className="col-lg-12  text-center">
                            <button type='submit' className='btn btn-primary' onClick={reviewModal} >Review</button>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={open} onHide={() => setOpen(true)} >
                <Modal.Header>

                    <Modal.Title>Review PromoCode</Modal.Title>
                    <div
                        className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => setOpen(false)}
                    >
                        <KTSVG
                            path="/media/icons/duotune/arrows/arr061.svg"
                            className="svg-icon svg-icon-2x"
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>

                    <div className="mb-3 col-lg-12">
                        <div className="border-bottom border-gray-300">
                            <label className="form-label">Create PromoCode</label>
                        </div>
                    </div>

                    <div className="mb-3 col-lg-12 mt-4">
                        <label className="form-label">Name</label>
                        <div>
                            {formData.promoName}
                        </div>
                    </div>

                    <div className="mb-3 col-lg-12 mt-4">
                        <label className="form-label">Discount Percentage</label>
                        <div className="">{formData?.discountpercentage}%</div>
                    </div>

                    {formData?.discountamount && <div className="mb-3 col-lg-12 mt-4">
                        <label className="form-label">Discount Amount</label>
                        <div className="">{formData?.discountamount}</div>
                    </div>}

                    <div className="mb-3 col-lg-12 mt-4">
                        <label className="form-label">Header</label>
                        <div className="">{formData?.header}</div>
                    </div>

                    {formData?.description && <div className="mb-3 col-lg-12 mt-4">
                        <label className="form-label">Description</label>
                        <div className="">{formData?.description}</div>
                    </div>}

                    <div className="mb-3 col-lg-12 mt-4">
                        <label className="form-label">Start Time Scheduling</label>
                        <div className="">{formattedDate} {formData?.time}</div>
                    </div>

                    <div className="mb-3 col-lg-12 mt-4">
                        <label className="form-label">End Time Scheduling</label>
                        <div className="">{formattedDateEnd} {formData?.end_time}</div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={() => setOpen(false)} >
                        Cancel
                    </button>
                    <button type="button" className="btn btn-primary" onClick={ApiCall} >
                        Publish
                    </button>
                </Modal.Footer>
            </Modal>

        </div >
    )
}
export default CreatePromoCode
