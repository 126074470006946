
import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import EditKnowledgePartnerPlan from './EditKnowledgePartnerPlan';
import Pagination from '../../../components/Pagination'
import CallFor from '../../../routing/CallFor';
import Select from 'react-select';
import { MenuItem } from '../../../../_metronic/layout/components/header/header-menus/MenuItem';
import { useAuth } from '../../auth';


type UserSubmitForm = {
  name: string;
  duration: number;
  durationtype: string;
  industrye: string;
  Amount: number;
  startdate: Date;
  enddate: Date;
  webinarcredits: string;
  remark: string;
  status: string;
}

const KnowledgePartner = () => {

  const durationtype = [
    { value: 'Days', label: 'Days' },
    { value: 'Month', label: 'Month' },
    { value: 'Year', label: 'Year' }
  ]

  const statuses = [
    { value: 'ACTIVE', label: 'ACTIVE' },
    { value: 'INACTIVE', label: 'INACTIVE' }
  ]

  const [values, setValues] = useState({
    name: "",
    duration: "",
    industrye: "",
    durationtype: "",
    Amount: "",
    startdate: "",
    enddate: "",
    webinarcredits: "",
    remark: "",
    status: "",
    name_error: '',
    duration_error: '',
    durationtype_error: '',
    industrye_error: '',
    Amount_error: '',
    startdate_error: '',
    enddate_error: '',
    webinarcredits_error: '',
    remark_error: '',
    // status_error:''
  });
  const [kpData, setKpData] = useState<any[]>([]);
  const [industry, setIndustry] = useState<any[]>([]);
  const modalRef = useRef<HTMLDivElement>(null);
  const deleteModalRef = useRef<HTMLDivElement>(null);
  const editkpref = useRef<HTMLDivElement>(null);
  const selectRef = useRef<any>(null);
  // const [editData, setEditData] = useState<any>({
  //   id: "",
  //   planName: "",
  //   durationUnit: "",
  //   durationType: "",
  //   industry: "",
  //   amount: "",
  //   startDate: "",
  //   endDate: "",
  //   webinarCredit: "",
  //   remarks: "",
  //   status: "",
  // });
  const [page, setPage] = useState<number>(0);
  const [totalPageNo, setTotalPageNo] = useState<number>(1);
  const [loading, setLoading] = useState<Boolean>(false);
  const [error, setError] = useState<any>();
  const [isClear, setIsClear] = useState(false);
  const [deleteId, setDeleteId] = useState()
  const [search, setSearch] = useState<any>({
    planName: "",
    planName_error: '',
    status: "",
    status_error: ''
  });
  useEffect(() => {
    KnowledgePartnerAllData();
  }, [isClear, page]);

  // useEffect(() => {
  //   getIndstury();
  // }, [])

  const addKnowledgePlanModal = () => {
    // clearErrors();
    const divElement = document.createElement("div")
    divElement.classList.add("modal-backdrop")
    divElement.classList.add("fade")
    divElement.classList.add("show")
    const body = document.getElementById("kt_app_body")
    body?.appendChild(divElement)
    modalRef.current!.style.display = "block"
    modalRef.current!.style.paddingLeft = "0px"
    modalRef.current!.classList.add("show")
    modalRef.current!.setAttribute("aria-modal", "true")
    modalRef.current!.setAttribute("role", "dialog")
    modalRef.current!.removeAttribute("aria-hidden")
  }
  const deletePlanModal = (id: any) => {
    setDeleteId(id);
    const divElement = document.createElement("div")
    divElement.classList.add("modal-backdrop")
    divElement.classList.add("fade")
    divElement.classList.add("show")
    const body = document.getElementById("kt_app_body")
    body?.appendChild(divElement)
    deleteModalRef.current!.style.display = "block"
    deleteModalRef.current!.style.paddingLeft = "0px"
    deleteModalRef.current!.classList.add("show")
    deleteModalRef.current!.setAttribute("aria-modal", "true")
    deleteModalRef.current!.setAttribute("role", "dialog")
    deleteModalRef.current!.removeAttribute("aria-hidden")
  }

  const closeKpPlanDeleteModal = () => {
    deleteModalRef.current!.style!.display = "none"
    deleteModalRef.current!.classList.remove("show")
    deleteModalRef.current!.removeAttribute("aria-modal")
    deleteModalRef.current!.removeAttribute("role")
    deleteModalRef.current!.setAttribute("aria-hidden", "true");
    const divElement = document.getElementsByClassName('modal-backdrop')
    divElement[0].remove()
  }

  const closeKpPlanModal = () => {
    setValues({
      name: "",
      duration: "",
      industrye: "",
      durationtype: "",
      Amount: "",
      startdate: "",
      enddate: "",
      webinarcredits: "",
      remark: "",
      status: "",
      name_error: '',
      duration_error: '',
      durationtype_error: '',
      industrye_error: '',
      Amount_error: '',
      startdate_error: '',
      enddate_error: '',
      webinarcredits_error: '',
      remark_error: '',
      // status_error:''
    });
    modalRef.current!.style!.display = "none"
    modalRef.current!.classList.remove("show")
    modalRef.current!.removeAttribute("aria-modal")
    modalRef.current!.removeAttribute("role")
    modalRef.current!.setAttribute("aria-hidden", "true");
    const divElement = document.getElementsByClassName('modal-backdrop')
    divElement[0].remove()
  }
  // const editKpPlanModal = () => {
  //   const divElement = document.createElement("div")
  //   divElement.classList.add("modal-backdrop")
  //   divElement.classList.add("fade")
  //   divElement.classList.add("show")
  //   const body = document.getElementById("kt_app_body")
  //   body?.appendChild(divElement)
  //   editkpref.current!.style.display = "block"
  //   editkpref.current!.style.paddingLeft = "0px"
  //   editkpref.current!.classList.add("show")
  //   editkpref.current!.setAttribute("aria-modal", "true")
  //   editkpref.current!.setAttribute("role", "dialog")
  //   editkpref.current!.removeAttribute("aria-hidden")
  // }

  // const validationSchema = Yup.object().shape({
  //   name: Yup.string().required('Please Enter Name').matches(/^[A-Za-z-&-\s]+$/, 'Only alphabets allowed ').test(
  //     'len',
  //     'Minimum 2 character require',
  //     (val) => val !== undefined && val?.toString()?.length > 2).test(
  //       'len',
  //       'Maximum 50 character allowed',
  //       (val) => val !== undefined && val?.toString()?.length < 50),
  //   duration: Yup.string().required(' Enter Duration').matches(/^[0-9]+$/, "Must be a number"),
  //   durationtype: Yup.string().required('select Duration Type'),
  //   industrye: Yup.string().required(' select Industry'),
  //   Amount: Yup.string().required('Enter Amount'),
  //   startdate: Yup.string().required('Enter Date'),
  //   enddate: Yup.string().required('Enter Date'),
  //   webinarcredits: Yup.string().required('Enter Webinarcredits').matches(/^[0-9]+$/, "Must be a number"),
  //   remark: Yup.string().required('Enter Remark'),
  //   status: Yup.string().required('Select Status'),
  // })

  const {
    handleSubmit,
    control
  } = useForm<UserSubmitForm>({})

  const handleChange = (event: any) => {
    const validationField = [event.target.name] + '_error'
    setValues({ ...values, [event.target.name]: event.target.value, [validationField]: '' });
  }

  // const industrySelectHandler = (event: { value: undefined; } | null) => {
  //   if (event !== null && event.value !== undefined) {
  //     setValues({ ...values, industrye: event.value,industrye_error:'' });

  //   } 
  //   else{
  //     setValues({ ...values, industrye: '' });
  //   }
  // };
  // const industrySearchHandler = (event: any) => {
  //   if (event !== null && event.value !== undefined) {
  //     setSearch({ ...search, industtysearch: event.value,});

  //   } 
  //   else{
  //     setSearch({ ...search, industtysearch: '' });
  //   }
  // };
  const industryStatusSearchHandler = (event: any) => {
    if (event !== null && event.value !== undefined) {
      setSearch({ ...search, status: event.value, status_error: '' });
    }
    else {
      setSearch({ ...search, status: '' });
    }
  }

  const validationSchema = () => {
    let isValid = true;

    if (values.name === '') {
      setValues((prev: any) => ({ ...prev, name_error: "Enter plan name" }))
      isValid = false
    } else
      // if (values.name.match(/[0-9]/)) {
      //   setValues((prev: any) => ({ ...prev, name_error: "PlanName Must Be In Character" }))
      //   isValid = false
      // }
      if (values.name.length < 2) {
        setValues((prev: any) => ({ ...prev, name_error: "Minimum 2 character allow" }))
        isValid = false
      } else
        if (values.name.length > 50) {
          setValues((prev: any) => ({ ...prev, name_error: "Maximum 50 character allow" }))
          isValid = false
        }
    if (values.duration === '') {
      setValues((prev: any) => ({ ...prev, duration_error: "Enter plan duration" }))
      isValid = false
    } else
      if (values.duration.match(/[a-zA-Z]/)) {
        setValues((prev: any) => ({ ...prev, duration_error: "Duration must be number" }))
        isValid = false
      }
    if (values.durationtype === '') {
      setValues((prev: any) => ({ ...prev, durationtype_error: "Select duration type" }))
      isValid = false
    }
    // if (values.industrye === '') {
    //   setValues((prev: any) => ({ ...prev, industrye_error: "Select Industry" }))
    //   isValid = false
    // }
    if (values.Amount === '') {
      setValues((prev: any) => ({ ...prev, Amount_error: "Enter amount" }))
      isValid = false
    } else
      if (values.Amount.match(/[a-zA-Z]/) || values.Amount.match(/[-/]/)) {
        setValues((prev: any) => ({ ...prev, Amount_error: "Amount must be a number" }))
        isValid = false
      }
    if (values.startdate === '') {
      setValues((prev: any) => ({ ...prev, startdate_error: "Enter plan start date" }))
      isValid = false
    }
    if (values.enddate === '') {
      setValues((prev: any) => ({ ...prev, enddate_error: "Enter plan end date" }))
      isValid = false
    }
    if (values.webinarcredits === '') {
      setValues((prev: any) => ({ ...prev, webinarcredits_error: "Enter webinar credits" }))
      isValid = false
    } else
      if (values.webinarcredits.match(/[a-zA-Z]/) || values.webinarcredits.match(/[.*+?/^${}@#=%()|[\]\\]/)) {
        setValues((prev: any) => ({ ...prev, webinarcredits_error: "Webinar credits must be number" }))
        isValid = false
      }
    if (values.remark === '') {
      setValues((prev: any) => ({ ...prev, remark_error: "Enter remarks" }))
      isValid = false
    }
    return isValid
  }

  const submitHandler = async (data: UserSubmitForm) => {
    if (validationSchema()) {
      const jsondata = {
        planName: values.name,
        durationUnit: Number(values.duration),
        durationType: values.durationtype,
        // industry: values.industrye,
        // industry:values.industrye,
        amount: Number(values.Amount),
        startDate: new Date(values.startdate).toISOString(),
        endDate: new Date(values.enddate).toISOString(),
        webinarCredit: values.webinarcredits,
        remarks: values.remark,
        status: 'ACTIVE'
      }
      const json1 = JSON.stringify(jsondata)
      const response = await CallFor('api/admin/v1/plans', 'POST', json1, 'Auth')
      if (response.status === 200) {
        KnowledgePartnerAllData();
        closeKpPlanModal();
        setValues({
          name: "",
          duration: "",
          industrye: "",
          durationtype: "",
          Amount: "",
          startdate: "",
          enddate: "",
          webinarcredits: "",
          remark: "",
          status: "",
          name_error: '',
          duration_error: '',
          durationtype_error: '',
          industrye_error: '',
          Amount_error: '',
          startdate_error: '',
          enddate_error: '',
          webinarcredits_error: '',
          remark_error: '',
          // status_error:''
        })
      }

    }
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const validationField = [event.target.name] + '_error'
    setSearch({ ...search, [event.target.name]: event.target.value, [validationField]: '' })
  }
  const clearSearch = () => {
    setSearch({
      planName: "",
      planName_error: '',
      status: "",
      status_error: ''
    })
    setIsClear(true);
  }

  const searchValidate = () => {
    if (search.planName === '' && search.status === '') {
      setSearch((prev: any) => ({ ...prev, planName_error: "Enter plan name", status_error: "Select status" }))
      return false
    }
    return true
  }

  const submitSearchHandler = () => {
    if (searchValidate()) {
      setPage(0);
      KnowledgePartnerAllData();
    }
  }

  const KnowledgePartnerAllData = async () => {
    setSearch((prev: any) => ({ ...prev, planName_error: "", status_error: "" }))
    try {
      setLoading(true)
      const response = await CallFor(`api/admin/v1/plans?planName=${search.planName}&status=${search.status}&page=${page}&size=10`, 'GET', null, 'Auth')
      if (response.status === 200) {
        const res = await response.json();
        if (res.length === 0) {

          setPage(0);
          setTotalPageNo(0);
        }
        setTotalPageNo(Number(res.data.pages))
        setKpData(res.data.content);

      }
    } catch (error) {
      setError(error);
    }
    setLoading(false);
    setIsClear(false)
  }

  const deleteplan = async () => {
    setKpData(kpData.filter((item) => item.id !== deleteId));
    const response = await CallFor("api/admin/v1/plans/" + deleteId, 'DELETE', null, 'Auth')
    if (response.status === 204) {
      KnowledgePartnerAllData();
      closeKpPlanDeleteModal();
    }
  }

  const durationTypes = (event: any) => {
    if (event !== null && event.value !== undefined) {
      setValues({ ...values, durationtype: event.value, durationtype_error: '' });
    }
    else {
      setValues({ ...values, durationtype: '' });
    }
  }

  return (
    <div>

      <PageTitle >Knowledge Partner Plan Details</PageTitle>
      <div className='card mb-5 p-5'>
        <div className='row'>
          <div className="mb-3 col-lg-3">
            <label className="form-label">Plan Name</label>
            <input
              type="text"
              name='planName'
              className={`form-control ${search?.planName_error === "" ? '' : 'is-invalid'}`}
              placeholder="Enter Plan Name"
              value={search.planName}
              onChange={(e) => handleSearch(e)}
            />
            <p id='error_1' className='errormsg' >{search?.planName_error}</p>
          </div>

          {/* <div className="col-lg-3">
            <label className='form-label'>Industry</label>
            <Controller
                          name="industrye"
                          control={control}
                          render={({ field }) => (
                            <Select
                            {...field}
                            isClearable
                            isSearchable={true}
                            placeholder='Select Industry'
                            value={industry.filter(
                              (obj) => obj.value === search.industtysearch)}
                            onChange={industrySearchHandler}
                            id="Industry"
                            options={industry}
                          />
                      )}/>
          </div> */}
          <div className="col-lg-3">
            <label className='form-label'>Status</label>
            <Controller
              name='status'
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  isClearable
                  isSearchable={true}
                  placeholder='Select Staus'
                  className={search.status_error ? 'error-border' : ''}
                  value={statuses.filter(
                    (obj) => obj.value === search.status)}
                  onChange={industryStatusSearchHandler}
                  id="statuses"
                  options={statuses}
                />
              )} />
            <p id='error_1' className='errormsg' >{search?.status_error}</p>
          </div>

          <div className="mb-3 col-lg-3 mt-8">
            <button type='submit' className='btn btn-primary' onClick={submitSearchHandler}>Search</button>
            <button type='submit' className='btn btn-danger clear-btn' onClick={clearSearch} >Clear</button>
          </div>
        </div>
      </div>

      <div className='card mb-5'>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Plan Details</span>
            {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 members</span> */}
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <button type="button" className="btn btn-primary btn-sm"
              // data-bs-toggle="modal" 
              data-bs-target="#kt_modal_1"
              onClick={addKnowledgePlanModal}>
              Add Knowledge Partner Plan
            </button>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
              <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">

                  <th className='min-w-150px'>Name</th>
                  <th className='min-w-150px'>Duration</th>
                  {/* <th className='min-w-150px'>Industry</th> */}
                  <th className='min-w-150px'>Amount</th>
                  <th className='min-w-150px'>Start Date</th>
                  <th className='min-w-150px'>End Date</th>
                  <th className='min-w-150px'>Webinar Credits</th>
                  <th className='min-w-150px'>Remarks</th>
                  <th className='min-w-140px'>Status</th>
                  <th className='min-w-100px text-end'>Actions</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              {
                kpData.length > 0 ?
                  kpData.map((data, index) => {
                    return (
                      <tbody key={index}>
                        <tr>
                          <td>
                            <div className='d-flex align-items-center'>
                              {data.planName}
                            </div>
                          </td>
                          <td>
                            <div className='d-flex align-items-center'>
                              {data.durationUnit} {data.durationType}
                            </div>
                          </td>
                          {/* <td>
                            <div className='d-flex align-items-center'>
                              {data.industryName}
                            </div>
                          </td> */}
                          <td>
                            <div className='d-flex align-items-center'>
                              {data.amount}
                            </div>
                          </td>
                          <td>
                            <div className='d-flex align-items-center'>
                              {data.startDate.split('T')[0]?.split("-")?.reverse().join("-")}
                              {/* 01-mar-2022 */}
                              {/* .split('T')[0]).split("-").reverse().join("-") */}
                            </div>
                          </td>
                          <td>
                            <div className='d-flex align-items-center'>
                              {data.endDate.split('T')[0]?.split("-")?.reverse().join("-")}
                              {/* 02-mar-2023 */}
                            </div>
                          </td>
                          <td>
                            <div className='d-flex align-items-center'>
                              {data.webinarCredit}
                            </div>
                          </td>
                          <td>
                            <div className='d-flex align-items-center'>
                              {data.remarks}
                            </div>
                          </td>
                          <td>
                            <div className='d-flex align-items-center'>
                              {data.status}
                            </div>
                          </td>
                          <td>
                            {
                              data.status === 'ACTIVE' ?
                                <div className='d-flex justify-content-end flex-shrink-0'>
                                  <a
                                    href='#'
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                    // onClick={() => { deleteplan(data.id) }}
                                    onClick={() => deletePlanModal(data.id)}
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/general/gen027.svg'
                                      className='svg-icon-3'
                                    />
                                  </a>
                                </div>
                                : ''
                            }
                          </td>
                        </tr>
                      </tbody>
                    )
                  })
                  :
                  <tbody>
                    <tr>
                      <td colSpan={9}>
                        <h6 className='text-center bg-gray-200i p-4 text-gray-800 mb-0 mt-6'>Data Not Found</h6>
                      </td>
                    </tr>
                  </tbody>
              }
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
      </div>
      {/* begin::Body */}

      <div className="modal fade" ref={modalRef} tabIndex={-1} id="kt_modal_1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header p-4">
              <h5 className="modal-title">Create Plan</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                // data-bs-dismiss="modal"
                aria-label="Close"
              >
                <a
                  href='#'
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                  onClick={closeKpPlanModal}
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon svg-icon-2x"
                  />
                </a>
              </div>
            </div>
            <div className="modal-body">
              <div className='row'>
                <form onSubmit={handleSubmit(submitHandler)}>
                  <div className="mb-3 col-lg-12">
                    <label className="form-label">Plan Name*</label>
                    <input
                      type="text"
                      name='name'
                      placeholder="Enter Plan Name"
                      className={`form-control ${values?.name_error === "" ? '' : 'is-invalid'}`}
                      value={values.name}
                      onChange={(e) => handleChange(e)}
                    />
                    <p id='error_1' className='errormsg' >{values?.name_error}</p>
                  </div>
                  <div className='row'>
                    <div className="mb-3 col-lg-6">
                      <label className="form-label">Duration*</label>
                      <input
                        type="number"
                        name='duration'
                        placeholder="Enter Duration"
                        className={`form-control ${values?.duration_error === "" ? '' : 'is-invalid'}`}
                        value={values.duration}
                        onChange={(e) => handleChange(e)}
                      />
                      <p id='error_1' className='errormsg' >{values?.duration_error}</p>
                    </div>
                    <div className=" col-lg-6">
                      <label className='form-label'>Duration Type*</label>
                      <Controller
                        name="durationtype"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            isClearable
                            isSearchable={true}
                            placeholder='Select Durationtype'
                            //  value={values.durationType}
                            value={durationtype.filter(
                              (obj) => obj.value === values.durationtype)}
                            onChange={durationTypes}
                            className={values.durationtype_error ? 'error-border' : ''}
                            id="durationType"
                            options={durationtype}
                          />
                        )} />
                      <p id='error_1' className='errormsg' >{values?.durationtype_error}</p>
                    </div>
                  </div>

                  <div className='row'>
                    {/* <div className="mb-3 col-lg-6">
                      <label className='form-label'>Industry*</label>
                      <Controller
                          name="industrye"
                          control={control}
                          render={({ field }) => (
                            <Select
                            {...field}
                             className={`${values?.industrye_error === "" ? '' : 'is-invalid'}`}
                            isClearable
                            isSearchable={true}
                            placeholder='Select Industry'
                            value={industry.filter(
                              (obj) => obj.value === values.industrye)}
                            ref={selectRef}
                            onChange={industrySelectHandler}
                            id="Industry"
                            options={industry}
                          />
                      )}/>
                      <p id='error_1' className='errormsg' >{values?.industrye_error}</p>
                    </div> */}
                    <div className="mb-3 col-lg-6">
                      <label className="form-label">Amount*</label>
                      <input
                        type="number"
                        name='Amount'
                        placeholder="Enter Amount"
                        className={`form-control ${values?.Amount_error === "" ? '' : 'is-invalid'}`}
                        value={values.Amount}
                        onChange={(e) => handleChange(e)}
                      />
                      <p id='error_1' className='errormsg' >{values?.Amount_error}</p>
                    </div>
                  </div>

                  <div className='row'>
                    <div className="mb-3 col-lg-6">
                      <label className="form-label">Start Date*</label>
                      <input
                        type="date"
                        name='startdate'
                        placeholder="Enter StartDate"
                        className={`form-control calendar-picker-indicator ${values?.startdate_error === "" ? '' : 'is-invalid error-calander-padding'}`}
                        value={values.startdate}
                        onChange={(e) => handleChange(e)}
                      />
                      <p id='error_1' className='errormsg' >{values?.startdate_error}</p>
                    </div>
                    <div className="mb-3 col-lg-6">
                      <label className="form-label">End Date*</label>
                      <input
                        type="date"
                        name='enddate'
                        min={values?.startdate}
                        placeholder="Enter EndDate"
                        className={`form-control calendar-picker-indicator ${values?.enddate_error === "" ? '' : 'is-invalid error-calander-padding'}`}
                        value={values.enddate}
                        onChange={(e) => handleChange(e)}
                      />
                      <p id='error_1' className='errormsg' >{values?.enddate_error}</p>
                    </div>
                  </div>

                  <div className="mb-3 col-lg-12">
                    <label className="form-label">Webinar Credits*</label>
                    <input
                      type="text"
                      name='webinarcredits'
                      placeholder="Enter Webinar Credits"
                      className={`form-control ${values?.webinarcredits_error === "" ? '' : 'is-invalid'}`}
                      value={values.webinarcredits}
                      onChange={(e) => handleChange(e)}
                    />
                    <p id='error_1' className='errormsg' >{values?.webinarcredits_error}</p>
                  </div>
                  <div className="mb-3 col-lg-12">
                    <label className="form-label">Remarks*</label>
                    <input
                      type="text"
                      name='remark'
                      placeholder="Enter Remark"
                      className={`form-control ${values?.remark_error === "" ? '' : 'is-invalid'}`}
                      value={values.remark}
                      onChange={(e) => handleChange(e)}
                    />
                    <p id='error_1' className='errormsg' >{values?.remark_error}</p>
                  </div>
                  <div className="modal-footer p-0 ms-auto w-100 border-0 mt-2">
                    <button type="submit" className="btn btn-primary">
                      Create
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" ref={deleteModalRef} tabIndex={-1} id="kt_modal_1">
        <div className="modal-dialog modelLower">
          <div className="modal-content">
            <div className="modal-header p-4">
              <h5 className="modal-title">Inactive Plan</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                // data-bs-dismiss="modal"
                aria-label="Close"
              >
                <a
                  href='#'
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                  onClick={closeKpPlanDeleteModal}
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon svg-icon-2x"
                  />
                </a>
              </div>
            </div>
            <div className="modal-body">
              <p className='inactiveName'>Are you sure you want to INACTIVE this plan ?</p>
            </div>
            <div className='modal-footer py-1 px-3 ms-auto w-100 deleteButton'>
              <button className="btn btn-primary btn-sm" onClick={deleteplan}>Yes</button>
              <button className="btn btn-secondary btn-sm" onClick={closeKpPlanDeleteModal}>No</button>
            </div>
          </div>
        </div>
      </div>

      {
          totalPageNo >0 &&

      <Pagination
        totalCount={totalPageNo}  //total number of data
        siblingCount={1} // number of extra elements around avtive page
        currentPage={page} //current active page
        onPageChange={setPage} //page change handler state
        pageSize={10} // data per page
      />
                    }
      {/* <Pagination/>       */}

      { /* ............................editkp plan ...................... */}

      {/* <EditKnowledgePartnerPlan
        editkpref={editkpref}
        setEditData={setEditData}
        editData={editData}
        industry={industry}
        // closeModal1={closeEditKpPlanModal}
        KnowledgePartnerAllData={KnowledgePartnerAllData}
      /> */}
    </div>

  )
}
export default KnowledgePartner
