import { Field, validateYupSchema } from 'formik'
import React, { useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { Controller, ControllerFieldState, ControllerRenderProps, FieldValues, useForm, UseFormStateReturn } from 'react-hook-form';
import { useEffect } from 'react';
import { useRef } from 'react';
import EditDesignation from './EditDesignation';
import Pagination from '../../../components/Pagination'
import CallFor from '../../../routing/CallFor';
import Select from 'react-select';

const designationBreadCrumbs: Array<PageLink> = [
  {
    title: 'Management',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
type UserSubmitForm = {
  designation: string,
  selectstatus: string
}

const statuses = [
  { value: 'ACTIVE', label: 'ACTIVE' },
  { value: 'INACTIVE', label: 'INACTIVE' }
]


const Designation = () => {

  const modalRef = useRef<HTMLDivElement>(null);
  const editref = useRef<HTMLDivElement>(null);
  const inputref = useRef<HTMLDivElement>(null);
  const [designationSearch, setDesignationSearch] = useState<any>({
    designat: "",
    stat: ""
  });
  const [newdata, setNewdata] = useState<any[]>([]);
  const [editData, setEditData] = useState<any>({});
  const [addDesignationData, setAddDesignationData] = useState({
    designation: '',
    designation_error: '',
    selectstatus: '',
    selectstatus_error: ''
  })
  const [page, setPage] = useState<number>(0);
  const [totalPageNo, setTotalPageNo] = useState<number>(1);
  const [isClear, setIsClear] = useState(false);
  useEffect(() => {
    searchDesination()
  }, [isClear, page])

  // const validationschema = Yup.object().shape({
  //   designation: Yup.string().required('Enter Designation').matches(/^[A-Za-z-&-\s]+$/, 'Only alphabets allowed ').test(
  //     'len',
  //     'Minimum 2 character require',
  //     (val) => val !== undefined && val?.toString()?.length > 2).test(
  //       'len',
  //       'Maximum 50 character allowed',
  //       (val) => val !== undefined && val?.toString()?.length < 50),
  //   selectstatus: Yup.string().required('Please Select status'),
  // })

  const { handleSubmit, control } = useForm<UserSubmitForm>({
    mode: 'onChange'
  });

  const addDesignationModal = () => {
    const divElement = document.createElement("div")
    divElement.classList.add("modal-backdrop")
    divElement.classList.add("fade")
    divElement.classList.add("show")
    const body = document.getElementById("kt_app_body")
    body?.appendChild(divElement)
    modalRef.current!.style.display = "block"
    modalRef.current!.style.paddingLeft = "0px"
    modalRef.current!.classList.add("show")
    modalRef.current!.setAttribute("aria-modal", "true")
    modalRef.current!.setAttribute("role", "dialog")
    modalRef.current!.removeAttribute("aria-hidden")
  }
  const editDesignationModal = () => {
    const divElement = document.createElement("div")
    divElement.classList.add("modal-backdrop")
    divElement.classList.add("fade")
    divElement.classList.add("show")
    const body = document.getElementById("kt_app_body")
    body?.appendChild(divElement)
    editref.current!.style.display = "block"
    editref.current!.style.paddingLeft = "0px"
    editref.current!.classList.add("show")
    editref.current!.setAttribute("aria-modal", "true")
    editref.current!.setAttribute("role", "dialog")
    editref.current!.removeAttribute("aria-hidden")
  }
  const closeModal = () => {
    setAddDesignationData({
      designation: '',
      designation_error: '',
      selectstatus: '',
      selectstatus_error: ''
    })
    modalRef.current!.style!.display = "none"
    modalRef.current!.classList.remove("show")
    modalRef.current!.removeAttribute("aria-modal")
    modalRef.current!.removeAttribute("role")
    modalRef.current!.setAttribute("aria-hidden", "true");
    const divElement = document.getElementsByClassName('modal-backdrop')
    divElement[0].remove()
  }

  const handleChange = (event: { target: { value: any; name: any; }; }) => {
    const validationField = [event.target.name] + '_error'
    setAddDesignationData({ ...addDesignationData, [event.target.name]: event.target.value, [validationField]: '' });
  }

  const validationschema = () => {

    if (addDesignationData.designation === '') {
      setAddDesignationData((prev: any) => ({ ...prev, designation_error: "Enter Designation" }))
      return false
    }
    // if (addDesignationData.designation.match(/[0-9]/) || addDesignationData.designation.match(/[.*+?^${}@#=%()|[\]\\]/)) {
    //   setAddDesignationData((prev: any) => ({ ...prev, designation_error: "Only Alphabets Are Allowed" }))
    //   return false
    // }
    // if (addDesignationData.selectstatus==='') {
    //  setAddDesignationData((prev:any)=>({...prev, selectstatus_error: "Select Status"}))
    //   return false
    // } 
    return true
  }

  const submitHandler = async () => {

    if (validationschema()) {
      const jsondata = {
        designationName: addDesignationData.designation,
        status: 'ACTIVE'
      }
      const json1 = JSON.stringify(jsondata);
      const response = await CallFor('api/admin/v1/designations', 'POST', json1, 'Auth');
      if (response.status === 200) {
        closeModal();
        // getDesignationDetails();
        searchDesination();
        setAddDesignationData({
          designation: '',
          designation_error: '',
          selectstatus: '',
          selectstatus_error: ''
        })
      }
    }
  }

  const editDesignation = async (id: string) => {
    editDesignationModal();
    const response = await CallFor('api/admin/v1/designations/' + id, 'GET', null, 'Auth');
    if (response.status === 200) {
      const res = await response.json();
      setEditData({
        id: res.id,
        designationName: res.designationName,
        designationName_error: '',
        status: res.status,
        status_error: ''
      });
    }
  }
  const deleteDesignation = async (id: string) => {
    setNewdata(newdata.filter((value) => value.id !== id));
    const response = await CallFor('api/admin/v1/designations/' + id, 'DELETE', null, 'Auth')
    if (response.status === 204) {
      searchDesination();
    }
  }

  const validationSchema = () => {
    if (designationSearch.designat === '' && designationSearch.stat === '') {
      setDesignationSearch((prev: any) => ({ ...prev, designat_error: "Enter designation", stat_error: "Select status" }))
      return false
    }
    return true
  }

  const searchSubmit = () => {
    if (validationSchema()) {
      setPage(0);
      searchDesination()
    }
  }

  const searchDesination = async () => {
    setDesignationSearch((prev: any) => ({ ...prev, designat_error: '', stat_error: '' }))
    try {
      const response = await CallFor('api/admin/v1/designations?designationName=' + designationSearch.designat + '&status=' + designationSearch.stat + "&page=" + page + "&size=10", 'GET', null, 'Auth')
      if (response.status === 200) {
        const res = await response.json();
        if (res.length === 0) {
          setPage(0);
          setTotalPageNo(0);
        }
          setTotalPageNo(Number(res.data.pages) )
        setNewdata(res.data.content);
      }
    } catch (error) {
      console.log(error);
    }
    setIsClear(false);
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const validationField = [event.target.name] + '_error'
    setDesignationSearch({ ...designationSearch, [event.target.name]: event.target.value, [validationField]: '' })
  }

  const clearSearch = () => {
    setPage(0);
    setDesignationSearch((prev: any) => ({ ...prev, designat: "", stat: "", designat_error: '', stat_error: '' }))
    searchDesination();
    setIsClear(true);
  }

  const statusSearchHandler = (event: any) => {
    if (event !== null && event.value !== undefined) {
      setDesignationSearch({ ...designationSearch, stat: event.value, stat_error: '' });
    }
    else {
      setDesignationSearch({ ...designationSearch, stat: '' });
    }
  }

  return (
    <div>
      <PageTitle >Designation Details</PageTitle>

      <div className='card mb-5 p-5'>
        <div className='row'>
          <div className="mb-3 col-lg-3">
            <label className="form-label">Designation Name</label>
            <input
              type="text"
              name='designat'
              className={`form-control ${designationSearch?.designat_error === "" ? '' : 'is-invalid'}`}
              placeholder="Search Designation Name"
              value={designationSearch.designat}
              onChange={(e) => handleSearch(e)}
            />
            <p id='error_1' className='errormsg' >{designationSearch?.designat_error}</p>
          </div>
          <div className="col-lg-3">
            <label className='form-label'>Status</label>
            <Controller
              name='selectstatus'
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  isClearable
                  isSearchable={true}
                  placeholder='Select Status'
                  // className={designationSearch.stat_error ? 'error-border' : ''}
                  value={statuses.filter(
                    (obj) => obj.value === designationSearch.stat
                  )}
                  onChange={statusSearchHandler}
                  id='status'
                  options={statuses}
                  className={designationSearch.stat_error ? 'error-border' : ''}
                />
              )}
            />
            <p id='error_1' className='errormsg' >{designationSearch?.stat_error}</p>
          </div>
          <div className="mb-3 col-lg-3 mt-8">
            <button type='submit' className='btn btn-primary' onClick={searchSubmit} >Search</button>
            <button type='submit' className='btn btn-danger clear-btn' onClick={clearSearch}>clear</button>
          </div>
        </div>
      </div>

      <div className='card mb-5'>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Designation Details</span>
            {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 members</span> */}
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <button
              type="button"
              className="btn btn-primary btn-sm"
              id='openMdl'
              // data-bs-toggle="modal"

              data-bs-target="#kt_modal_1"
              onClick={addDesignationModal}
            >
              Add Designation
            </button>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
              <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                  <th className='min-w-150px'>Designation</th>
                  <th className='min-w-140px'>Status</th>
                  <th className='min-w-100px text-end'>Actions</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              {
                newdata.length > 0 ?
                  newdata.map((data, i) => {
                    return (
                      <tbody key={i}>
                        <tr>
                          <td>
                            <div className='d-flex justify-content-start flex-column' key={data.id}>
                              <a href='#' className='text-dark text-hover-primary fs-6'>
                                {data.designationName}
                              </a>
                            </div>
                          </td>
                          <td>
                            <a href='#' className='text-dark text-hover-primary d-block fs-6'>
                              {data.status}
                            </a>
                          </td>
                          {
                            data.status === 'ACTIVE' ?
                              <td>
                                <div className='d-flex justify-content-end flex-shrink-0'>
                                  <a
                                    href='#'
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                    onClick={() => { editDesignation(data.id) }}
                                  >
                                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                  </a>
                                  <a
                                    href='#'
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                    onClick={() => { deleteDesignation(data.id) }}
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/general/gen027.svg'
                                      className='svg-icon-3'
                                    />
                                  </a>
                                </div>
                              </td>
                              :
                              <td>
                                <div className='d-flex justify-content-end flex-shrink-0'>
                                  <a
                                    href='#'
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                    onClick={() => { editDesignation(data.id) }}
                                  >
                                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                  </a>
                                </div>
                              </td>
                          }
                        </tr>
                      </tbody>
                    )
                  })
                  :
                  <tbody>
                    <tr>
                      <td colSpan={9}>
                        <h6 className='text-center bg-gray-200i p-4 text-gray-800 mb-0 mt-6'>Data Not Found</h6>
                      </td>
                    </tr>
                  </tbody>
              }
            </table>
          </div>
        </div>
      </div>

      <div className="modal fade" ref={modalRef} tabIndex={-1} id="kt_modal_1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header p-4">
              <h5 className="modal-title">Add Designation</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                // data-bs-dismiss="modal"
                aria-label="Close"
              >
                <a
                  href='#'
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                  onClick={closeModal}
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon svg-icon-2x"
                  />
                </a>
              </div>
            </div>
            <div className="modal-body">
              <div className='row'>

                <form onSubmit={handleSubmit(submitHandler)}>
                  <div className="mb-3 col-lg-12">
                    <label className="form-label">Designation Name</label>
                    <input
                      type="text"
                      placeholder="Enter Designation Name"
                      className={`form-control ${addDesignationData?.designation_error === "" ? '' : 'is-invalid'}`}
                      name='designation'
                      id='designation'
                      value={addDesignationData.designation}
                      onChange={(e) => handleChange(e)}
                    />
                    <p id='error_1' className='errormsg' >{addDesignationData?.designation_error}</p>
                  </div>
                  <div className="modal-footer p-0 ms-auto w-100 border-0 mt-2">
                    <button type="submit" className="btn btn-primary"
                      // data-bs-dismiss="modal"
                      id='saveBtn'
                    >
                      Save Designation
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

        {
          totalPageNo >0 &&
      <Pagination
        totalCount={totalPageNo}  //total number of data
        siblingCount={1} // number of extra elements around avtive page
        currentPage={page} //current active page
        onPageChange={setPage} //page change handler state
        pageSize={10} // data per page
      />
        }

      <EditDesignation
        editref={editref}
        getDesignationDetails={searchDesination}
        setEditData={setEditData}
        editData={editData}
        inputref={inputref}
      />
    </div>

  )
}
export default Designation