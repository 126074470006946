import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { KTSVG, } from '../../../../_metronic/helpers'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import Pagination from '../../../components/Pagination'
import CallFor from '../../../routing/CallFor'
import { toast } from 'react-toastify'

const employeeBreadCrumbs: Array<PageLink> = [
    {
        title: 'Feature Plan',
        path: '#',
        isSeparator: false,
        isActive: false,
    }
]

type UserSubmitForm = {
    EmployeeName: string,
}

const Feature = () => {
    const modalRef = useRef<HTMLDivElement>(null);
    const [designation, setDesignation] = useState<any[]>([]);
    const [employeedata, setEmployeedata] = useState({
        EmployeeName: "",
        EmployeeName_error: '',

    });

    const [page, setPage] = useState<number>(0);
    const [totalPageNo, setTotalPageNo] = useState<number>(0);
    const [deleteId, setDeleteId] = useState()
    const deleteModalRef = useRef<HTMLDivElement>(null);

    const convertBigIntToString = (bigIntNumber: any) => {
        return BigInt(bigIntNumber).toString()
    }

    useEffect(() => {
        listingApi();
    }, [page])

    //   -------------------------- Listing Feature Api  start ------------------------

    const listingApi = async () => {
        try {
            const response = await CallFor('api/admin/v1/pay/plans/features/listings', 'POST', JSON.stringify({ page: page }), 'Auth')
            if (response.status === 200) {
                const res = await response.json();

                if (res?.length === 0) {
                    setPage(0);
                    setTotalPageNo(0);
                }
                setTotalPageNo(Number(res.data.page))
                setDesignation(res.data.content);
            }
        } catch (error) {
            console.log(error)
        }
    }

    //   -------------------------- Listing Feature Api End ---------------------------//

    // ------------------------   Feature detail Add --------------------- //

    const submitHandler = async () => {
        if (validationSchema()) {
            const jsondata = {
                featuresName: employeedata.EmployeeName,
            }
            const data = JSON.stringify(jsondata)
            const response = await CallFor('api/admin/v1/pay/plans/features/createfeature', 'POST', data, 'Auth')
            if (response.status === 200) {
                listingApi();
                closeEmployeeModal();
            } else if (response.status === 400) {
                toast.error("Feature allready Created")
                closeEmployeeModal();

            }
        }
    }

    // ------------------------  Feature detail End  --------------------- //

    // -----------------------------  Delete Api --------------------------- // 

    const deleteFeatureDetail = async () => {
        const response = await CallFor('api/admin/v1/pay/plans/features/deletefeature/' + convertBigIntToString(deleteId), 'DELETE', null, 'Auth')
        if (response.status === 200) {
            setDesignation(designation.filter((item) => item.featureId !== deleteId));
            toast.success('Successfully Delete')
            listingApi();
        }
        closeKpPlanDeleteModal();

    }

    // -----------------------------  Delete Api End --------------------------- //

    // ---------------------  Form functionality & validation -----------------------//

    const { handleSubmit } = useForm<UserSubmitForm>({ mode: 'onChange' });
    const handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
        const validationField = [event.target.name] + '_error'
        setEmployeedata({ ...employeedata, [event.target.name]: event.target.value, [validationField]: '' })
    }

    //------------- form Validation  ----------//

    const validationSchema = () => {
        let isValid = true;
        if (employeedata.EmployeeName === '') {
            setEmployeedata((prev: any) => ({ ...prev, EmployeeName_error: "Enter Feature name" }))
            isValid = false
        } else
            if (employeedata.EmployeeName.length < 2) {
                setEmployeedata((prev: any) => ({ ...prev, EmployeeName_error: "Minimum 2 characters" }))
                isValid = false
            } else
                if (employeedata.EmployeeName.length > 50) {
                    setEmployeedata((prev: any) => ({ ...prev, EmployeeName_error: "maximum 50 characters" }))
                    isValid = false
                } else if (!employeedata.EmployeeName.match(/^[a-zA-Z0-9]*[a-zA-Z0-9 ]*[a-zA-Z0-9]*$/)) {
                    setEmployeedata((prev: any) => ({ ...prev, EmployeeName_error: "Special characters are not allowed" }));
                    isValid = false;
                }
        return isValid
    }

    // -----------------------------  Add Feature --------------------------- //

    const addEmployeeModal = () => {
        const divElement = document.createElement("div")
        divElement.classList.add("modal-backdrop")
        divElement.classList.add("fade")
        divElement.classList.add("show")
        const body = document.getElementById("kt_app_body")
        body?.appendChild(divElement)
        modalRef.current!.style.display = "block"
        modalRef.current!.style.paddingLeft = "0px"
        modalRef.current!.classList.add("show")
        modalRef.current!.setAttribute("aria-modal", "true")
        modalRef.current!.setAttribute("role", "dialog")
        modalRef.current!.removeAttribute("aria-hidden")
    }

    const closeEmployeeModal = () => {
        setEmployeedata({
            EmployeeName: "",
            EmployeeName_error: ''

        });
        modalRef.current!.style!.display = "none"
        modalRef.current!.classList.remove("show")
        modalRef.current!.removeAttribute("aria-modal")
        modalRef.current!.removeAttribute("role")
        modalRef.current!.setAttribute("aria-hidden", "true");
        const divElement = document.getElementsByClassName('modal-backdrop')
        divElement[0].remove()
    }

    // ----------------------- Delete Modal -------------------------- 

    const deleteFeatureModal = (featureId: any) => {
        setDeleteId(featureId)
        const divElement = document.createElement("div")
        divElement.classList.add("modal-backdrop")
        divElement.classList.add("fade")
        divElement.classList.add("show")
        const body = document.getElementById("kt_app_body")
        body?.appendChild(divElement)
        deleteModalRef.current!.style.display = "block"
        deleteModalRef.current!.style.paddingLeft = "0px"
        deleteModalRef.current!.classList.add("show")
        deleteModalRef.current!.setAttribute("aria-modal", "true")
        deleteModalRef.current!.setAttribute("role", "dialog")
        deleteModalRef.current!.removeAttribute("aria-hidden")
    }

    const closeKpPlanDeleteModal = () => {
        deleteModalRef.current!.style!.display = "none"
        deleteModalRef.current!.classList.remove("show")
        deleteModalRef.current!.removeAttribute("aria-modal")
        deleteModalRef.current!.removeAttribute("role")
        deleteModalRef.current!.setAttribute("aria-hidden", "true");
        const divElement = document.getElementsByClassName('modal-backdrop')
        divElement[0].remove()
    }


    return (
        <div>

            {/* ---------------------------- Title ------------------------------ */}

            <PageTitle breadcrumbs={employeeBreadCrumbs}>Feature Details</PageTitle>
            <div className='card mb-5'>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>Feature Details</span>
                    </h3>

                    {/* ---------------------------- Title End ------------------------------ */}
                    <div
                        className='card-toolbar'
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        data-bs-trigger='hover'
                        title='Click to add a user'
                    >
                        <button type="button" className="btn btn-primary btn-sm" data-bs-target="#kt_modal_1" onClick={addEmployeeModal}>Add Feature</button>
                    </div>
                </div>

                {/* ---------------------- Feature Table --------------------------  */}

                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                                    <th className='min-w-150px'>Feature Name</th>
                                </tr>
                            </thead>

                            {
                                designation.length > 0
                                    ?
                                    designation.map((data, index) => {

                                        return (
                                            <tbody key={index}>
                                                <tr>
                                                    <td>
                                                        <pre className='d-flex align-items-center'>{data.featureName}</pre>
                                                    </td>
                                                    <td>
                                                        <div className='d-flex justify-content-end flex-shrink-0'>
                                                            <a
                                                                href='#'
                                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                                                onClick={() => { deleteFeatureModal(data.featureId) }}
                                                            >
                                                                <KTSVG
                                                                    path='/media/icons/duotune/general/gen027.svg'
                                                                    className='svg-icon-3'
                                                                />
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        )
                                    })
                                    :
                                    <tbody><tr> <td colSpan={9}> <h6 className='text-center bg-gray-200i p-4 text-gray-800 mb-0 mt-6'>Data Not Found</h6> </td> </tr> </tbody>
                            }
                        </table>
                    </div>
                </div>
            </div>

            {/* ---------------------- Feature Table End --------------------------  */}

            {/* ---------------------------  Add feature Detail ---------------------  */}

            <div className="modal fade" ref={modalRef} tabIndex={-1} id="kt_modal_1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Feature</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <a
                                    href='#'
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                    onClick={closeEmployeeModal}
                                >
                                    <KTSVG
                                        path="/media/icons/duotune/arrows/arr061.svg"
                                        className="svg-icon svg-icon-2x"
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className='row'>
                                <form onSubmit={handleSubmit(submitHandler)}>
                                    <div className="mb-3 col-lg-12">
                                        <label className="form-label">Add Feature*</label>
                                        <input
                                            type="text"
                                            placeholder="Feature Name"
                                            className={`form-control ${employeedata?.EmployeeName_error === "" ? '' : 'is-invalid'}`}
                                            value={employeedata.EmployeeName}
                                            name='EmployeeName'
                                            onChange={(e) => handleChange(e)}
                                            id='designation'
                                        />
                                        <p id='error_1' className='errormsg' >{employeedata?.EmployeeName_error}</p>
                                    </div>
                                    <div className="modal-footer p-0 ms-auto w-100 border-0 mt-2">
                                        {/* <button type="submit" className="btn btn-primary"
                                            id='saveBtn'
                                        >
                                            Create Feature
                                        </button> */}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* ---------------------------  Add feature End ---------------------  */}

            {/* ----------------------- Delete modal -------------------- */}

            <div className="modal fade" ref={deleteModalRef} tabIndex={-1} id="kt_modal_1">
                <div className="modal-dialog modelLower">
                    <div className="modal-content">
                        <div className="modal-header p-4">
                            <h5 className="modal-title">Feature Detail</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                aria-label="Close"
                            >
                                <a
                                    href='#'
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                    onClick={closeKpPlanDeleteModal}
                                >
                                    <KTSVG
                                        path="/media/icons/duotune/arrows/arr061.svg"
                                        className="svg-icon svg-icon-2x"
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="modal-body">
                            <p className='inactiveName'>Are you sure you want to delete this Feature ?</p>
                        </div>
                        <div className='modal-footer py-1 px-3 ms-auto w-100 deleteButton'>
                            <button className="btn btn-primary btn-sm" onClick={deleteFeatureDetail}>Yes</button>
                            <button className="btn btn-secondary btn-sm" onClick={closeKpPlanDeleteModal}>No</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* ----------------------- Delete modal End -------------------- */}

            {/* -------------------------- Pagination Start -------------------  */}
            {
                totalPageNo > 0 &&
                <Pagination
                    totalCount={totalPageNo * 10}  //total number of data
                    siblingCount={1} // number of extra elements around avtive page
                    currentPage={page} //current active page
                    onPageChange={setPage} //page change handler state
                    pageSize={10} // data per page
                />}

            {/* -------------------------- Pagination End -------------------  */}

        </div>
    )
}
export default Feature
