import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import CallFor from '../../../routing/CallFor';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { convertResponsetojsonBig } from '../../../../_metronic/layout/core/convertToBigJson';
const addpartnerBreadCrumbs: Array<PageLink> = [
    {
        title: 'Knowledge Partner',
        path: '#',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]
const AddKnowledgePartner = () => {
    const navigate = useNavigate();
    const [values, setValues] = useState({
        organizationName: "",
        contactName: "",
        contactNumber: "",
        emailId: "",
        gstNumber: "",
        Plan: "",
        Amount: "",
        Discount: "",
        invoiceAmount: "",
        gstInclude: "",
        cgst: "9%",
        sgst: "9%",
        paymentDetail: "",
        date: "",
        industry: "",
        ClientProvide: "",
        url: "",
        logo: "",
        totalAmount: "",
        totalAmount_error: "",
        partnerLabel: "",
        description: '',
        webinarCredit: "",
        webinarCode: "",
        organizationName_error: "",
        contactName_error: "",
        contactNumber_error: "",
        emailId_error: "",
        gstNumber_error: "",
        Plan_error: "",
        Amount_error: "",
        Discount_error: "",
        invoiceAmount_error: "",
        gstInclude_error: "",
        cgst_error: "",
        sgst_error: "",
        paymentDetail_error: "",
        date_error: "",
        industry_error: "",
        ClientProvide_error: "",
        url_error: "",
        logo_error: "",
        partnerLabel_error: "",
        descriptionLabel_error: "",
        webinarCredit_error: "",
        webinarCode_error: ""
    });
    const [industry, setIndustry] = useState<any[]>([]);
    const [plan, setPlan] = useState<any[]>([]);
    const { handleSubmit, control } = useForm({})
    useEffect(() => {
        getIndstury();
    }, []);
    useEffect(() => {
        kpPlan();
    }, []);

    // useEffect(() => {
    //     planDetailAmountHander()
    // }, [values.Amount, values.Discount, values.gstInclude])

    useEffect(() => {
        if (values.Plan) {
            setValues((prev: any) => ({ ...prev, Amount: plan.filter(it => it.value === values.Plan)[0].amount }))
            planDetailAmountHander()
        }
    }, [values.Plan, values.Amount, values.Discount, values.gstInclude])
    const kpPlan = async () => {
        const response = await CallFor('api/admin/v1/plans/PlaneAmount', 'GET', null, 'Auth')
        // const response = await fetch('http://192.168.7.119:7075/api/admin/v1/plans/PlaneAmount',{
        //     method:'GET'
        // })
        if (response.status === 200) {
            const res = await response.json();
            const states = res.data.filter((item: any) => item.status === 'ACTIVE').map(
                (d: { id: any; planName: any; amount: any; status: any }) => ({
                    value: d.id,
                    label: d.planName,
                    amount: d.amount,
                    status: d.status
                })
            );
            setPlan(states);
        }
    }

    const planSelectHandler = (event: any) => {
        if (event !== null && event.value !== undefined) {
            setValues({ ...values, Plan: event.value, Plan_error: '' });
        }
        else {
            setValues({ ...values, Plan: '' });
        }
    };
    const industrySelectHandler = (event: any) => {
        if (event !== null && event.value !== undefined) {
            setValues({ ...values, industry: event.value, industry_error: '' });
        }
        else {
            setValues({ ...values, industry: '' });
        }
    };
    const getIndstury = async () => {
        const response = await fetch(`${process.env.NODE_ENV === "development" ? "http://192.168.7.41:7777" : "https://admin.zyapaar.com"}/api/public/v2/industries`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                // 'authorization': 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxMDQ3NDA2NDQyNTA0Nzk4MjA4Iiwicm9sZXMiOiJST0xFX1VTRVIiLCJpYXQiOjE2Njk3OTE1NTUsImV4cCI6Mzc2Njk3OTE1NTV9.swGYxmTnnfsUgb1Ccf1h-BGQisW1WSscEemEAzLfUPc'
            }
        });
        // const response = await CallFor('api/public/v1/industries', 'GET', null, 'withoutAuth')
        if (response.status === 200) {
            const res = await convertResponsetojsonBig(response);
            console.log(res)
            const states = await res.data.map(
                (d: { subIndustryID: any; subIndustry: any }) => ({
                    value: d.subIndustryID,
                    label: d.subIndustry
                })
            );
            setIndustry(states);
        }
    }
    const handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
        const validationField = [event.target.name] + '_error'
        setValues({ ...values, [event.target.name]: event.target.value, [validationField]: '' });
    }
    const handleChangeDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const validationField = [event.target.name] + '_error'
        setValues({ ...values, [event.target.name]: event.target.value, [validationField]: '' });
    }
    const validationSchema = () => {
        let isValid = true;

        if (values.organizationName.trim() === '') {
            setValues((prev: any) => ({ ...prev, organizationName_error: "Enter organization name" }))
            isValid = false
        } else
            if (values.organizationName.trim().length < 2) {
                setValues((prev: any) => ({ ...prev, organizationName_error: "Minimum 2 characters required" }))
                isValid = false
            } else
                if (values.organizationName.trim().length > 100) {
                    setValues((prev: any) => ({ ...prev, organizationName_error: "Upto  100 characters allowed" }))
                    isValid = false
                }

        if (values.contactName.trim() === '') {
            setValues((prev: any) => ({ ...prev, contactName_error: "Enter client contact name" }))
            isValid = false
        } else
            if (values.contactName.match(/[0-9]/) || values.contactName.match(/[*+?^${}@#=%()|[\]\\]/)) {
                setValues((prev: any) => ({ ...prev, contactName_error: "Only alphabets are alloewd" }))
                isValid = false
            } else
                if (values.contactName.trim().length < 2) {
                    setValues((prev: any) => ({ ...prev, contactName_error: "Minimum 2 charactre required" }))
                    isValid = false
                } else
                    if (values.contactName.length > 100) {
                        setValues((prev: any) => ({ ...prev, contactName_error: "Upto 100 Characters allowed" }))
                        isValid = false
                    }

        if (values.contactNumber.trim() === '') {
            setValues((prev: any) => ({ ...prev, contactNumber_error: "Enter client mobile number" }))
            isValid = false
        } else
            if (values.contactNumber.match(/[a-zA-Z]/) || values.contactNumber.match(/[.*+?^${}@#=%()|[\]\\]/)) {
                setValues((prev: any) => ({ ...prev, contactNumber_error: "mobile no must be in digit only " }))
                isValid = false
            } else
                if (values.contactNumber.trim().length < 10) {
                    setValues((prev: any) => ({ ...prev, contactNumber_error: "Enter valid client mobile number" }))
                    isValid = false
                } else
                    if (values.contactNumber.trim().length > 10) {
                        setValues((prev: any) => ({ ...prev, contactNumber_error: "mobile number is invalid" }))
                        isValid = false
                    }

        if (values.emailId.trim() === '') {
            setValues((prev: any) => ({ ...prev, emailId_error: "Enter email id" }))
            isValid = false
        } else
            if (!values.emailId.trim().match(/^[A-Za-z0-9.]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/)) {
                setValues((prev: any) => ({ ...prev, emailId_error: "Enter valid email" }))
                isValid = false
            }

        if (values.gstNumber.trim() === '') {
            setValues((prev: any) => ({ ...prev, gstNumber_error: "Enter GST number" }))
            isValid = false
        } else if (!values.gstNumber.trim().match(/[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1}/)) {
            setValues((prev: any) => ({ ...prev, gstNumber_error: "Enter valid GST number " }))
            isValid = false
        }
        else
            if (values.gstNumber.length < 15) {
                setValues((prev: any) => ({ ...prev, gstNumber_error: "GST number must be 15 digit" }))
                isValid = false
            } else
                if (values.gstNumber.length > 15) {
                    setValues((prev: any) => ({ ...prev, gstNumber_error: "GST number maximum length must be 15 digit" }))
                    isValid = false
                }

        if (values.industry.trim() === '') {
            setValues((prev: any) => ({ ...prev, industry_error: "Select industry" }))
            isValid = false
        }

        if (values.Plan.trim() === '') {
            setValues((prev: any) => ({ ...prev, Plan_error: "Select Plan" }))
            isValid = false
        }
        // if (String(values.Amount.trim()) === '') {
        //     setValues((prev: any) => ({ ...prev, Amount_error: "Enter amount" }))
        //     isValid = false
        // }
        // if (values.Amount.match(/[a-zA-Z]/)) {
        //     setValues((prev:any)=>({...prev, Amount_error: "Amount is in only digits"}))
        //     isValid = false
        //  }
        // if (values.Discount.trim() === '') {
        //     setValues((prev: any) => ({ ...prev, Discount_error: "Enter discount" }))
        //     isValid = false
        // }
        if (String(values.invoiceAmount).trim() === '') {
            setValues((prev: any) => ({ ...prev, invoiceAmount_error: "Enter invoiceAmount" }))
            isValid = false
        }
        if (values.gstInclude.trim() === '') {
            setValues((prev: any) => ({ ...prev, gstInclude_error: "Select GST included" }))
            isValid = false
        }
        // if (values.cgst.trim() === '') {
        //     setValues((prev: any) => ({ ...prev, cgst_error: "Enter CGST" }))
        //     isValid = false
        // }
        // if (values.sgst.trim() === '') {
        //     setValues((prev: any) => ({ ...prev, sgst_error: "Enter SGST" }))
        //     isValid = false
        // }
        if (values.paymentDetail.trim() === '') {
            setValues((prev: any) => ({ ...prev, paymentDetail_error: "Enter payment detail" }))
            isValid = false
        }
        if (values.date === '') {
            setValues((prev: any) => ({ ...prev, date_error: "Enter date" }))
            isValid = false
        }
        if (values.ClientProvide.trim() === '') {
            setValues((prev: any) => ({ ...prev, ClientProvide_error: "Select link" }))
            isValid = false
        }
        if (values.url.trim() === '') {
            setValues((prev: any) => ({ ...prev, url_error: "Enter webpage url" }))
            isValid = false
        } else
            if (!values.url.trim().match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)) {
                setValues((prev: any) => ({ ...prev, url_error: "Enter valid website address" }))
                isValid = false
            }

        if (values.logo.trim() === '') {
            setValues((prev: any) => ({ ...prev, logo_error: "Enter logo url" }))
            isValid = false
        }
        if (values.partnerLabel.trim() === '') {
            setValues((prev: any) => ({ ...prev, partnerLabel_error: "Enter partner label" }))
            isValid = false
        }
        // if (values.description.trim() === '') {
        //     setValues((prev: any) => ({ ...prev, descriptionLabel_error: "Enter description" }));
        //     isValid = false;
        // } else 
        if (values.description.length > 250) {
            setValues((prev: any) => ({ ...prev, descriptionLabel_error: "Description must not exceed 250 characters" }));
            isValid = false;
        } else {
            // If the character count is less than or equal to 250, reset the error message.
            setValues((prev: any) => ({ ...prev, descriptionLabel_error: "" }));
        }
        // if (values.webinarCredit.trim() === '') {
        //     setValues((prev: any) => ({ ...prev, webinarCredit_error: "Enter webinar credit" }))
        //     isValid = false
        // } else
        if (values.webinarCredit.trim().match(/[a-zA-Z]/)) {
            setValues((prev: any) => ({ ...prev, webinarCredit_error: "WebinarCredit must be number" }))
            isValid = false
        }

        // if (values.webinarCode.trim() === '') {
        //     setValues((prev: any) => ({ ...prev, webinarCode_error: "Enter webinar code" }))
        //     isValid = false
        // }
        return isValid
    }
    const submitHandler = async () => {
        if (validationSchema()) {
            const jsonData = {
                companyName: values.organizationName,
                contactNo: values.contactNumber,
                contactDetailsContent: values.contactName,
                email: values.emailId,
                gstNo: values.gstNumber.toUpperCase(),
                planMasterId: values.Plan,
                discount: values.Discount,
                invoiceAmount: values.invoiceAmount,
                gstIncluded: values.gstInclude,
                // cgst: values.cgst,
                // sgst: values.sgst,
                paymentDetails: values.paymentDetail,
                startDate: new Date(values.date).toISOString(),
                industry: values.industry,
                clientWillProvide: values.ClientProvide,
                webpageUrl: values.url,
                logoUrl: values.logo,
                partnerLabel: values.partnerLabel,
                description: values.description,
                webinarCredit: values.webinarCredit,
                webinarCodes: values.webinarCode,
                amount: values.Amount,
                totalAmount: values.totalAmount
            }
            const data = JSON.stringify(jsonData);
            const response = await CallFor('api/admin/v1/knowledge-customers', 'POST', data, 'Auth')
            if (response.status === 200) {
                navigate('/addcustomer');
            }
        }
    }
    const planDetailAmountHander = () => {
        setValues((prev: any) => ({ ...prev, invoiceAmount: (Number(prev.Amount) - Number(prev.Discount)) }))

        if (values.gstInclude === 'True') {
            setValues((prev: any) => ({ ...prev, totalAmount: (prev.invoiceAmount + (Number(prev.invoiceAmount) * 18 / 100)) }))
        } else {
            setValues((prev: any) => ({ ...prev, totalAmount: prev.invoiceAmount }))
        }
    }
    return (
        <div>
            <PageTitle >Create Knowledge Partner</PageTitle>
            <form onSubmit={handleSubmit(submitHandler)} >
                <div className='card mb-5 p-5'>
                    <div className='row'><h3>Organization Details</h3></div>
                    <div className='row'>
                        <div className="mb-3 col-xxl-3 col-lg-4">
                            <label className="form-label">Organization Name*</label>
                            <input
                                type="text"
                                name='organizationName'
                                // className="form-control"
                                className={`form-control ${values?.organizationName_error === "" ? '' : 'is-invalid'}`}
                                placeholder="Enter organization Name"
                                value={values.organizationName}
                                onChange={(e) => handleChange(e)}
                            />
                            <p id='error_1' className='errormsg' >{values?.organizationName_error}</p>
                        </div>
                        <div className="mb-3 col-xxl-3 col-lg-4">
                            <label className="form-label">Contact Name*</label>
                            <input
                                type="text"
                                name='contactName'
                                className={`form-control ${values?.contactName_error === "" ? '' : 'is-invalid'}`}
                                placeholder="Enter Contact Name"
                                value={values.contactName}
                                onChange={(e) => handleChange(e)}
                            />
                            <p id='error_1' className='errormsg' >{values?.contactName_error}</p>
                        </div>
                        <div className="mb-3 col-xxl-3 col-lg-4">
                            <label className="form-label">Mobile Number*</label>
                            <input
                                type="number"
                                name='contactNumber'
                                className={`form-control ${values?.contactNumber_error === "" ? '' : 'is-invalid'}`}
                                placeholder="Enter Mobile Number"
                                value={values.contactNumber}
                                onChange={(e) => handleChange(e)}
                            />
                            <p id='error_1' className='errormsg' >{values?.contactNumber_error}</p>
                        </div>
                        <div className="mb-3 col-xxl-3 col-lg-4">
                            <label className="form-label">Email ID*</label>
                            <input
                                type="text"
                                name='emailId'
                                className={`form-control ${values?.emailId_error === "" ? '' : 'is-invalid'}`}
                                placeholder="Enter Email ID"
                                value={values.emailId}
                                onChange={(e) => handleChange(e)}
                            />
                            <p id='error_1' className='errormsg' >{values?.emailId_error}</p>
                        </div>
                        <div className="mb-3 col-xxl-3 col-lg-4">
                            <label className="form-label">GST Number*</label>
                            <input
                                type="text"
                                name='gstNumber'
                                className={`form-control ${values?.gstNumber_error === "" ? '' : 'is-invalid'}`}
                                placeholder="Enter GST Number"
                                value={values.gstNumber.toUpperCase()}
                                onChange={(e) => handleChange(e)}
                            />
                            <p id='error_1' className='errormsg' >{values?.gstNumber_error}</p>
                        </div>
                        <div className="mb-3 col-xxl-3 col-lg-4">
                            <label className='form-label'>Industry*</label>
                            <Controller
                                name="industry"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        isClearable
                                        isSearchable={true}
                                        placeholder='Select Industry'
                                        value={industry.filter(
                                            (obj) => obj.value === values.industry)}
                                        onChange={industrySelectHandler}
                                        id="Industry"
                                        options={industry}
                                        className={values.industry_error ? 'error-border' : ''}
                                    />
                                )} />
                            <p id='error_1' className='errormsg' >{values?.industry_error}</p>
                        </div>
                    </div>
                    <div className='row mt-5'><h3>Plan Details*</h3></div>
                    <div className='row'>
                        <div className="mb-3 col-xxl-3 col-lg-4">
                            <label className='form-label'>Plan*</label>

                            <Controller
                                name="Plan"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        isClearable
                                        isSearchable={true}
                                        placeholder='Select Plan'
                                        className={values.Plan_error ? 'error-border' : ''}
                                        value={plan.filter(
                                            (obj) => obj.value === values.Plan)}
                                        onChange={planSelectHandler}
                                        id="Plan"
                                        options={plan}
                                    />
                                )} />
                            <p id='error_1' className='errormsg' >{values?.Plan_error}</p>
                        </div>
                        <div className="mb-3 col-xxl-3 col-lg-4">
                            <label className="form-label">Amount*</label>
                            <input
                                disabled={true}
                                type="number"
                                name='Amount'
                                className={`form-control ${values?.Amount_error === "" ? '' : 'is-invalid'}`}
                                placeholder="Enter Amount"
                                value={values.Amount}
                                onChange={(e) => handleChange(e)}
                            />
                            <p id='error_1' className='errormsg' >{values?.Amount_error}</p>
                        </div>
                        <div className="mb-3 col-xxl-3 col-lg-4">
                            <label className="form-label">Discount</label>
                            <input
                                type="number"
                                name='Discount'
                                // className={`form-control ${values?.Discount_error === "" ? '' : 'is-invalid'}`}
                                className='form-control'
                                placeholder="Enter Discount"
                                value={values.Discount}
                                onChange={(e) => handleChange(e)}
                            />
                            {/* <p id='error_1' className='errormsg' >{values?.Discount_error}</p> */}
                        </div>
                        <div className="mb-3 col-xxl-3 col-lg-4">
                            <label className="form-label">Invoice Amount</label>
                            <input
                                disabled={true}
                                type="number"
                                name='invoiceAmount'
                                className={`form-control ${values?.invoiceAmount_error === "" ? '' : 'is-invalid'}`}
                                placeholder="Enter Invoice Amount"
                                value={values.invoiceAmount}
                                onChange={(e) => handleChange(e)}
                            />
                            <p id='error_1' className='errormsg' >{values?.invoiceAmount_error}</p>
                        </div>
                        <div className="mb-3 col-xxl-3 col-lg-4">
                            <label className="form-label">GST included?*</label>
                            <div className="form-check form-check-custom form-check-solid form-check-sm">
                                <input
                                    className="form-check-input"
                                    id="yes"
                                    value="True"
                                    checked={values.gstInclude === 'True'}
                                    // value={values.gstInclude}
                                    name="gstInclude"
                                    type="radio"
                                    onChange={(e) => handleChange(e)}
                                />
                                <label htmlFor='yes' className="form-check-label" >Yes</label>
                                <input
                                    className="form-check-input ms-10"
                                    id="no"
                                    value="False"
                                    // value={values.gstInclude}
                                    name="gstInclude"
                                    type="radio"
                                    checked={values.gstInclude === 'False'}
                                    onChange={(e) => handleChange(e)}
                                />
                                <label htmlFor='no' className="form-check-label" >No</label>
                            </div>
                            <p id='error_1' className='errormsg' >{values?.gstInclude_error}</p>
                        </div>
                        {/* {values.gstInclude === 'True' ?
                            <><div className="mb-3 col-xxl-3 col-lg-4">
                                <label className="form-label">CGST*</label>
                                <input
                                    type="text"
                                    name='cgst'
                                    className={`form-control ${values?.cgst_error === "" ? '' : 'is-invalid'}`}
                                    className='form-control'
                                    disabled={true}
                                    placeholder="Enter CGST"
                                    value={values.cgst}
                                    onChange={(e) => handleChange(e)} />
                                <p id='error_1' className='errormsg'>{values?.cgst_error}</p>
                            </div>
                                <div className="mb-3 col-xxl-3 col-lg-4">
                                    <label className="form-label">SGST*</label>
                                    <input
                                        type="text"
                                        name='sgst'
                                        className={`form-control ${values?.sgst_error === "" ? '' : 'is-invalid'}`}
                                        className='form-control'
                                        placeholder="Enter SGST"
                                        disabled={true}
                                        value={values.sgst}
                                        onChange={(e) => handleChange(e)} />
                                    <p id='error_1' className='errormsg'>{values?.sgst_error}</p>
                                </div>
                            </>
                            : ""} */}
                        <div className="mb-3 col-xxl-3 col-lg-4">
                            <label className="form-label">Total Amount</label>
                            <input
                                disabled={true}
                                type="number"
                                name='totalAmount'
                                // className={`form-control ${values?.sgst_error === "" ? '' : 'is-invalid'}`}
                                className='form-control'
                                placeholder="Enter SGST"
                                value={values.totalAmount}
                                onChange={(e) => handleChange(e)} />
                            <p id='error_1' className='errormsg'>{values?.totalAmount_error}</p>
                        </div>
                    </div>
                    <div className='row mt-5'><h3>Payment Details</h3></div>
                    <div className='row'>
                        <div className="mb-3 col-xxl-3 col-lg-4">
                            <label className="form-label">Payment Details*</label>
                            <input
                                type="text"
                                name='paymentDetail'
                                className={`form-control ${values?.paymentDetail_error === "" ? '' : 'is-invalid'}`}
                                placeholder="Enter Payment Details"
                                value={values.paymentDetail}
                                onChange={(e) => handleChange(e)}
                            />
                            <p id='error_1' className='errormsg' >{values?.paymentDetail_error}</p>
                        </div>
                        <div className="mb-3 col-xxl-3 col-lg-4">
                            <label className="form-label">Date*</label>
                            <input
                                type="date"
                                name='date'
                                className={`form-control ${values?.date_error === "" ? '' : 'is-invalid error-calander-padding'}`}
                                value={values.date}
                                onChange={(e) => handleChange(e)}
                            />
                            <p id='error_1' className='errormsg' >{values?.date_error}</p>
                        </div>
                        {/* <div className="mb-3 col-xxl-3 col-lg-4">
                            <label className='form-label'>Industry</label>
                            <select
                                className={`form-select text-muted ${values?.industry_error === "" ? '' : 'is-invalid'}`}
                                name='industry'
                                value={values.industry}
                                onChange={(e) => handleChange(e)}
                            >
                                <option value=''>Select Industry Or All Industries...</option>
                                {industry.map((data) => {
                                    return (
                                        <option key={data.subIndustryID} value={data.subIndustryID}>
                                            {data.subIndustry}
                                        </option>
                                    );
                                })}
                            </select>
                        <p id='error_1' className='errormsg' >{values?.industry_error}</p>
                        </div> */}
                    </div>
                </div>
                <div className='card mb-5 p-5'>
                    <div className='row'>
                        <div className="mb-3 col-xxl-3 col-lg-4">
                            <label className="form-label">Client will provid*</label>
                            <div className="form-check form-check-custom form-check-solid form-check-sm">
                                {/* <input
                                    className="form-check-input"
                                    id="flexRadioSm"
                                    value="HTML"
                                    // value={values.ClientProvide}
                                    name="ClientProvide"
                                    type="radio"
                                    onChange={(e) => handleChange(e)}
                                />
                                <label className="form-check-label" >HTML</label> */}
                                <input
                                    className="form-check-input ms-10"
                                    id="flexRadioSm"
                                    value="LINK"
                                    // value={values.ClientProvide}
                                    name="ClientProvide"
                                    type="radio"
                                    onChange={(e) => handleChange(e)}
                                />
                                <label className="form-check-label" >Only 1 Link</label>
                            </div>
                        </div>
                    </div>
                    <p id='error_1' className='errormsg' >{values?.ClientProvide_error}</p>
                </div>
                <div className='card mb-5 p-5'>
                    <div className='row'>
                        <div className="mb-3 col-lg-12">
                            <label className="form-label">Webpage URL* <span className='text-gray-500'>(Client will provide it - Open in new window)</span></label>
                            <input
                                type="text"
                                name='url'
                                className={`form-control ${values?.url_error === "" ? '' : 'is-invalid'}`}
                                placeholder="Enter Webpage URL"
                                value={values.url}
                                onChange={(e) => handleChange(e)}
                            />
                            <p id='error_1' className='errormsg' >{values?.url_error}</p>
                        </div>
                        <div className="mb-3 col-lg-12">
                            <label className="form-label">Logo Upload* <span className='text-gray-500'>(Client will provide it)</span></label>
                            <input
                                type="text"
                                name='logo'
                                className={`form-control ${values?.logo_error === "" ? '' : 'is-invalid'}`}
                                value={values.logo}
                                onChange={(e) => handleChange(e)}
                            />
                            <p id='error_1' className='errormsg' >{values?.logo_error}</p>
                        </div>
                        <div className="mb-3 col-lg-12">
                            <label className="form-label">Partner Label*  <span className='text-gray-500'>(Client will provide it)</span></label>
                            <input
                                type="text"
                                name='partnerLabel'
                                className={`form-control ${values?.partnerLabel_error === "" ? '' : 'is-invalid'}`}
                                value={values.partnerLabel}
                                onChange={(e) => handleChange(e)}
                            />
                            <p id='error_1' className='errormsg' >{values?.partnerLabel_error}</p>
                        </div>
                        <div className="mb-3 col-lg-12">
                            <label className="form-label">Description  <span className='text-gray-500'></span></label>
                            <textarea
                                name='description'
                                className={`form-control ${values?.descriptionLabel_error === "" ? '' : 'is-invalid'}`}
                                value={values.description}
                                onChange={(e) => handleChangeDescription(e)}
                            />
                            <p id='error_1' className='errormsg' >{values?.descriptionLabel_error}</p>
                        </div>
                        <div className="mb-3 col-lg-12">
                            <label className="form-label">Free Webinar Credits*</label>
                            <div className='row'>
                                <div className=' col-lg-6'>
                                    <input
                                        type="text"
                                        name='webinarCredit'
                                        className={`form-control ${values?.webinarCredit_error === "" ? '' : 'is-invalid'}`}
                                        value={values.webinarCredit}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </div>
                                <p id='error_1' className='errormsg' >{values?.webinarCredit_error}</p>
                            </div>
                        </div>
                        <div className="mb-3 col-lg-12">
                            <label className="form-label">Webinar Code(s)*</label>
                            <div className='row'>
                                <div className='col-lg-2'>
                                    <input
                                        type="text"
                                        name='webinarCode'
                                        value={values.webinarCode}
                                        className={`form-control ${values?.webinarCode_error === "" ? '' : 'is-invalid'}`}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </div>
                            </div>
                            <p id='error_1' className='errormsg' >{values?.webinarCode_error}</p>
                        </div>
                        <div className="mb-3 col-lg-12 text-center">
                            <button type='submit' className='btn btn-primary'>Save</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}
export default AddKnowledgePartner