// import React from 'react'
import GlobalProperties from "./GlobalProperties";
import { UrlType } from "./UrlType";
import { handleError } from "./ExceptionHandler";
const getToken = () => {
    const user: any = localStorage.getItem('kt-auth-react-v') || null;
    const data: any = JSON.parse(user)
    return data.token;
}

const CallFor = async (requestUrl: string, method: string, data: string | any, headerType: string | FormData | null) => {
    let url = '';
    if (GlobalProperties.environment === UrlType.LIVE) {
        url = GlobalProperties.urlParam + requestUrl;
    } else if (GlobalProperties.environment === UrlType.TEST) {
        url = GlobalProperties.testParam + requestUrl;
    } else {
        url = GlobalProperties.localUrlParam + requestUrl;
    }
    const header = new Headers();
    if (headerType === 'withoutAuth') {
        header.append('Content-Type', 'application/json');
    } else if (headerType === 'withAuth') {
        header.append('curUserId', '123456')
        header.append('Content-Type', 'application/json')
    }
    else if (headerType === 'Auth') {
        header.append('authorization', 'Bearer ' + getToken());
        header.append('curUserId', '123456')
        header.append('Content-Type', 'application/json');
    }
    else if (headerType === 'authWithoutContentType') {
        header.append('authorization', 'Bearer ' + getToken());
    }
    else if (headerType === 'AuthwithContentType') {
        header.append('Authorization', 'Bearer ' + getToken());
        header.append('Content-Type', 'application/json');
    }
    else if (headerType === 'test') {
        header.append('curUserId', '123456');
    } else if (headerType === 'authWithoutContentTypeMultipart') {
        header.append('Authorization', 'Bearer ' + getToken());
        header.append('Content-Type', 'multipart/form-data');
    }
    const requestOptions = {
        method: method,
        headers: header,
        body: data
    };
    const returnResponse = {
        status: 500,
        message: 'Server Error Found'
    };
    try {
        return await fetch(
            url, requestOptions
        )
        // .then((res) => {
        //   return res;
        // }).catch((error) => {
        //   handleError(error);
        //   return returnResponse;
        // });
    } catch (error: any) {
        return handleError(error);
    }
}

export default CallFor