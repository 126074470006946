/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, useRef } from 'react'
import { PageTitle } from '../../../../_metronic/layout/core'
import { KTSVG } from '../../../../_metronic/helpers'
import CallFor from '../../../routing/CallFor'
import { toast } from 'react-toastify'
import Pagination from '../../../components/Pagination'
import { Modal } from 'react-bootstrap'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const BannerNotification = () => {

    const [bannerData, setBannerData] = useState<any[]>([]);
    const [bannerData2, setBannerData2] = useState<any[]>([]);
    const [page, setPage] = useState<number>(0);
    const [totalPageNo, setTotalPageNo] = useState<number>(0);
    const [deleteId, setDeleteId] = useState("")
    const deleteModalRef = useRef<HTMLDivElement>(null);
    const [openList, setOpenList] = useState(false);
    const [perticularBanner, setPerticularBanner] = useState<any>();
    const [openModal, setOpenModal] = useState(false);

    // let ids = bannerData.map((listData: any) => listData.id);

    useEffect(() => {
        getCampaignList();
    }, [page])

    useEffect(() => {
        const getCampaignList2 = async () => {
            try {
                const response = await CallFor('api/admin/v1/banner/list', 'GET', null, 'Auth');
                if (response.status === 200) {

                    const json1response = await response.json();
                    if (json1response.data?.length > 0) {
                        setBannerData2(json1response.data);
                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
        getCampaignList2();
    }, [])

    const deletePlanModal = (deleteId: any) => {
        setDeleteId(deleteId)
        const divElement = document.createElement("div")
        divElement.classList.add("modal-backdrop")
        divElement.classList.add("fade")
        divElement.classList.add("show")
        const body = document.getElementById("kt_app_body")
        body?.appendChild(divElement)
        deleteModalRef.current!.style.display = "block"
        deleteModalRef.current!.style.paddingLeft = "0px"
        deleteModalRef.current!.classList.add("show")
        deleteModalRef.current!.setAttribute("aria-modal", "true")
        deleteModalRef.current!.setAttribute("role", "dialog")
        deleteModalRef.current!.removeAttribute("aria-hidden")
    }

    const closeKpPlanDeleteModal = () => {
        deleteModalRef.current!.style!.display = "none"
        deleteModalRef.current!.classList.remove("show")
        deleteModalRef.current!.removeAttribute("aria-modal")
        deleteModalRef.current!.removeAttribute("role")
        deleteModalRef.current!.setAttribute("aria-hidden", "true");
        const divElement = document.getElementsByClassName('modal-backdrop')
        divElement[0].remove()
        setDeleteId("")
    }

    const deleteEmployeeDetail = async () => {
        const deleteCampain = await CallFor
            (`api/admin/v1/banner/delete/${deleteId}`,
                'DELETE',
                null,
                'Auth')
        const fetchData = await deleteCampain.json()
        if (deleteCampain.status === 200) {
            setBannerData(bannerData.filter((item) => item.id !== deleteId))
            toast.success(fetchData?.message);
            getCampaignList();
        } else if (deleteCampain.status === 404) {
            toast.error(fetchData?.error?.message)
        } else if (deleteCampain.status === 400) {
            toast.error(fetchData?.error?.message)
        }
        closeKpPlanDeleteModal();
    }


    const getCampaignList = async () => {
        try {
            const response = await CallFor('api/admin/v1/banner/list', 'POST', JSON.stringify({ page: page }), 'AuthwithContentType');
            if (response.status === 200) {
                const json1response = await response.json();
                if (json1response?.length === 0) {
                    setPage(0);
                    setTotalPageNo(0);
                }
                setTotalPageNo(Number(json1response.data.pages))
                setBannerData(json1response.data.content)
                console.log(json1response.length, totalPageNo, ".......")
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleOnDragEnd = async (result: any) => {
        if (!result.destination) return;
        const items = bannerData2;
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        // const itemsUpdated = items.map(item => item.id)
    }

    const ListSave = async () => {
        try {
            const listOfBannerId = bannerData2?.map(item => item.id)
            const response = await CallFor('api/admin/v1/banner/ordering', 'POST', JSON.stringify(listOfBannerId), 'AuthwithContentType');
            if (response.status === 200) {
                setOpenList(false);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const reviewModal = () => {
        setOpenList(true)
    }

    return (
        <div>
            <PageTitle>Notification Banner</PageTitle>
            <div className="col-lg-12  text-center">
                <button type='submit' className='btn btn-primary' onClick={reviewModal}>View Banner List</button>
            </div>
            <div className='card mb-5'>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>Banner Details</span>
                    </h3>
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                                    <th>Banner</th>
                                    <th>User Type</th>
                                    <th>Start</th>
                                    <th>End</th>
                                    <th>Web Url</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    bannerData.length > 0 &&
                                    bannerData.map((detail, i) => {
                                        return (
                                            <tr key={i} onClick={() => { setPerticularBanner(detail); setOpenModal(true) }}>
                                                <td><img src={detail.url} style={{ width: "50px" }} alt='' /></td>
                                                <td>
                                                    <div className='d-flex justify-content-start flex-column'>
                                                        {detail.userType}
                                                    </div>
                                                </td>
                                                <td>{detail.startDate}</td>
                                                <td>{detail.endDate}</td>
                                                <td>{detail.webUrl}</td>
                                                <td>
                                                    <a
                                                        href='#'
                                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                                        onClick={(e) => { e.stopPropagation(); deletePlanModal(detail.id) }}
                                                    >
                                                        <KTSVG
                                                            path='/media/icons/duotune/general/gen027.svg'
                                                            className='svg-icon-3'
                                                        />
                                                    </a>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {/* ------Modal------ */}

            <div className="modal fade" ref={deleteModalRef} tabIndex={-1} id="kt_modal_1">
                <div className="modal-dialog modelLower">
                    <div className="modal-content">
                        <div className="modal-header p-4">
                            <h5 className="modal-title">Banner Delete</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                // data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <a
                                    href='#'
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                    onClick={closeKpPlanDeleteModal}
                                >
                                    <KTSVG
                                        path="/media/icons/duotune/arrows/arr061.svg"
                                        className="svg-icon svg-icon-2x"
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="modal-body">
                            <p className='inactiveName'>Are you sure you want to delete Banner ?</p>
                        </div>
                        <div className='modal-footer py-1 px-3 ms-auto w-100 deleteButton'>
                            <button className="btn btn-secondary btn-sm" onClick={deleteEmployeeDetail}>Yes</button>
                            <button className="btn btn-primary btn-sm" onClick={closeKpPlanDeleteModal}>No</button>
                        </div>
                    </div>
                </div>
            </div>
            {
                totalPageNo > 0 &&
                <Pagination
                    totalCount={totalPageNo * 10}  //total number of data
                    siblingCount={1} // number of extra elements around avtive page
                    currentPage={page} //current active page
                    onPageChange={setPage} //page change handler state
                    pageSize={10} // data per page
                />
            }

            {/* listing rearrange modal */}
            <Modal show={openList} onHide={() => setOpenList(true)} className='modal-lg'>
                <Modal.Header>

                    <Modal.Title>Banner List</Modal.Title>
                    <div
                        className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => setOpenList(false)}
                    >
                        <KTSVG
                            path="/media/icons/duotune/arrows/arr061.svg"
                            className="svg-icon svg-icon-2x"
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='card-body py-3'>
                        <div className='table-responsive'>

                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                <thead>
                                    <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                                        <th>Banner</th>
                                        <th>User Type</th>
                                        <th>Start</th>
                                        <th>End</th>
                                        <th>Web Url</th>
                                    </tr>
                                </thead>

                                <DragDropContext onDragEnd={handleOnDragEnd}>
                                    <Droppable droppableId="characters">
                                        {(provided) => (
                                            <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                                {
                                                    bannerData2.length > 0 &&
                                                    bannerData2.map((detail, i) => {
                                                        return (
                                                            <Draggable key={i} draggableId={detail.id} index={i}>
                                                                {(provided) => (
                                                                    <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>

                                                                        <td><img src={detail.url} style={{ width: "50px" }} alt='' /></td>
                                                                        <td>
                                                                            <div className='d-flex justify-content-start flex-column'>
                                                                                {detail.userType}
                                                                            </div>
                                                                        </td>
                                                                        <td>{detail.startDate}</td>
                                                                        <td>{detail.endDate}</td>
                                                                        <td>{detail.webUrl}</td>
                                                                    </tr>
                                                                )}
                                                            </Draggable>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </table>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>

                    <button type="button" className="btn btn-primary" onClick={ListSave}>
                        Save
                    </button>

                </Modal.Footer>
            </Modal>

            {/* perticular Banner details */}
            <Modal show={openModal} onHide={() => setOpenList(true)} className='modal-lg'>
                <Modal.Header>

                    <Modal.Title>Banner</Modal.Title>
                    <div
                        className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => setOpenModal(false)}
                    >
                        <KTSVG
                            path="/media/icons/duotune/arrows/arr061.svg"
                            className="svg-icon svg-icon-2x"
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='card-body py-3'>
                        <div className='table-responsive'>
                            <img src={perticularBanner?.url} alt='' width='250px' height='200px' />
                            <br />
                            <span>UserType : <p>{perticularBanner?.userType}</p></span>
                            <span>WebUrl :<p>{perticularBanner?.webUrl ? perticularBanner?.webUrl : "null"}</p></span>
                            <span>startDate :<p>{perticularBanner?.startDate}</p></span>
                            <span>endDate :<p>{perticularBanner?.endDate}</p></span>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </div >
    )
}
export default BannerNotification