export const dataStyle: any = {

    control: (provided: any) => ({
        ...provided,
        'minHeight': 50,
        'zIndex': 9999,
        'fontSize': '14px',
        'background': '#fff, !important',
        '&:focus': {
            border: '1px solid #d8d8d8',
        },
    }),
    multiValue: (styles: any) => {
        return {
            ...styles,
            padding: 4,
            backgroundColor: '#0073ae!important',
            borderRadius: '50px',
        };
    },
    multiValueLabel: (styles: any) => ({
        ...styles,
        color: '#fff',
    }),
    multiValueRemove: (styles: any) => ({
        ...styles,
        'color': '#0073ae!important',
        'borderRadius': '50px',
        'margin': 3,
        'backgroundColor': 'rgba(255, 255, 255, 0.7)',
        ':hover': {
            backgroundColor: '#fff',
        },
    }),
    indicatorSeparator: () => { },
    dropdownIndicator: (defaultStyles: any) => ({
        ...defaultStyles,
        '& svg': { display: 'none' },
    }),
};
