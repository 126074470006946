import React, { useEffect, useState } from 'react'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import Pagination from '../../../components/Pagination'
import { read, utils } from 'xlsx';
import CallFor from '../../../routing/CallFor';
import Loading from '../components/Loading';
const plandetailBreadCrumbs: Array<PageLink> = [
    {
        title: 'Mis Report',
        path: '#',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]
const userInitialValue = {
    contactNumber: "",
    contacts: "",
    contactNumber_error: "",
    contacts_error: "",
}

const MisReport: React.FC = () => {
    const [details, setDetails] = useState<Object[]>([]);
    const [userInputData, setUserInputData] = useState<typeof userInitialValue>(userInitialValue)
    const [page, setPage] = useState<number>(0);
    const [totalPageNo, setTotalPageNo] = useState<number>(0);
    const [searchBy, setSearchBy] = useState<string>('file');
    const [loading, setLoading] = useState<Boolean>(false);
    useEffect(() => {
        let subscribe = true;
        if (subscribe) {
            searchHandler();
        }
        return () => {
            subscribe = false;
        }
    }, [page])

    const getUserSearchData = async (data: any) => {
        if (validationHandler()) {
            try {
                const apiResponse = await CallFor('api/admin/v1/mis/users', 'POST', JSON.stringify({ mobileNoList: data, request: page }), 'Auth')
                const jsonResponse = await apiResponse.json();
                if (jsonResponse?.content.length === 0) {
                    setPage(0);
                    setTotalPageNo(0);
                }
                setDetails(jsonResponse.content);
                setTotalPageNo(Number(jsonResponse.pages))
            } catch (error) {
                console.log(error);
            }
        }
    }

    const validationHandler = () => {
        if (userInputData?.contacts[0]?.length < 0 && searchBy === "") {
            setUserInputData((prev: any) => ({ ...prev, contactNumber_error: '' }))
            return false;
        }
        else if (searchBy === 'contact') {
            if (userInputData?.contacts[0] === "") {
                setUserInputData((prev: any) => ({ ...prev, contactNumber_error: 'Please Enter Contact No' }))
                return false
            }
            else if (userInputData?.contacts[0]?.length < 10 || userInputData?.contacts[0]?.length > 10) {
                setUserInputData((prev: any) => ({ ...prev, contactNumber_error: 'Please Enter Valid Length Of Contact No' }))
                return false
            }
        }
        return true;
    }
    const searchHandler = () => {
        if (searchBy === 'file') {
            getUserSearchData(userInputData?.contacts)

        } else if (validationHandler()) {
            getUserSearchData(userInputData?.contacts)
        }
    }

    const dataChangeHandler = (e: any) => {
        if (e.target.type === 'file') {
            setSearchBy('file')
            if (e.target.files.length > 0) {
                const file = e.target.files[0]
                const reader = new FileReader();
                //File reader
                reader.onload = (event) => {
                    const workbook = read(event!.target!.result);
                    const sheets = workbook.SheetNames;
                    if (sheets.length) {
                        const rows = utils.sheet_to_json(workbook.Sheets[sheets[0]]);
                        if (rows.length > 0) {
                            let hasContactNo = false;
                            rows.forEach((element: any) => {
                                hasContactNo = "contact" in element
                            })
                            if (hasContactNo) {
                                let contactList: any = [];
                                rows.forEach((el: any) => {
                                    contactList.push(el.contact)
                                })
                                setUserInputData((prev: any) => ({ ...prev, [e.target.name]: contactList, [`${e.target.name}_error`]: "" }))
                            } else {
                                setUserInputData((prev: any) => ({ ...prev, contacts_error: "File hasn't contact Heading.." }))
                            }
                        } else {
                            setUserInputData((prev: any) => ({ ...prev, contacts_error: "File is empty" }))
                        }
                    }
                }
                // reading file
                reader.readAsArrayBuffer(file);
            }
        }
        else {
            setSearchBy('contact')
            setUserInputData((prev: any) => ({ ...prev, contacts: [e.target.value], [`${e.target.name}_error`]: "" }))
        }
        setUserInputData((prev: any) => ({ ...prev, contacNumber_error: '' }))
        setUserInputData((prev: any) => ({ ...prev, contacts_error: '' }))
    }
    const downloadExcelSheet = async () => {
        try {
            setLoading(true);
            const response = await CallFor('api/admin/v1/mis/users/excel', 'POST', JSON.stringify(userInputData?.contacts), 'Auth')
            const fileName: any = `User_Contact_Payment_Details_${Date.now()}.xlsx`
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();
        }
        catch (error) {
            console.log(error)
        }
        setLoading(false);
    }

    return (
        <div>
            <PageTitle breadcrumbs={plandetailBreadCrumbs}>Mis Report</PageTitle>
            <div className='card mb-5 p-5'>
                <div className='row'>
                    <div className="mb-3 col-lg-3">
                        <label className="form-label">Contact Number</label>
                        <input
                            onChange={dataChangeHandler}
                            name="contactNumber"
                            type="text"
                            className={`form-control ${userInputData.contactNumber_error === "" ? '' : 'is-invalid'}`}
                            placeholder="Search Contact Number"
                        />
                        <p className='errormsg' >{userInputData.contactNumber_error}</p>
                    </div>
                    <h2>OR</h2>
                    <div className="col-lg-3">
                        <label className='form-label'>Upload Excel sheet or CSV file</label>
                        <input
                            // disabled={true}
                            onChange={dataChangeHandler}
                            name="contacts"
                            type="file"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            className={`form-control ${userInputData?.contacts_error === "" ? '' : 'is-invalid'}`}
                            placeholder="Search Contact Number"
                        />
                        <p className='errormsg' >{userInputData?.contacts_error}</p>
                    </div>
                    <div className="mb-3 col-lg-3 mt-8">
                        <button
                            type='submit'
                            className='btn btn-primary'
                            onClick={searchHandler}
                        >Submit</button>
                    </div>
                </div>
                {details?.length > 0 ?
                    <div className="mb-3 col-lg-12 text-lg-center">
                        <button onClick={downloadExcelSheet} className="btn btn-primary clear-btn ms-0">Download Excel Sheet</button>
                    </div> : null
                }
            </div>
            {loading ? <Loading /> :
                <div className='card mb-5'>
                    {/* begin::Header */}
                    <div className='card-header border-0 pt-5'>
                        <h3 className='card-title align-items-start flex-column'>
                            <span className='card-label fw-bold fs-3 mb-1'> MIS Search Details</span>
                        </h3>
                    </div>
                    {/* end::Header */}
                    {/* begin::Body */}
                    <div className='card-body py-3'>
                        {/* {details.length > 0 ? */}
                        <div className='table-responsive'>
                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                <thead>
                                    <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                                        <th className='min-w-150px'>Date Of Registraion</th>
                                        <th className='min-w-150px'>Mobile Number</th>
                                        <th className='min-w-120px'>App Download</th>
                                        <th className='min-w-140px'>Profile Created &nbsp; &nbsp; (2 stage registration done) </th>
                                        <th className='min-w-120px'>User Profile Picture</th>
                                        <th className='min-w-120px'>Company Profile Picture</th>
                                        <th className='min-w-100px'>&gt;= 3 Products</th>
                                        <th className='min-w-100px'>Buy Post</th>
                                        <th className='min-w-100px'>Sell Post</th>
                                        <th className='min-w-100px'>Connections</th>
                                        <th className='min-w-100px'>Total Proportion</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {details?.map((item: any, key: number) => <tr key={key}>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                {(item?.createdOn?.split('T')[0]).split("-").reverse().join("-")}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                {item?.mobileNo}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                {item?.download ? "Yes" : "No"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                {item?.registration ? "Yes" : "No"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                {item?.userLogo ? "Yes" : "No"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                {item?.entityLogo ? "Yes" : "No"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                {item?.product ? "Yes" : "No"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                {item?.buyPost ? "Yes" : "No"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                {item?.sellPost ? "Yes" : "No"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                {item?.connection ? "Yes" : "No"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                {item?.proportionPercentage} %
                                            </div>
                                        </td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/* end::Table container */}
                    {
                        totalPageNo > 0 &&
                        <Pagination
                            totalCount={totalPageNo}  //total number of data
                            siblingCount={1} // number of extra elements around avtive page
                            currentPage={page} //current active page
                            onPageChange={setPage} //page change handler state
                            pageSize={10} // data per page
                        />}
                </div>
            }
        </div >
    )
}
export default MisReport