import React, { useEffect, useRef, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { KTSVG, } from '../../../../_metronic/helpers'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import Pagination from '../../../components/Pagination'
import CallFor from '../../../routing/CallFor'
import Accordion from './Accordion'
import { toast } from 'react-toastify'
import Select from 'react-select';
import { yupResolver } from '@hookform/resolvers/yup'
import { CreatePlanValidation } from './CreatePlanValidation'
import { dataStyle } from './dataStyle'
import { convertToJsonResponse } from '../../../../_metronic/layout/core/convertToJsonResponse'

const employeeBreadCrumbs: Array<PageLink> = [
    {
        title: 'Plan',
        path: '#',
        isSeparator: false,
        isActive: false,
    }
]

const user = [
    { value: '1', label: 'WEEK' },
    { value: '2', label: 'MONTH' },
    { value: '3', label: 'YEAR' },
]

const planDurationJson = [
    { value: 1, label: 'YEAR' },
    { value: 2, label: 'MONTH' },
    { value: 3, label: 'WEEK' },
    { value: 4, label: 'DAY' }
]

const Plan = () => {
    const modalRef = useRef<HTMLDivElement>(null);
    const [designation, setDesignation] = useState<any[]>([]);
    const [page, setPage] = useState<number>(0);
    const [totalPageNo, setTotalPageNo] = useState<number>(0);
    const [deleteId, setDeleteId] = useState<any>()
    const deleteModalRef = useRef<HTMLDivElement>(null);
    const [featureLists, setFeatureList] = useState<any[]>([])
    const tomorrowDate = new Date();
    const [premiumUnitTypes, setpremiumUnitTypes] = useState(false);
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);
    const newone = tomorrowDate.toISOString().split("T")[0]
    const [account, setAccount] = React.useState({
        startDate: "",
        endDate: "",
        reason: "",
        leavetype: "",
    });

    const handleChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        setAccount({ ...account, [name]: value });
    }

    useEffect(() => {
        listingPlanApi();
    }, [page])

    useEffect(() => {
        listingFeatureApi()
        appendFields();
    }, [])

    // -------------------------- Listing Feature Api start ------------------------

    const listingFeatureApi = async () => {

        try {
            const response = await CallFor('api/admin/v1/pay/plans/features/listings', 'GET', null, 'Auth')

            if (response.status === 200) {
                // const res = await response.json();
                const redData = await convertToJsonResponse(response)
                const finalFeatureList = redData?.data?.map((item: any) => ({ ...item, featureId: item?.featureId?.toString() }))
                setFeatureList(finalFeatureList);
            }

        } catch (error) {
            console.log(error)
        }
    }

    // -------------------------- Listing Plan Api start ------------------------

    const listingPlanApi = async () => {
        try {
            const response = await CallFor('api/admin/v1/pay/plans/listings', 'POST', JSON.stringify({ page: page }), 'Auth')
            if (response.status === 200) {
                const res = await convertToJsonResponse(response)
                if (res?.length === 0) {
                    setPage(0);
                    setTotalPageNo(0);
                }
                setTotalPageNo(Number(res.data.page))
                setDesignation(res.data.content);
            }

        } catch (error) {
            console.log(error)
        }
    }

    // -------------------------- Delete Lisiting ------------------------

    const deletePlanDetail = async () => {
        const response = await CallFor('api/admin/v1/pay/plans/premiumplan/deletepremiumplan/' + deleteId?.toString(), 'DELETE', null, 'Auth')
        if (response.status === 200) {
            setDesignation(designation.filter((item) => item.planId !== deleteId));
            toast.success('Successfully Delete')
        }
        closeKpPlanDeleteModal();
    }

    // ------------------------- Form Submit Api ------------------

    const submitPlanHandler = async (planSubmitData: any) => {
        const jsondata = {
            premiumPlanName: planSubmitData.premiumPlanName,
            startDate: `${convertDateFormat(planSubmitData.startDate)} ${planSubmitData.startTime}`,
            endDate: `${convertDateFormat(planSubmitData.endDate)} ${planSubmitData.timeEnd}`,
            planType: planSubmitData?.planType ? 1 : 0,
            planAmount: Number(planSubmitData?.planAmount),
            durationType: planSubmitData?.planDurationType?.value,
            duration: planSubmitData?.planDuration,
            features: planSubmitData?.plan?.map((item: any) => ({ featureId: item?.featureName?.value?.toString(), limit: Number(item?.limit), premiumUnitTypes: Number(item.premiumUnitTypes.value) }))
        }
        try {
            const response = await CallFor('api/admin/v1/pay/plans', 'POST', JSON.stringify(jsondata), 'Auth');

            if (response?.ok) {
                toast.success('Plan Successfully created');
                listingPlanApi();
                closeEmployeeModal();

            } else if (response.status === 400) {
                toast.error('Free plan already created');
                closeEmployeeModal();
            }
        } catch (error) {
            console.log(error)
            toast.error('Free plan already created');
        }

    }

    // ------------------------- Hook From Controller ------------------
    const {
        control,
        handleSubmit, reset, watch, setValue,
        formState: { errors },
    }: any = useForm({
        resolver: yupResolver(CreatePlanValidation({ premiumUnitTypes })),
        mode: "onChange",
    });

    const isChecked = watch('planType')
    useEffect(() => {
        if (!isChecked) {
            setValue('planAmount', "");
        }
    }, [isChecked]);
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'plan'
    })
    useEffect(() => {
        if (watch('planType') === false) {
            setpremiumUnitTypes(false);
        } else {
            setpremiumUnitTypes(true);
        }
    }, [watch('planType'), premiumUnitTypes])

    const appendFields = () => {
        append({
            featureName: '',
            limit: '',
            premiumUnitTypes: '',
        })
    }

    // ----------- Remove Sub Plan Detail ----------

    const removeFields = (index: number) => {
        remove(index)
    }

    const addEmployeeModal = () => {
        const divElement = document.createElement("div")
        divElement.classList.add("modal-backdrop")
        divElement.classList.add("fade")
        divElement.classList.add("show")
        const body = document.getElementById("kt_app_body")
        body?.appendChild(divElement)
        modalRef.current!.style.display = "block"
        modalRef.current!.style.paddingLeft = "0px"
        modalRef.current!.classList.add("show")
        modalRef.current!.setAttribute("aria-modal", "true")
        modalRef.current!.setAttribute("role", "dialog")
        modalRef.current!.removeAttribute("aria-hidden")
    }
    const convertDateFormat = (inputDate: any) => {
        const dateParts = inputDate.split("-");
        const day = dateParts[2];
        const month = dateParts[1];
        const year = dateParts[0];
        return `${day}-${month}-${year}`;
    };
    const closeEmployeeModal = () => {
        modalRef.current!.style!.display = "none"
        modalRef.current!.classList.remove("show")
        modalRef.current!.removeAttribute("aria-modal")
        modalRef.current!.removeAttribute("role")
        modalRef.current!.setAttribute("aria-hidden", "true");
        const divElement = document.getElementsByClassName('modal-backdrop')
        divElement[0].remove()
        remove();
        reset();
        appendFields();

    }

    const deletePlanModal = (planId: any) => {
        setDeleteId(planId)
        const divElement = document.createElement("div")
        divElement.classList.add("modal-backdrop")
        divElement.classList.add("fade")
        divElement.classList.add("show")
        const body = document.getElementById("kt_app_body")
        body?.appendChild(divElement)
        deleteModalRef.current!.style.display = "block"
        deleteModalRef.current!.style.paddingLeft = "0px"
        deleteModalRef.current!.classList.add("show")
        deleteModalRef.current!.setAttribute("aria-modal", "true")
        deleteModalRef.current!.setAttribute("role", "dialog")
        deleteModalRef.current!.removeAttribute("aria-hidden")
    }

    const closeKpPlanDeleteModal = () => {
        deleteModalRef.current!.style!.display = "none"
        deleteModalRef.current!.classList.remove("show")
        deleteModalRef.current!.removeAttribute("aria-modal")
        deleteModalRef.current!.removeAttribute("role")
        deleteModalRef.current!.setAttribute("aria-hidden", "true");
        const divElement = document.getElementsByClassName('modal-backdrop')
        divElement[0].remove()
    }

    // const options = featureLists.map(item => ({
    //     value: item.featureId,
    //     label: <pre>{item.featureName}</pre> // Use <pre> tag here
    // }));

    return (
        <div>
            <PageTitle breadcrumbs={employeeBreadCrumbs}>Plan Details</PageTitle>
            <div className='card mb-5'>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>Plan Details</span>
                    </h3>
                    <div
                        className='card-toolbar'
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        data-bs-trigger='hover'
                        title='Click to add a user'
                    >
                        <button type="button" className="btn btn-primary btn-sm" data-bs-target="#kt_modal_1" onClick={addEmployeeModal}>Add Plan</button>
                    </div>
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                                    <div className='ml-2'>
                                        <th className='min-w-150px'>Plan Name</th>
                                    </div>
                                    <th className='min-w-150px'>Start Date</th>
                                    <th className='min-w-120px'>End Date</th>
                                    <th className='min-w-150px'>Plan Type</th>
                                    <th className='min-w-150px'>Amount</th>
                                    <th className='min-w-120px'>Action</th>
                                </tr>
                            </thead>
                            {
                                designation.length > 0
                                    ?
                                    designation.map((data, index) => {
                                        return (
                                            <tbody key={index}>
                                                <tr>
                                                    <td className='align-top'>
                                                        <span className='head-plan'>{data.planName}</span>
                                                    </td>
                                                    <td className='align-top pe-3'>
                                                        {data.startDate}
                                                    </td>
                                                    <td className='align-top'>
                                                        {data.endDate}
                                                    </td>
                                                    <td className='align-top'>
                                                        {data.planType}
                                                    </td>
                                                    <td className='align-top'>
                                                        {data.planAmount}
                                                    </td>
                                                    <td className='align-top w-20px'>
                                                        <a
                                                            href='#'
                                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                                            onClick={() => { deletePlanModal(data.planId) }}
                                                        >
                                                            <KTSVG
                                                                path='/media/icons/duotune/general/gen027.svg'
                                                                className='svg-icon-3'
                                                            />
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={5} className='position-relative'>
                                                        <span className="table-bordered">
                                                            <Accordion>
                                                                <table className="table table-striped table-hover">
                                                                    <thead>
                                                                        <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                                                                            <th className='text-dark fs-7'>Feature Name</th>
                                                                            <th className='pe-2 text-dark fs-7'>Limit</th>
                                                                            <th className='pe-2 text-dark fs-7'>Unit</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className='text-gray-600'>
                                                                        {
                                                                            data.features.map((item: any) => (
                                                                                <tr data-kt-docs-datatable-subtable="subtable_template">
                                                                                    <td>
                                                                                        <pre className="text-dark fs-7">{item.featureName}</pre>
                                                                                    </td>

                                                                                    <td>
                                                                                        <div className="text-dark fs-7">{item.limit === 0 ? "Unlimited" : item.limit}</div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="text-dark fs-7">{item.unit}</div>
                                                                                    </td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </Accordion>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        )
                                    })
                                    :
                                    <tbody><tr> <td colSpan={9}> <h6 className='text-center bg-gray-200i p-4 text-gray-800 mb-0 mt-6'>Data Not Found</h6> </td> </tr> </tbody>
                            }
                        </table>
                    </div>
                </div>
            </div>

            {/* add plan modal */}
            <div className="modal fade" ref={modalRef} tabIndex={-1} id="kt_modal_1">
                <div className="modal-dialog">
                    <div className="modal-content w-650px ">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Plan</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <a
                                    href='#'
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                    onClick={closeEmployeeModal}
                                >
                                    <KTSVG
                                        path="/media/icons/duotune/arrows/arr061.svg"
                                        className="svg-icon svg-icon-2x"
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="modal-body modal-custom-height">
                            <form id="a-form" onSubmit={handleSubmit(submitPlanHandler)}>
                                <div className='row'>
                                    <div className="mb-3 col-lg-12">
                                        <label className="form-label">Premium Plan Name </label>
                                        <Controller
                                            name="premiumPlanName"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <>
                                                    <input
                                                        type="text"
                                                        placeholder="Premium Plan Name"
                                                        {...field}
                                                        className={`form-control ${errors.premiumPlanName ? 'border border-danger' : ""}`}
                                                    />
                                                    {errors.premiumPlanName && (
                                                        <p className="mt-1 absolute text-danger text-xs">
                                                            {errors?.premiumPlanName?.message}
                                                        </p>
                                                    )}

                                                </>
                                            )}

                                        />
                                    </div>
                                    <div className="col-lg-6 mt-2">
                                        <label className="form-label">Plan Start Date</label>
                                        <Controller
                                            name="startDate"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <>
                                                    <input
                                                        type="date"
                                                        id="datePickerId"
                                                        className={`form-control ${errors.startDate ? 'border border-danger' : ""}`}
                                                        placeholder="Start Date"
                                                        min={newone}
                                                        {...field}
                                                    />
                                                    {errors.startDate && (
                                                        <p className="mt-1 absolute text-danger text-xs">
                                                            {errors?.startDate?.message}
                                                        </p>
                                                    )}
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className="col-lg-6 mt-10">
                                        <Controller
                                            name="startTime"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <>
                                                    <input
                                                        type="time"
                                                        className={`form-control ${errors.startTime ? 'border border-danger' : ""}`}
                                                        aria-invalid={errors.startTime ? errors.startTime.message : ""}
                                                        {...field}
                                                    />
                                                    {errors.startTime && (
                                                        <p className="mt-1 absolute text-danger text-xs">
                                                            Time is Required
                                                        </p>
                                                    )}
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className="col-lg-6 mt-4">
                                        <label className="form-label">Plan End Date</label>
                                        <Controller
                                            name="endDate"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <>
                                                    <input
                                                        type="date"
                                                        className={`form-control ${errors.endDate ? 'border border-danger' : ""}`}
                                                        placeholder="End Date"
                                                        {...field}
                                                        min={watch('startDate') ? new Date(watch('startDate')).toISOString().split("T")[0] : ""}
                                                    />
                                                    {errors.endDate && (
                                                        <p className="mt-1 absolute text-danger text-xs">
                                                            {errors?.endDate?.message}
                                                        </p>
                                                    )}
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className="col-lg-6 mt-12">
                                        <Controller
                                            name="timeEnd"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <>
                                                    <input
                                                        type="time"
                                                        className={`form-control ${errors.timeEnd ? 'border border-danger' : ""}`}
                                                        aria-invalid={errors.timeEnd ? errors.timeEnd.message : ""}
                                                        {...field}
                                                    />
                                                    {errors.timeEnd && (
                                                        <p className="mt-1 absolute text-danger text-xs">
                                                            Time is Required
                                                        </p>
                                                    )}
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className="col-lg-6 mt-5">
                                        <label className='form-label'>Premium Types</label>
                                        <div className="form-check form-switch form-check-custom form-check-solid mt-2">
                                            <Controller
                                                name="planType"
                                                control={control}
                                                defaultValue={false}
                                                render={({ field }) => (
                                                    <>
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="flexSwitchChecked"
                                                            checked={field?.value}

                                                            aria-invalid={errors?.planType ? errors?.planType.message : ""}
                                                            {...field}
                                                        />
                                                        <label className="form-check-label" htmlFor="flexSwitchChecked">
                                                            {field?.value ? "Paid" : "Free"}
                                                        </label>
                                                    </>
                                                )}
                                            />
                                        </div>
                                        {errors?.planType && (
                                            <p className="mt-1 absolute text-danger text-xs">
                                                {errors?.planType?.message}
                                            </p>
                                        )}

                                    </div>
                                    <div className="col-lg-6 mt-5">
                                        <label className="form-label">Amount</label>

                                        <Controller
                                            name="planAmount"
                                            control={control}
                                            defaultValue={isChecked ? '' : '0'}
                                            render={({ field }) => (

                                                <>
                                                    <input
                                                        type="number"
                                                        disabled={!isChecked}
                                                        className={`form-control ${errors.planAmount ? 'border border-danger' : ""}`}
                                                        placeholder={!isChecked ? "0" : "Amount"}
                                                        {...field}
                                                    />
                                                </>
                                            )}
                                        />

                                        {errors.planAmount && (
                                            <p className="mt-1 absolute text-danger text-xs">
                                                {errors?.planAmount?.message}
                                            </p>
                                        )}

                                    </div>

                                    <div className="col-lg-6 mt-5">
                                        <label className='form-label'>Plan Duration</label>
                                        <div className="form-check form-switch form-check-custom form-check-solid mt-2">
                                            <Controller
                                                name={`planDuration`}
                                                control={control}
                                                defaultValue={""}
                                                render={({ field }) => (
                                                    <>
                                                        <input
                                                            type="number"
                                                            className={`form-control h-50px ${errors?.planDuration ? 'border border-danger' : ""}`}
                                                            placeholder="Limit"
                                                            {...field}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </div>
                                        {errors?.planDuration
                                            && (
                                                <p className="mt-1 mb-1 absolute text-danger text-xs">
                                                    {errors?.planDuration?.message}
                                                </p>
                                            )}

                                    </div>
                                    <div className="col-lg-6 mt-5">
                                        <label className="form-label">Plan Duration Type</label>

                                        <div className='w-100'>
                                            <Controller
                                                name={`planDurationType`}
                                                control={control}
                                                defaultValue={""}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        styles={dataStyle}
                                                        placeholder="Duration"
                                                        options={planDurationJson.map(item => ({ value: item.value, label: item.label }))}
                                                        className={`h-12 ${errors?.planDurationType ? "error-border" : ""}`}
                                                    />
                                                )}
                                            />
                                            {errors?.planDurationType
                                                && (
                                                    <p className="mt-1 mb-1 absolute text-danger text-xs">
                                                        {errors?.planDurationType?.message}
                                                    </p>
                                                )}
                                        </div>
                                    </div>

                                    {/* ---------------- feature filled ------------------ */}

                                    <h3 className='mt-7'>Feature Detail :- </h3>
                                    <div className="col-lg-6 mt-3">
                                        <label className="form-label">Feature Name</label>
                                    </div>
                                    <div className="col-lg-2 mt-3">
                                        <label className="form-label">Limit</label>
                                    </div>
                                    <div className="col-lg-3 mt-3">
                                        <label className='form-label'>Duration</label>
                                    </div>


                                    {
                                        fields?.map((_: any, index: any) => {
                                            return (

                                                <React.Fragment key={index}>
                                                    <div className="col-lg-6 customheightscroll pb-2" >

                                                        <Controller
                                                            name={`plan[${index}].featureName`}
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    isClearable
                                                                    isSearchable={true}
                                                                    styles={dataStyle}
                                                                    menuPlacement="bottom"
                                                                    placeholder='Feature Name'
                                                                    className={`h-12 ${errors?.plan && errors?.plan[index]?.featureName ? "error-border" : ""
                                                                        }`}
                                                                    options={featureLists.map(item => ({ value: item.featureId, label: item.featureName }))}
                                                                // options={options}
                                                                />
                                                            )}
                                                        />


                                                        {errors?.plan && errors?.plan[index]?.featureName
                                                            && (
                                                                <p className="mt-1 mb-1 absolute text-danger text-xs">
                                                                    {errors?.plan[index]?.featureName?.message}
                                                                </p>
                                                            )}


                                                    </div>
                                                    <div className="col-lg-2">
                                                        <Controller
                                                            name={`plan[${index}].limit`}
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <>
                                                                    <input
                                                                        type="number"
                                                                        className={`form-control h-50px ${errors?.plan && errors?.plan[index]?.limit ? 'border border-danger' : ""}`}
                                                                        placeholder="Limit"
                                                                        {...field}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors?.plan && errors?.plan[index]?.limit
                                                            && (
                                                                <p className="mt-1 mb-1 absolute text-danger text-xs">
                                                                    {errors?.plan[index]?.limit?.message}
                                                                </p>
                                                            )}
                                                    </div>
                                                    <div className="col-lg-4 d-flex">
                                                        <div className='w-100'>
                                                            <Controller
                                                                name={`plan[${index}].premiumUnitTypes`}
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        isClearable
                                                                        styles={dataStyle}
                                                                        menuPlacement="bottom"
                                                                        isSearchable={true}
                                                                        placeholder="Duration"
                                                                        options={user}
                                                                        className={`h-12 ${errors?.plan && errors?.plan[index]?.premiumUnitTypes ? "error-border" : ""}`}
                                                                        {...field}
                                                                    />
                                                                )}
                                                            />
                                                            {errors?.plan && errors?.plan[index]?.premiumUnitTypes
                                                                && (
                                                                    <p className="mt-1 mb-1 absolute text-danger text-xs">
                                                                        {errors?.plan[index]?.premiumUnitTypes?.message}
                                                                    </p>
                                                                )}
                                                        </div>
                                                        <div>
                                                            {(fields?.length > 1) ? <a
                                                                href='#'
                                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm pt-4'
                                                                onClick={() => removeFields(index)}
                                                            >
                                                                <KTSVG
                                                                    path='/media/icons/duotune/general/gen027.svg'
                                                                    className='svg-icon-3'
                                                                />
                                                            </a> : ""}
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                    {
                                        errors?.plan?.message && <div className='mt-3'>
                                            <p><b>ERROR :-</b> <span className='fs-base' style={{ color: "red" }}>{errors?.plan?.message}</span></p>
                                        </div>
                                    }


                                    <div className='mt-3'>
                                        <p><b>Note :-</b> <span className='fs-lg-7' style={{ color: "red" }}>0 Means Unlimited Limit</span></p>
                                    </div>

                                    <div className="modal-footer p-0 w-100 border-0 mt-2 z">

                                        <span className="btn btn-outline-success bg-primary text-white" onClick={appendFields}>Add Feature</span>
                                        <button type="submit" className="btn btn-primary">
                                            Create Plan
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div >

            {/* ---------------------   Delete Modal ------------------------------- */}

            < div className="modal fade" ref={deleteModalRef} tabIndex={- 1} id="kt_modal_1" >
                <div className="modal-dialog modelLower">
                    <div className="modal-content">
                        <div className="modal-header p-4">
                            <h5 className="modal-title">Plan Delete</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                aria-label="Close"
                            >
                                <a
                                    href='#'
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                    onClick={closeKpPlanDeleteModal}
                                >
                                    <KTSVG
                                        path="/media/icons/duotune/arrows/arr061.svg"
                                        className="svg-icon svg-icon-2x"
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="modal-body">
                            <p className='inactiveName'>Are you sure you want to delete this Plan ?</p>
                        </div>
                        <div className='modal-footer py-1 px-3 ms-auto w-100 deleteButton'>
                            <button className="btn btn-primary btn-sm" onClick={deletePlanDetail}>Yes</button>
                            <button className="btn btn-secondary btn-sm" onClick={closeKpPlanDeleteModal}>No</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* ------------------------ Pagination ------------------------  */}
            {
                totalPageNo > 0 &&
                <Pagination
                    totalCount={totalPageNo * 10} //total number of data
                    siblingCount={1} // number of extra elements around avtive page
                    currentPage={page} //current active page
                    onPageChange={setPage} //page change handler state
                    pageSize={10} // data per page
                />
            }
        </div >
    )
}
export default Plan
