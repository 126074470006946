import GlobalProperties from "../../../routing/GlobalProperties";
import { UrlType } from "../../../routing/UrlType";
const getToken = () => {
    const user: any = localStorage.getItem('kt-auth-react-v') || null;
    const data: any = JSON.parse(user)
    return data.token;
}
// import CallFor from '../../../routing/CallFor';
export const mediaUploader = async (media: any[]) => {
    if (media?.length > 0) {
        const date = new Date()
        const mediaNames = media?.map((media: any) => `${date.getTime()}_${media?.name}`)
        let url = '';
        if (GlobalProperties.environment === UrlType.LIVE) {
            url = GlobalProperties.urlParam;
        } else if (GlobalProperties.environment === UrlType.TEST) {
            url = 'http://192.168.7.41:7777/';
        } else {
            url = GlobalProperties.localUrlParam;
        }
        console.log(getToken(), 'tokennnnn')
        const res = await fetch(`${url}api/v1/uploads/url/PRODUCT`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${GlobalProperties.environment === UrlType.LIVE ? getToken() : 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxMDcyNDAwODQ1Nzg0MjE5NjQ4Iiwicm9sZXMiOiJST0xFX1VTRVIiLCJpYXQiOjE2ODc3NjMzNjQsImV4cCI6Mzc2ODc3NjMzNjR9.9dkMeQe0JcFFTfJM8EhCj2jBKewsCvDQubKcf3cxX5Y'}`
            },
            body: JSON.stringify(mediaNames)
        });
        // const res: any = await CallFor('api/v1/uploads/PAGE', 'POST', JSON.stringify(mediaNames), 'Auth')
        const presignedUrls = await res.json()

        let i = 0
        const finalUrl = []
        for (i; i < presignedUrls?.length; i++) {
            const resData = await fetch(presignedUrls[i].url, {
                body: media[i],
                method: "PUT"
            })

            if (resData?.ok) {
                finalUrl.push(presignedUrls[i]?.url?.split("?")[0])
            }
            else {
                break;
            }
        }

        if (i !== presignedUrls?.length) {

            return { success: false, data: [] }
        } else if (i === 0) {
            return { success: false, data: [] }
        } else {
            return { success: true, data: finalUrl }
        }
    }
}