import { PageTitle } from '../../../../_metronic/layout/core'
import { KTSVG } from '../../../../_metronic/helpers'
import { Controller, useForm } from 'react-hook-form'
import Select from 'react-select';
import { handleError } from '../../../routing/ExceptionHandler';
import { useEffect, useRef, useState } from 'react';
import CallFor from '../../../routing/CallFor';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap'

const Audience = [
  { value: '0', label: 'All' },
  { value: '1', label: 'Half Registration' },
  { value: '2', label: 'Full Registration without Catalogue' },
  { value: '3', label: 'Full Registration without Connections' },
  { value: '4', label: 'Full Registration - No buy requirement' },
  { value: '5', label: 'Not updated Address in company profle' }
]

const app = [
  { value: '0', label: 'All' },
  { value: '1', label: 'Android' },
  { value: '2', label: 'Ios' }
]

const PushNotifcation = () => {

  const d = new Date();
  const t = d.getTime();
  const h = d.getHours();
  const m = d.getMinutes();
  const datePicker = new Date().toISOString().split("T")[0];
  const currentDate = new Date().toISOString().split("T")[0];
  const time = new Date().toLocaleTimeString().split(" ")[0];
  const sceduleTime = time?.slice(0, 5);
  const modalRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [open, setOpen] = useState(false)
  const [formData, setFormData] = useState<any>({
    title: "",
    title_error: '',
    description: "",
    description_error: '',
    appType: "",
    appType_error: '',
    audience: "",
    audience_error: '',
    date: "",
    date_error: '',
    time: "",
    time_error: '',
    logofile: "",
    logofile_error: ""
  });
  const { control } = useForm();

  const handleChange = (event: { target: { name: any; value: any; }; }) => {
    const validationField = [event.target.name] + '_error'
    setFormData({ ...formData, [event.target.name]: event.target.value, [validationField]: '' })
  }
  const appHandler = (event: any) => {
    if (event !== null && event.value !== undefined) {
      setFormData({ ...formData, appType: event.value, appType_error: '' })
    }else{
      setFormData({ ...formData, appType: '', appType_error: '' })
    }
  }

  const audienceHandler = (event: any) => {
    if (event !== null && event.value !== undefined) {
    setFormData({ ...formData, audience: event.value, audience_error: '' })
    }else{
      setFormData({ ...formData, audience: '', audience_error: '' })
    }
  }
  const handleLogoChange = (event: { target: { files: any; }; }) => {
    if (event.target.files.length > 0) {
      if (!event.target.files[0].name.match(/\.(JPG|JPEG|PNG|jpg|jpeg|png)$/)) {
        setIsValid(false);
        setError('ImageJPG/JPEG/JFIF/PNG file only')
      } else if (event.target.files[0].size > 307200) {
        setIsValid(false);
        setError('Image upto 300 KB allowed')
      }
      else {
        setIsValid(true);
        setError('')
      }
    }
  }

  const submitHandler = async () => {
    const date = formData.date;
    const newdate = new Date(date);
    const convertedDate = newdate.toISOString().replaceAll("/", "-");
    const dat = convertedDate.slice(8, 10);
    const mont = convertedDate.slice(5, 7);
    const yea = convertedDate.slice(0, 4);
    const logoFile: any = document.getElementById('logoimg');
    const data = new FormData();
    data.append('img', logoFile.files[0])
    if (validationSchema()) {
      const jsonData = {
        title: formData.title,
        content: formData.description,
        type: Number(formData.audience),
        deviceType: Number(formData.appType),
        scheduleTime: `${dat}-${mont}-${yea} ${formData.time}`
      }
      data.append('dto', JSON.stringify(jsonData));
      const response = await CallFor('api/admin/v1/notifications/customs', 'POST', data, 'authWithoutContentType');
      if (response.status === 201) {
        setOpen(false)
        navigate('/Notification')
      }
      else if (response.status === 401) {
        navigate('/auth')
      }
    }
  }

  const validationSchema = () => {
    let isValid = true;
    if (formData?.title?.trim() === '') {
      setFormData((prev: any) => ({ ...prev, title_error: "Notification title is required" }))
      isValid = false
    } else if (formData?.title?.trim().length < 2) {
      setFormData((prev: any) => ({ ...prev, title_error: "Minimum 2 characters required" }))
      isValid = false
    } else if (formData?.title?.trim().length > 50) {
      setFormData((prev: any) => ({ ...prev, title_error: "Upto 50 characters allowed" }))
      isValid = false
    }
    if (formData?.description?.trim() === '') {
      setFormData((prev: any) => ({ ...prev, description_error: "Notification text is required" }))
      isValid = false
    } else if (formData?.description?.trim().length < 2) {
      setFormData((prev: any) => ({ ...prev, description_error: "Minimum 2 characters required" }))
      isValid = false
    } else if (formData?.description?.trim().length > 100) {
      setFormData((prev: any) => ({ ...prev, description_error: "Upto 100 characters allowed" }))
      isValid = false
    }
    if (formData?.appType?.trim() === '') {
      setFormData((prev: any) => ({ ...prev, appType_error: "Please select a Device Type" }))
      isValid = false
    }
    if (formData?.audience?.trim() === '') {
      setFormData((prev: any) => ({ ...prev, audience_error: "Select audience type" }))
      isValid = false
    }
    if (formData?.date === '') {
      setFormData((prev: any) => ({ ...prev, date_error: "select schedule date" }))
      isValid = false
    }
    if (formData?.time === '') {
      setFormData((prev: any) => ({ ...prev, time_error: "Select schedule time" }))
      isValid = false
    }
    if (formData.time?.slice(0, 2) < h) {
      if (formData.time?.slice(3, 6) < m) {
        if (formData.date === currentDate) {
          setFormData((prev: any) => ({ ...prev, time_error: "The schedule time is invalid" }))
          isValid = false
        }
      }
    }
    return isValid
  }

  const reviewModal = () => {
    if (validationSchema() && isValid) {
      // setFormData({
      //   time_error:"",
      //   date_error:"",
      //   audience_error:"",
      //   appType_error:"",
      //   description_error:"",
      //   title_error:""
      // })
      setOpen(true)
    }
  }
  return (
    <div>

      <PageTitle >Create Campaign</PageTitle>

      <div className='card mb-5 p-5'>
        <div className='row'>

          <div className='col-lg-6 offset-lg-3' >

            <div className="mb-6 col-lg-12">
              <label className="form-label">Notifcation Title*</label>
              <input
                name='title'
                type="text"
                className={`form-control ${formData?.title_error === "" ? '' : 'is-invalid'}`}
                placeholder="Notifcation Title"
                value={formData.title}
                onChange={handleChange}
              />
              <p id='error_1' className='errormsg' >{formData?.title_error}</p>
            </div>

            <div className="mb-6 col-lg-12">
              <label className="form-label">Notifcation Description*</label>
              <input
                name='description'
                type="text"
                className={`form-control ${formData?.description_error === "" ? '' : 'is-invalid'}`}
                value={formData.description}
                placeholder="Notifcation Description"
                onChange={handleChange}
              />
              <p id='error_1' className='errormsg' >{formData?.description_error}</p>
            </div>

            <div className="mb-3 col-lg-12">
              <label className="form-label">Image / Logo </label>
              <input
                name='logofile'
                type="file"
                className={`form-control ${error === "" ? '' : 'is-invalid'}`}
                placeholder=". Image / Logo - Ur"
                accept="image/png, image/jpg, image/jpeg"
                onChange={handleLogoChange}
                id='logoimg'
              />
              <p id='error_1' className='errormsg' >{error}</p>
            </div>

            <div className="mb-3 col-lg-12">
              <label className='form-label'>App*</label>
              <Controller
                name='appType'
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    isClearable
                    isSearchable={true}
                    placeholder='Select Status'
                    className={formData.appType_error ? 'error-border' : ''}
                    value={app.filter(
                      (obj) => obj.value === formData.appType)}
                    onChange={appHandler}
                    id='status'
                    options={app}
                  />
                )}
              />
              <p id='error_1' className='errormsg' >{formData.appType_error}</p>

            </div>

            <div className="mb-3 col-lg-12">
              <label className='form-label'>Audience Selection*</label>
              <Controller
                name='audience'
                control={control}
                render={({ field }) => (
                  <Select

                    {...field}
                    isClearable
                    isSearchable={true}
                    placeholder='Select Status'
                    className={formData.audience_error ? 'error-border' : ''}
                    value={Audience.filter(
                      (obj) => obj.value === formData.audience
                    )}
                    onChange={audienceHandler}
                    id='audience'
                    options={Audience}
                  />
                )}
              />
              <p id='error_1' className='errormsg' >{formData.audience_error}</p>
            </div>
            <div className="mb-3 col-lg-12">
              <label className="form-label">Scheduling*</label>
              <div className='row'>
                <div className="mb-6 col-lg-6">
                  <input
                    name='date'
                    type="date"
                    id="datePickerId"
                    min={datePicker}
                    value={formData.date}
                    className={`form-control ${formData?.date_error === "" ? '' : 'is-invalid'}`}
                    onChange={handleChange}
                  />
                  <p id='error_1' className='errormsg' >{formData?.date_error}</p>
                </div>

                <div className="mb-6 col-lg-6">
                  <input
                    name='time'
                    type="time"
                    min={sceduleTime}
                    value={formData.time}
                    className={`form-control ${formData?.time_error === "" ? '' : 'is-invalid'}`}
                    onChange={handleChange}
                  />
                  <p id='error_1' className='errormsg' >{formData?.time_error}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-12  text-center">
              <button type='submit' className='btn btn-primary' onClick={reviewModal} >Review</button>
            </div>
          </div>
        </div>
      </div>

      <Modal show={open} onHide={() => setOpen(true)} >
        <Modal.Header>

          <Modal.Title>Review Message</Modal.Title>
          <div
            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => setOpen(false)}
          >
            <KTSVG
              path="/media/icons/duotune/arrows/arr061.svg"
              className="svg-icon svg-icon-2x"
            />
          </div>
        </Modal.Header>
        <Modal.Body>


          <div className="mb-3 col-lg-12">
            <label className="form-label">Notification Content</label>
            <div className="border-bottom border-gray-300">{formData.title}</div>
          </div>

          <div className="mb-3 col-lg-12 mt-4">
            <label className="form-label">Traget</label>
            <div className="border-bottom border-gray-300">
              {formData.appType === '0' ?
                'All' : formData.appType === '1' ? 'Android' :
                  'Ios'
              }
            </div>
          </div>

          <div className="mb-3 col-lg-12 mt-4">
            <label className="form-label">Scheduling</label>
            <div className="">{formData?.date} {formData?.time}</div>
          </div>


        </Modal.Body>
        <Modal.Footer>

          <button type="button" className="btn btn-secondary" onClick={() => setOpen(false)} >
            Cancel
          </button>

          <button type="button" className="btn btn-primary" onClick={submitHandler} >
            Publish
          </button>

        </Modal.Footer>
      </Modal>

    </div>
  )
}
export default PushNotifcation
