/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Admin</span>
        </div>
      </div>

      <SidebarMenuItemWithSub
        to='/Management'
        title='Management'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/designation' title='Designation' hasBullet={true} />
        <SidebarMenuItem to='/employee' title='Employee' hasBullet={true} />
      </SidebarMenuItemWithSub>


      <SidebarMenuItemWithSub
        to='/Knowledge Partner'
        title='Knowledge Partner'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/inquiries' title='Inquiries' hasBullet={true} />
        <SidebarMenuItem to='/addcustomer' title='Add Customer' hasBullet={true} />
        <SidebarMenuItem to='/knowledgepartner' title='Knowledge Partner Plan' hasBullet={true} />

      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/MIS'
        title='MIS'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/user' title='User Search' hasBullet={true} />
        <SidebarMenuItem to='/mis' title='MIS Search Report' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/notification'
        title='Notification'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/notification' title='Notification Dashboard' hasBullet={true} />
        <SidebarMenuItem to='/pushNotification' title='Create Campaign' hasBullet={true} />
        <SidebarMenuItem to='/createBanner' title='Create Banner' hasBullet={true} />
        <SidebarMenuItem to='/bannerNotification' title='BannerNotification' hasBullet={true} />
        <SidebarMenuItem to='/creative' title='creative' hasBullet={true} />
        <SidebarMenuItem to='/CreativeNotification' title='CreativeNotification' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/feature'
        title='Plan Panel'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/feature' title='Feature' hasBullet={true} />
        <SidebarMenuItem to='/plan' title='Plan' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/promocode'
        title='PromoCode'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/createpromoCode' title='Create PromoCode' hasBullet={true} />
        <SidebarMenuItem to='/promoCodelisting' title='PromoCode Listing' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/paymentreport'
        title='Payment Report'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/paymentreport' title='Payment Report' hasBullet={true} />
        <SidebarMenuItem to='/planuseractivity' title='Plan User Activity' hasBullet={true} />
      </SidebarMenuItemWithSub>
    </>
  )
}

export { SidebarMenuMain }
